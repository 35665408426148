import { findIndex } from "lodash"; // eslint-disable-line
import { LOGOUT_SUCCESS, STARTUP, STARTUP_SUCCESS, STARTUP_FAIL } from "./auth";

const LOAD = "service-tapp/locations/LOAD";
const LOAD_SUCCESS = "service-tapp/locations/LOAD_SUCCESS";
const LOAD_FAIL = "service-tapp/locations/LOAD_FAIL";
const ADD = "service-tapp/locations/ADD";
const CHANGE_TAB = "service-tapp/locations/CHANGE_TAB";
const CLEAR = "service-tapp/locations/CLEAR";
const CLEAR_SAVE_ERROR = "service-tapp/locations/CLEAR_SAVE_ERROR";
const EDIT_START = "service-tapp/locations/EDIT_START";
const EDIT_STOP = "service-tapp/locations/EDIT_STOP";
const EDIT_UPDATE = "service-tapp/locations/EDIT_UPDATE";
const INIT = "service-tapp/locations/INIT";
const INSERT = "service-tapp/locations/INSERT";
const INSERT_SUCCESS = "service-tapp/locations/INSERT_SUCCESS";
const INSERT_FAIL = "service-tapp/locations/INSERT_FAIL";
const REMOVE = "service-tapp/locations/REMOVE";
const REMOVE_SUCCESS = "service-tapp/locations/REMOVE_SUCCESS";
const REMOVE_FAIL = "service-tapp/locations/REMOVE_FAIL";
const SAVE = "service-tapp/locations/SAVE";
const SAVE_SUCCESS = "service-tapp/locations/SAVE_SUCCESS";
const SAVE_FAIL = "service-tapp/locations/SAVE_FAIL";
const SEARCH_START = "service-tapp/locations/SEARCH_START";
const SEARCH_SUCCESS = "service-tapp/locations/SEARCH_SUCCESS";
const SEARCH_FAIL = "service-tapp/locations/SEARCH_FAIL";

const initialState = {
  data: [],
  dirty: {},
  editing: null,
  loaded: false,
  removing: {},
  saveError: {},
  saving: {},
  searching: false,
  searchError: null,
  selectedTab: "",
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
    case INIT:
      return initialState;

    case STARTUP:
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.locations,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };

    case STARTUP_FAIL:
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case ADD:
      const addData = [...state.data, action.location];
      return {
        ...state,
        data: addData,
        dirty: {
          ...state.dirty,
          [action.location._id]: true,
        },
      };
    case CHANGE_TAB:
      return {
        ...state,
        selectedTab: action.tabName,
      };
    case CLEAR:
      return {
        ...state,
        // loading: false,
        loaded: false,
        data: null,
        // error: null
      };
    case CLEAR_SAVE_ERROR:
      return {
        ...state,
        saveError: {
          ...state.saveError,
          [action.id]: null,
        },
      };
    case EDIT_START:
      return {
        ...state,
        selectedTab: action.tab || "type",
        editing: action.location,
      };
    case EDIT_STOP:
      const editData = [...state.data];
      const editIndex = findIndex(
        editData,
        (item) => item._id === state.editing
      );
      if (state.dirty[state.editing]) {
        editData.splice(editIndex, 1);
      }

      return {
        ...state,
        data: editData,
        editing: null,
      };
    case EDIT_UPDATE:
      return {
        ...state,
        editing: {
          ...state.editing,
          ...action.updates,
        },
      };
    case INSERT:
      return state; // 'saving' flag handled by redux-form
    case INSERT_SUCCESS:
      const insertData = [...state.data];
      const insertIndex = findIndex(
        insertData,
        (item) => item._id === action.result._id
      );
      insertData[insertIndex] = action.result;
      return {
        ...state,
        data: insertData,
        editing: null,
        saveError: {
          ...state.saveError,
          [action.result._id]: null,
        },
      };
    case INSERT_FAIL:
      if (typeof action.error !== "string") {
        return state;
      }

      return {
        ...state,
        saveError: {
          ...state.saveError,
          [action.id]: action.error,
        },
      };
    case REMOVE:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: true,
        },
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        data: action.result,
        error: null,
      };
    case REMOVE_FAIL:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        data: null,
        error: action.error,
      };
    case SAVE:
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.id]: true,
        },
        dirty: {
          ...state.dirty,
          [action.id]: false,
        },
      };
    case SAVE_SUCCESS:
      const saveData = [...state.data];
      const saveIndex = findIndex(
        saveData,
        (item) => item._id === action.result._id
      );

      if (saveIndex > -1) {
        saveData[saveIndex] = action.result;
      } else {
        saveData.push(action.result);
      }

      return {
        ...state,
        data: saveData,
        editing: null,
        saving: {
          ...state.saving,
          [action.id]: false,
        },
        saveError: {
          ...state.saveError,
          [action.result._id]: null,
        },
        selectedTab: "",
      };
    case SAVE_FAIL:
      if (typeof action.error !== "string") {
        return state;
      }

      return {
        ...state,
        saving: {
          ...state.saving,
          [action.id]: false,
        },
        saveError: {
          ...state.saveError,
          [action.id]: action.error,
        },
      };
    case SEARCH_START:
      return {
        ...state,
        searching: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        editing: {
          ...state.editing,
          loc: [action.pos.lng, action.pos.lat],
        },
        found: true,
        searching: false,
        requestError: null,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        searching: false,
        found: false,
        searchError: action.message,
      };
    default:
      return state;
  }
}

export function init() {
  return { type: INIT };
}

export function isLoaded(globalState) {
  return globalState.locations && globalState.locations.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("/location/load"),
  };
}

export function add(location) {
  return { type: ADD, location };
}

export function changeTab(tabName) {
  return { type: CHANGE_TAB, tabName };
}

export function clear() {
  return { type: CLEAR };
}

export function clearSaveError(id) {
  return { type: CLEAR_SAVE_ERROR, id };
}

export function editStart(location, tab) {
  return { type: EDIT_START, location, tab };
}

export function editStop() {
  return { type: EDIT_STOP };
}

export function editUpdate(updates) {
  return { type: EDIT_UPDATE, updates };
}

export function insert(location) {
  return {
    types: [INSERT, INSERT_SUCCESS, INSERT_FAIL],
    id: location._id,
    promise: (client) =>
      client.post("/location/insert", {
        data: location,
      }),
  };
}

export function remove(id) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    id,
    promise: (client) =>
      client.post("/location/remove", {
        data: { id },
      }),
  };
}

export function save(location) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    id: location._id,
    promise: (client) =>
      client.post("/location/update", {
        data: location,
      }),
  };
}

export function searchStart() {
  return { type: SEARCH_START };
}

export function searchSuccess(pos) {
  return { type: SEARCH_SUCCESS, pos };
}

export function searchFailed(message) {
  return { type: SEARCH_FAIL, message };
}
