import findIndex from "lodash/findIndex";
import Select from "react-select";
import Dropzone from "react-dropzone";
import MaskedInput from "react-maskedinput";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import request from "superagent";
import * as applianceActions from "../../redux/modules/appliances";
import Icon from "../Icon/Icon";
import { Uploads, UploadFile } from "../../containers/Appliances/Appliances";

const TypeIcon = styled.figure`
  border-radius: 50%;
  border: 2px solid var(--blue);
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0 auto 20px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }

  path {
    fill: var(--blue);
  }

  figcaption {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--blue);
    opacity: 0;
    color: #fff;
    font-size: 24px;
    transition: opacity 350ms ease-out;
    cursor: pointer;
    border: 2px solid var(--blue);

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover figcaption {
    opacity: 1;
  }
`;

// TODO: This is repeated in containers/Appliances
// const Uploads = styled.div`
//   .uploads {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;

//     .file {
//       position: relative;
//       width: 30%;
//       height: auto;
//       margin-top: 7%;

//       &:global(.empty) {
//         margin-top: 0;
//       }

//       :global(.delete) {
//         position: absolute;
//         top: -12px;
//         right: -12px;
//       }

//       img {
//         display: block;
//       }
//     }
//   }
// `;

class EditApplianceForm extends Component {
  constructor() {
    super();
    this.state = { files: [] };
  }

  onDrop = (files) => {
    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];
    const images = [...currentAppliance.images];

    this.setState({ files });

    const req = request.post("/api/files/add/appliances");
    files.forEach((file) => req.attach(`images`, file));
    req.end((err, res) => {
      if (err) {
        return console.log("error", err);
      }
      res.body.images.map((imagePath) => images.push(imagePath));
      this.props.editUpdate({ images });
      this.setState({ files: [] });
    });
  };

  changeLocation = () => {
    let tab = "findLocation";
    if (this.props.user.locations.length) {
      tab = "useLocation";
    }

    this.props.changeTab(tab);
  };

  changeType = () => {
    this.props.changeTab("type");
  };

  removeImage = (imagePath) => {
    if (
      window.confirm(
        "Are you sure you want to remove this image? This cannot be undone."
      )
    ) {
      const req = request.post("/api/appliance/deleteUpload");
      req.send({ imagePath });
      req.end((err, res) => {
        if (err) {
          return console.log("error", err);
        }

        if (res.statusCode === 200) {
          const { appliances, editing } = this.props;
          const currentAppliance = appliances.filter(
            (item) => item._id === editing
          )[0];
          let images = [...currentAppliance.images];

          images = images.filter((path) => path !== imagePath);
          this.props.editUpdate({ images });
        }
      });
    }
  };

  updateBrand = (sel) => {
    const brandId = !sel ? "" : sel.value;
    const { brands } = this.props;
    const brandIndex = findIndex(brands, (b) => b._id === brandId);
    const brand = brandIndex > -1 ? brands[brandIndex] : "";

    this.props.editUpdate({ brand });
  };

  updateModelNumber = (evt) => {
    this.props.editUpdate({ modelNumber: evt.target.value });
  };

  updatePurchaseDate = (evt) => {
    this.props.editUpdate({ purchaseDate: evt.target.value });
  };

  updateSerialNumber = (evt) => {
    this.props.editUpdate({ serialNumber: evt.target.value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.saveAppliance();
  };

  render() {
    const { appliances, brands, dirty, editing } = this.props;

    let type = false;
    // let brand = "";
    let modelNumber = "";
    let serialNumber = "";
    let purchaseDate = "";
    let images = [];
    let location = {};
    if (editing) {
      const [currentAppliance] = appliances.filter(
        (item) => item._id === editing
      );

      type = currentAppliance.type.slug || currentAppliance.type;
      // brand = currentAppliance.brand._id || currentAppliance.brand;
      modelNumber = currentAppliance.modelNumber;
      serialNumber = currentAppliance.serialNumber;
      purchaseDate = currentAppliance.purchaseDate;
      images = currentAppliance.images;
      location = currentAppliance.location;
    }

    const brandTypeOpts = brands.map((b) => ({ value: b._id, label: b.name })); // eslint-disable-line

    return (
      <form onSubmit={this.handleSubmit}>
        {type && (
          <TypeIcon onClick={this.changeType}>
            <Icon icon={type} fill="#63666B" size="80" />
            <figcaption>
              <i className="fa fa-pencil"></i>
            </figcaption>
          </TypeIcon>
        )}

        <div className="field">
          <label className="label is-hidden">Brand</label>
          <Select
            options={brandTypeOpts}
            // value={brand}
            onChange={this.updateBrand}
          />
        </div>

        <p className="field">
          <label className="label is-hidden">Model Number</label>
          <input
            type="text"
            className="input"
            placeholder="Model Number"
            value={modelNumber}
            onChange={this.updateModelNumber}
          />
        </p>

        <p className="field">
          <label className="label is-hidden">Serial Number</label>
          <input
            type="text"
            className="input"
            placeholder="Serial Number"
            value={serialNumber}
            onChange={this.updateSerialNumber}
          />
        </p>

        <p className="field">
          <label className="label is-hidden">Date of Purchase</label>
          <MaskedInput
            className="input"
            type="tel"
            mask="11/11/1111"
            placeholder="Date of Purchase (MM/DD/YYYY)"
            value={purchaseDate}
            onChange={this.updatePurchaseDate}
          />
        </p>

        {!dirty[editing] && (
          <div className="field">
            <button
              onClick={this.changeLocation}
              className="button has-icon is-fullwidth is-primary"
            >
              <span className="icon is-small">
                <i className="fa fa-map-marker"></i>
              </span>
              {location && location.name ? (
                <span>
                  Location: <strong>{location.name}</strong>
                </span>
              ) : (
                "Add Location"
              )}
            </button>
          </div>
        )}

        <div className="field">
          <label className="label is-hidden">Images</label>
          <Dropzone
            onDrop={this.onDrop}
            accept="image/*"
            activeClassName="button has-icon is-fullwidth is-outlined is-success"
            rejectClassName="button has-icon is-fullwidth is-outlined is-danger"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="dropzone button has-icon is-fullwidth is-outlined is-primary"
              >
                <span className="icon is-small">
                  <i className="fa fa-camera"></i>
                </span>
                <span>Attach Image(s)</span>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          {(images.length > 0 || this.state.files.length > 0) && (
            <Uploads>
              {images.length > 0 &&
                images.map((imagePath, index) => (
                  <UploadFile key={index}>
                    <button
                      onClick={this.removeImage.bind(this, imagePath)}
                      className="button is-danger delete"
                    />
                    <img src={imagePath} alt="" />
                  </UploadFile>
                ))}
              {this.state.files.length > 0 &&
                this.state.files.map((file, index) => (
                  <UploadFile key={index}>
                    <span className={"st-overlay is-overlay"}>
                      <span className={"st-loader loader"}></span>
                    </span>
                    <img src={file.preview} alt="" />
                  </UploadFile>
                ))}
              {/* This extra span is to prevent a an empty space from showing when only 2 items are in "last row" */}
              <UploadFile className={"empty"}></UploadFile>
            </Uploads>
          )}
        </div>

        <p className="control is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    brands: state.brands.data,
    dirty: state.appliances.dirty,
    editing: state.appliances.editing,
    loading: state.appliances.loading,
    user: state.auth.user,
  }),
  { ...applianceActions }
)(EditApplianceForm);
