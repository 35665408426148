import { Component } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import * as authActions from "../../redux/modules/auth";
import LoginInput from "../LoginInput/LoginInput";
import { StyledForm } from "../LoginForm/LoginForm";
import Notification from "../Notification/Notification";
import {
  createValidator,
  email,
  maxLength,
  minLength,
  required,
} from "../../utils/validation";

const forgotValidation = createValidator({
  email: [required, email, minLength(6), maxLength(40)],
});

class ForgotForm extends Component {
  render() {
    const { clearForgotError, forgetting, forgotError, forgotMsg } = this.props;

    return (
      <Form
        initialValues={{ email: "" }}
        onSubmit={({ email }) => {
          this.props.forgot(email);
        }}
        validate={forgotValidation}
      >
        {({ handleSubmit, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            {forgotError && (
              <Notification
                type="danger"
                message={forgotError.message}
                dismiss={clearForgotError}
              />
            )}
            {forgotMsg && (
              <Notification
                type="success"
                message={forgotMsg.message}
                dismiss={clearForgotError}
              />
            )}

            <Field name="email">
              {(field) => (
                <LoginInput
                  field={field}
                  type="email"
                  label="Email Address"
                  autoComplete="off"
                />
              )}
            </Field>

            <button
              className={
                "button is-primary is-fullwidth" +
                (forgetting || submitting ? " is-loading" : "")
              }
            >
              Reset
            </button>
          </StyledForm>
        )}
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    forgotError: state.auth.forgotError,
    forgotMsg: state.auth.forgotMsg,
    forgetting: state.auth.forgetting,
    user: state.auth.user,
  }),
  { ...authActions }
)(ForgotForm);
