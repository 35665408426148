import React from 'react';

const Fridge = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="16.8 9 46.2 61.8" {...props}>
      <path fill={fill} d="M62.6,70.8H17.4c-0.2,0-0.6-0.2-0.6-0.6V9.6C16.8,9.4,17,9,17.4,9h45C62.6,9,63,9.2,63,9.6v60.6
      	C63,70.6,62.8,70.8,62.6,70.8z M18,69.8h44V10.2H18V69.8z M39.6,65H20V11.2h19.6V65z M20.6,64.4H39V11.8H20.6V64.4z M34.4,39.8H25
      	v-12h9.4V39.8z M25.3,39.4h8.8V28.4h-8.8V39.4z M60,65H40.4V11.2H60V65z M41,64.4h18.4V11.8H41V64.4z M59.8,67.2H20.2V66H60v1.2
      	H59.8z M20.4,66.9h39.2v-0.5H20.4V66.9z M59.8,68.8H20.2v-1.2H60v1.2H59.8z M20.4,68.5h39.2V68H20.4V68.5z M37.6,39
      	c-0.4,0-0.8-0.4-0.8-0.8v-8.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v8.8C38.4,38.8,38.2,39,37.6,39z M37.6,29.2
      	C37.6,29.2,37.4,29.4,37.6,29.2l-0.2,9c0,0,0,0.2,0.2,0.2s0.2,0,0.2-0.2L37.6,29.2C37.8,29.4,37.8,29.2,37.6,29.2z M42.4,39
      	c-0.4,0-0.8-0.4-0.8-0.8v-8.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v8.8C43.2,38.8,42.8,39,42.4,39z M42.4,29.2
      	C42.2,29.2,42.2,29.4,42.4,29.2l-0.2,9c0,0,0,0.2,0.2,0.2s0.2,0,0.2-0.2L42.4,29.2C42.6,29.4,42.4,29.2,42.4,29.2z"/>
    </svg>
  );
};

export default Fridge;
