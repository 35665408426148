import objectId from "bson-objectid";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as applianceActions from "../../redux/modules/appliances";
import { change as changeProvider } from "../../redux/modules/provider";
import { set as setServiceRequest } from "../../redux/modules/serviceRequest";
import config from "../../config";
import Icon from "../Icon/Icon";
import { getWarrantyDetails } from "../../utils/warranty";

const Appliance = styled.div`
  position: relative;

  &.removing .media {
    opacity: 0.1;
  }

  .media-content figure {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  figure.media-left {
    width: 128px;

    svg {
      max-height: 110px;
    }
  }

  .level a {
    margin-bottom: 0;
  }

  @media screen and (max-width: 769px) {
    margin: 0 -20px;
    border-radius: 0;

    figure.media-left {
      width: 96px;
    }
  }

  @media screen and (max-width: 480px) {
    .media {
      flex-direction: column;

      .media-content {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 340px) {
    margin: 0 -10px;
  }
`;

const Title = styled.h2`
  min-height: 27px;
`;

const Remove = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  @media screen and (max-width: $tiny) {
    top: 15px;
    right: 15px;
  }
`;

const WarrantyProgress = styled.div`
  position: relative;

  .progress {
    height: 16px;
  }
`;

const Remaining = styled.span`
  position: absolute;
  top: 0;
  color: #fff;
  font-size: 12px;
  line-height: 16px;

  :global(.is-warning) & {
    color: rgba(17, 17, 17, 0.5);
  }
`;

// TODO: Do I need to use GlobalStyles for this?
// :global(.column):first-child {
//   :global(.columns.is-gapless) > & {
//     padding-right: 1px;
//   }

//   :global(.progress) {
//     border-radius: 290486px 0 0 290486px;
//   }
// }

// :global(.column):last-child {
//   :global(.columns.is-gapless) > & {
//     padding-left: 1px;
//   }

//   :global(.progress) {
//     border-radius: 0 290486px 290486px 0;
//   }
// }

class AppliancesListItem extends Component {
  getService = (id, evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    const { user } = this.props;

    // TODO: duplicated in components/Appliance
    const serviceRequestId = String(objectId());
    const serviceRequest = {
      _id: serviceRequestId,
      appliance: id,
      provider: "",
      user: user._id,
      symptoms: [],
      other: "",
      images: [],
      videos: [],
      name: { ...user.name },
      email: user.email,
      phoneNumber: user.phoneNumber,
      status: config.requestStatuses.PENDING,
      tokens: { accept: "", decline: "" },
      // createdAt: new Date(),
    };
    this.props.setServiceRequest(serviceRequest);

    this.props.serviceStart(id, "symptoms");
  };

  editAppliance = (id, evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.props.editStart(id, "details");
  };

  editWarranty = (id, evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.props.editStart(id, "warrantyDetails");
  };

  removeAppliance = (id, evt) => {
    if (evt) {
      evt.stopPropagation();
    }

    const { removing, removeAppliance } = this.props;
    if (
      !removing[id] &&
      window.confirm("Are you sure you want to delete this?")
    ) {
      removeAppliance(id);
    }
  };

  showRequestDetails = (appliance, evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    // const requestId = appliance.serviceRequests[0]._id;
    const sReq = appliance.serviceRequests[0];
    this.props.setServiceRequest(sReq);
    this.props.changeProvider(sReq.provider);
    this.props.serviceStart(appliance._id, "whileYouWait");
  };

  showRepairHistory = (id, evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.props.editStart(id, "repairHistory");
  };

  render() {
    const { appliance, removing, saving, submittingService } = this.props;

    const title =
      (appliance.brand.name || "") + " " + (appliance.type.name || "");

    // TODO: Pass the entire appliance? return based on purchaseDate OR extendedWarranty
    const warranty = getWarrantyDetails(appliance.purchaseDate);

    let remainingStyle = {};
    if (warranty.length <= warranty.current) {
      remainingStyle = { right: "2%" };
    } else if (warranty.perc < 25) {
      remainingStyle = {
        left: warranty.perc + 2 + "%",
        color: "rgba(17,17,17,0.5)",
      };
    } else {
      remainingStyle = { right: 102 - warranty.perc + "%" };
    }

    let latestRequest = false;
    if (appliance.serviceRequests.length > 0) {
      latestRequest =
        appliance.serviceRequests[appliance.serviceRequests.length - 1];
    }

    return (
      <Appliance
        className={"box" + (removing === appliance._id ? " removing" : "")}
      >
        {(removing[appliance._id] || saving[appliance._id]) && (
          <div className={"st-overlay is-overlay"}>
            <span className={"st-loader loader"} />
          </div>
        )}

        <article
          className="media"
          onClick={this.editAppliance.bind(this, appliance._id)}
        >
          <figure className="media-left is-hidden-mobile">
            <Icon icon={appliance.type.slug} fill="#63666B" size={null} />
          </figure>
          <div className="media-content">
            <figure className="is-hidden-tablet">
              <Icon icon={appliance.type.slug} fill="#63666B" size={null} />
            </figure>
            <div className="content">
              <Title className={"title"}>{title}</Title>
            </div>
            <WarrantyProgress className={"is-" + warranty.class}>
              <progress
                className={"progress is-" + warranty.class}
                value={warranty.current}
                max={warranty.length}
              >
                {warranty.perc + "%"}
              </progress>
              <Remaining style={remainingStyle}>{warranty.message}</Remaining>
            </WarrantyProgress>
            <nav className="level">
              <div className="level-left">
                {(appliance.serviceRequests.length === 0 ||
                  latestRequest.status > 3) && (
                  <button
                    className={
                      "level-item button is-outlined is-success" +
                      (submittingService === appliance._id ? " is-loading" : "")
                    }
                    onClick={this.getService.bind(this, appliance._id)}
                  >
                    Get Service
                  </button>
                )}
                {latestRequest && latestRequest.status <= 3 && (
                  <button
                    className={
                      "level-item button " +
                      config.requestStatusData[latestRequest.status].className +
                      (submittingService === appliance._id ? " is-loading" : "")
                    }
                    onClick={this.showRequestDetails.bind(this, appliance)}
                  >
                    {config.requestStatusData[latestRequest.status].text}
                  </button>
                )}

                <button
                  className="level-item button is-outlined is-primary"
                  onClick={this.editWarranty.bind(this, appliance._id)}
                >
                  {warranty.status === "expired" && <span>Add&nbsp;</span>}{" "}
                  Warranty
                </button>

                <button
                  className="level-item button is-outlined"
                  onClick={this.editAppliance.bind(this, appliance._id)}
                >
                  Edit <span className="is-hidden-mobile">&nbsp;Details</span>
                </button>

                {appliance.serviceRequests.length > 0 && (
                  <button
                    className="level-item button is-outlined"
                    onClick={this.showRepairHistory.bind(this, appliance._id)}
                  >
                    <span className="is-hidden-mobile">Repair&nbsp;</span>{" "}
                    History
                  </button>
                )}
              </div>
            </nav>
          </div>
          <Remove>
            <button
              className="delete"
              onClick={this.removeAppliance.bind(this, appliance._id)}
            />
          </Remove>
        </article>
      </Appliance>
    );
  }
}

export default connect(
  (state) => ({
    removing: state.appliances.removing,
    saving: state.appliances.saving,
    submittingService: state.appliances.submittingService,
    user: state.auth.user,
  }),
  { ...applianceActions, changeProvider, setServiceRequest }
)(AppliancesListItem);
