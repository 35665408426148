import { useEffect, useRef } from "react";

export default function Control(props) {
  const inputRef = useRef();

  useEffect(() => {
    if (props.focusOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.focusOnMount]);

  const { field } = props;

  const classNames =
    "input" +
    (!field.meta.pristine ? " is-touched" : "") +
    (field.meta.touched && field.meta.error ? " is-danger has-error" : "");

  const inputProps = { ...props };
  delete inputProps.focusOnMount;

  return (
    <>
      <div className="control">
        {typeof props.children === "function" ? (
          props.children({ classNames, field, inputRef })
        ) : (
          <input
            ref={inputRef}
            id={field.input.name}
            {...field.input}
            {...inputProps}
            className={classNames}
          />
        )}
      </div>
      {field.meta.touched && field.meta.error && (
        <p className="help is-danger">{field.meta.error}</p>
      )}
    </>
  );
}
