// import { findIndex } from 'lodash';
import { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Dropzone from "react-dropzone";
import MaskedInput from "react-maskedinput";
import request from "superagent";
import * as applianceActions from "../../redux/modules/appliances";
import { Uploads, UploadFile } from "../../containers/Appliances/Appliances";

class ExtendedWarrantyForm extends Component {
  constructor() {
    super();
    this.state = {
      company: "",
      companyOptions: [],
      files: [],
      length: "",
    };
  }

  onDrop = (files) => {
    // console.log( 'dropped', files );

    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];
    const images = [...currentAppliance.images];

    this.setState({ files });

    const req = request.post("/api/appliance/upload");
    files.forEach((file) => req.attach(`images`, file));
    req.end((err, res) => {
      if (err) {
        return console.log("error", err);
      }
      // console.log( res );
      res.body.images.map((imagePath) => images.push(imagePath));
      this.props.editUpdate({ images });
      this.setState({ files: [] });
    });
  };

  removeImage = (imagePath) => {
    if (
      window.confirm(
        "Are you sure you want to remove this image? This cannot be undone."
      )
    ) {
      const req = request.post("/api/appliance/deleteUpload");
      req.send({ imagePath });
      req.end((err, res) => {
        if (err) {
          return console.log("error", err);
        }
        // console.log( res );

        if (res.statusCode === 200) {
          const { appliances, editing } = this.props;
          const currentAppliance = appliances.filter(
            (item) => item._id === editing
          )[0];
          let images = [...currentAppliance.images];

          images = images.filter((path) => path !== imagePath);
          this.props.editUpdate({ images });
        }
      });
    }
  };

  updateCompany = (sel) => {
    const company = !sel ? "" : sel.value;
    this.setState({ company });

    // const brandId = !sel ? '' : sel.value;
    // const { brands } = this.props;
    // const brandIndex = findIndex(brands, (b) => b._id === brandId); // eslint-disable-line
    // const brand = brandIndex > -1 ? brands[brandIndex] : '';
    // this.props.editUpdate({ brand });
  };

  updateLength = (sel) => {
    const length = !sel ? "" : sel.value;
    this.setState({ length });
  };

  updateModelNumber = (evt) => {
    this.props.editUpdate({ modelNumber: evt.target.value });
  };

  updatePurchaseDate = (evt) => {
    this.props.editUpdate({ purchaseDate: evt.target.value });
  };

  updateSerialNumber = (evt) => {
    this.props.editUpdate({ serialNumber: evt.target.value });
  };

  render() {
    // const selectStyles = require("react-select/dist/react-select.css");
    // const {} = this.props;

    const images = [];
    // if ( editing ) {
    //   const currentAppliance = appliances.filter(item => item._id === editing)[0];
    //
    //   brand = currentAppliance.brand._id || currentAppliance.brand;
    //   modelNumber = currentAppliance.modelNumber;
    //   serialNumber = currentAppliance.serialNumber;
    //   purchaseDate = currentAppliance.purchaseDate;
    //   images = currentAppliance.images;
    //   location = currentAppliance.location;
    // }

    const lengthOpts = [
      { value: 1 * 365, label: "1 year" },
      { value: 2 * 365, label: "2 years" },
      { value: 3 * 365, label: "3 years" },
      { value: 4 * 365, label: "4 years" },
      { value: 5 * 365, label: "5 years" },
    ];

    return (
      <form onSubmit={this.props.saveWarranty}>
        <div className="control">
          <label className="label is-hidden">Company</label>
          <Select.Creatable
            options={this.state.companyOptions}
            placeholder="Select Company..."
            value={this.state.company}
            onChange={this.updateCompany}
          />
        </div>

        <p className="control">
          <label className="label is-hidden">Date of Purchase</label>
          <MaskedInput
            className="input"
            type="tel"
            mask="11/11/1111"
            placeholder="Date of Purchase (MM/DD/YYYY)"
            // value={purchaseDate} onChange={this.updatePurchaseDate}
          />
        </p>

        <div className="control">
          <label className="label is-hidden">Warranty Length</label>
          <Select
            options={lengthOpts}
            placeholder="Warranty Length..."
            value={this.state.length}
            onChange={this.updateLength}
          />
        </div>

        <div className="control">
          <label className="label is-hidden">Images</label>
          <Dropzone
            onDrop={this.onDrop}
            accept="image/*"
            className="button has-icon is-fullwidth is-outlined is-primary"
            activeClassName="button has-icon is-fullwidth is-outlined is-success"
            rejectClassName="button has-icon is-fullwidth is-outlined is-danger"
          >
            {({ getRootProps }) => (
              <div {...getRootProps()}>
                <span className="icon is-small">
                  <i className="fa fa-camera"></i>
                </span>
                Attach Image(s)
              </div>
            )}
          </Dropzone>
          {(images.length > 0 || this.state.files.length > 0) && (
            <Uploads>
              {images.length > 0 &&
                images.map((imagePath, index) => (
                  <UploadFile key={index}>
                    <button
                      onClick={this.removeImage.bind(this, imagePath)}
                      className="button is-danger delete"
                    />
                    <img src={imagePath} alt="" />
                  </UploadFile>
                ))}
              {this.state.files.length > 0 &&
                this.state.files.map((file, index) => (
                  <UploadFile key={index}>
                    <span className={"st-overlay is-overlay"}>
                      <span className={"st-loader loader"}></span>
                    </span>
                    <img src={file.preview} alt="" />
                  </UploadFile>
                ))}
              {/* This extra span is to prevent a an empty space from showing when only 2 items are in "last row" */}
              {/* TODO: Make sure className is ADDED to the element */}
              <UploadFile className={"empty"} />
            </Uploads>
          )}
        </div>

        <p className="control is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    brands: state.brands.data,
    dirty: state.appliances.dirty,
    editing: state.appliances.editing,
    loading: state.appliances.loading,
  }),
  { ...applianceActions }
)(ExtendedWarrantyForm);
