import request from "superagent";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { clearToken as clearStripeToken } from "../../redux/modules/stripe";
import { logout, providerSignup } from "../../redux/modules/auth";
import Notification from "../../components/Notification/Notification";
import ProviderResetForm from "../../components/ProviderResetForm/ProviderResetForm";
import ProviderHeader from "../../components/ProviderHeader/ProviderHeader";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
import { useHistory, useParams } from "react-router";
import { useQuery } from "../../utils/hooks";

function ProviderSignUp({
  clearStripeToken,
  providerSignup,
  error,
  location,
  logout,
  routeParams,
  signingUp,
  stripeToken,
  user,
}) {
  const history = useHistory();
  const [checking, setChecking] = useState(false);
  const [code, setCode] = useState("");
  const [couponError, setCouponError] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    providerSignup(params.id, query.token);
  }, [providerSignup, params.id, query.token]);

  useEffect(() => {
    if (
      user &&
      typeof user.providerSignup !== "undefined" &&
      user.providerSignup.paid
    ) {
      clearStripeToken();

      if (query.redirect) {
        history.push(query.redirect);
      }
    }
  }, [user, clearStripeToken, query.redirect, history]);

  const onChangeCode = (evt) => setCode(evt.target.value);

  const checkCouponCode = (evt) => {
    evt.preventDefault();

    setChecking(true);
    setCouponError(null);

    const req = request.post("/api/coupons/check");
    req.send({ code });
    setTimeout(() => {
      req.end((err, res) => {
        console.log(res);
        setChecking(false);

        if (res.status !== 200) {
          setCouponError(res.body.message);
        } else {
          setCoupons(res.body.coupons);
        }
      });
    }, 500);
  };

  const makePayment = () => {
    const stripeData = { type: "token", token: stripeToken };
    providerSignup(params.id, query.token, stripeData, code);
  };

  const paid = user && "providerSignup" in user && user.providerSignup.paid;

  let stripeSource = null;
  if (stripeToken) {
    stripeSource = { ...stripeToken.card };
  }

  const paymentAmount =
    coupons.length > 0 ? (
      <span>
        &nbsp;<span style={{ textDecoration: "line-through" }}>$1.99</span> $
        {coupons[0].value / 100}&nbsp;
      </span>
    ) : (
      "$1.99"
    );

  return (
    <div className="hero is-fullheight is-bold">
      <Helmet title="Provider Signup" />

      <div className="hero-header">
        <ProviderHeader hideLogin hideSignUp />
      </div>

      <div className="hero-body">
        <div className="container">
          {signingUp && (
            <div className={"st-overlay is-overlay"}>
              <span className={"st-loader loader"} />
            </div>
          )}

          {error && !error.type && (
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <h1 className="title is-danger">Error!</h1>
              <h2 className="subtitle">{error.message}</h2>
            </div>
          )}

          {!signingUp && !paid && (
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <h1 className="title is-info">Provider Signup</h1>

              {!user ? (
                <>
                  <h2 className="subtitle">
                    Please set a password to activate your account.
                  </h2>

                  <div className="mb">
                    <ProviderResetForm
                      routeParams={routeParams}
                      location={location}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="subtitle">
                    In order to activate your account you must first pay the
                    signup fee of{" "}
                    <span style={{ textDecoration: "line-through" }}>$199</span>
                    . For a limited time, we are offering signup for just{" "}
                    <strong>$1.99</strong>.
                  </h2>

                  <p className="content block">
                    You are currently logged in as <strong>{user.email}</strong>
                    . If you would like to associate your provider account with
                    a different email address, please{" "}
                    <button className="text-btn" onClick={logout}>
                      logout
                    </button>{" "}
                    first.
                  </p>

                  {error && error.type && error.type === "StripeCardError" && (
                    <Notification
                      type="danger"
                      message={
                        error.message +
                        " Would you like to try a different card?"
                      }
                    />
                  )}

                  {(!stripeSource ||
                    (error &&
                      error.type &&
                      error.type === "StripeCardError")) && (
                    <StripePaymentForm />
                  )}

                  {stripeSource && !error && (
                    <>
                      {/* TODO: It would be nice to implement a "Change Payment Method" button */}

                      <form className="block" onSubmit={checkCouponCode}>
                        <div className="field has-addons">
                          <p
                            className={
                              "control is-expanded" +
                              (couponError ? " has-icons-right" : "")
                            }
                          >
                            <input
                              type="text"
                              className={
                                "input" + (couponError ? " is-danger" : "")
                              }
                              value={code}
                              onChange={onChangeCode}
                              placeholder="Coupon Code?"
                            />
                            {couponError && (
                              <span className="icon is-right">
                                <i className="fa fa-warning" />
                              </span>
                            )}
                            {couponError && (
                              <span className="help is-danger">
                                {couponError}
                              </span>
                            )}

                            {coupons.length > 0 && (
                              <span className="help is-success">
                                {coupons[0].title}
                              </span>
                            )}
                          </p>
                          <p className="control">
                            <button
                              type="submit"
                              className={
                                "button is-primary" +
                                (checking ? " is-loading" : "")
                              }
                            >
                              Apply
                            </button>
                          </p>
                        </div>
                      </form>

                      <div className="block">
                        <button
                          onClick={makePayment}
                          className="button is-primary is-fullwidth"
                        >
                          Pay {paymentAmount} with {stripeSource.brand} ....
                          {stripeSource.last4}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {!signingUp && !error && paid && (
            <div>
              <h1 className="title is-success">Provider Account Activated!</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    error: state.auth.error,
    signingUp: state.auth.signingUp,
    stripeToken: state.stripe.token,
    user: state.auth.user,
  }),
  {
    clearStripeToken,
    logout,
    providerSignup,
  }
)(ProviderSignUp);
