export default function detectBrowser(userAgentString) {
  const browsers = [
    ["edge", /Edge\/([0-9._]+)/],
    ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
    ["firefox", /Firefox\/([0-9.]+)(?:\s|$)/],
    ["opera", /Opera\/([0-9.]+)(?:\s|$)/],
    ["opera", /OPR\/([0-9.]+)(:?\s|$)$/],
    ["ie", /Trident\/7.0.*rv:([0-9.]+)\).*Gecko$/],
    ["ie", /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
    ["ie", /MSIE\s(7\.0)/],
    ["bb10", /BB10;\sTouch.*Version\/([0-9.]+)/],
    ["android", /Android\s([0-9.]+)/],
    ["ios", /iPad.*Version\/([0-9._]+)/],
    ["ipad", /iPad.*Version\/([0-9._]+)/],
    ["ios", /iPhone.*Version\/([0-9._]+)/],
    ["iphone", /iPhone.*Version\/([0-9._]+)/],
    ["safari", /Version\/([0-9._]+).*Safari/],
  ];

  function createMatch(pair) {
    return pair.concat(pair[1].exec(userAgentString));
  }

  function isMatch(pair) {
    return !!pair[2];
  }

  let i = 0; // eslint-disable-line
  const mapped = [];
  for (i = 0; i < browsers.length; i++) {
    browsers[i] = createMatch(browsers[i]);
    if (isMatch(browsers[i])) {
      mapped.push(browsers[i]);
    }
  }

  const match = mapped[0];
  const parts = match && match[3].split(/[._]/).slice(0, 3);

  while (parts && parts.length < 3) {
    parts.push("0");
  }

  // return the name and version
  return {
    names: mapped.map((map) => map[0]),
    name: match && match[0],
    version: parts && parts.join("."),
  };
}
