import React from 'react';

const DishWasher = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="14 13.2 52.2 54" {...props}>
      <path fill={fill} d="M65.8,13.4c0,0-0.2-0.2-0.4-0.2H14.6c-0.2,0-0.2,0-0.4,0.2c0,0-0.2,0.2-0.2,0.4v50c0,0.2,0,0.2,0.2,0.4c0,0,0.2,0.2,0.4,0.2
      	h3.2c0,0,0,0,0,0.2c0.2,1.4,1,2.4,2.2,2.6c1.2,0,2-1.2,2.2-2.6c0,0,0,0,0-0.2H58c0,0,0,0,0,0.2c0.2,1.4,1,2.4,2.2,2.6
      	c1.2,0,2-1.2,2.2-2.6c0,0,0,0,0-0.2h3.2c0.2,0,0.2,0,0.4-0.2c0,0,0.2-0.2,0.2-0.4v-50C65.8,13.6,65.8,13.4,65.8,13.4z M15,14h50v6
      	c-0.8,0.8-3.2,1.8-7,2.4c-4.6,0.8-11,1.2-18,1.2s-13.4-0.4-18-1.2c-3.8-0.6-6.4-1.6-7-2.4V14z M21.2,64.2c0,1-0.8,1.8-1.4,1.8
      	c-0.4,0-1.2-0.6-1.4-1.8l0,0H21.2L21.2,64.2z M61.4,64.2c-0.2,1-0.8,1.8-1.4,1.8c-0.4,0-1.2-0.6-1.4-1.8l0,0H61.4L61.4,64.2z
      	 M61.8,63.2L61.8,63.2h-3.6l0,0H21.8l0,0h-3.6l0,0H15V20.6c1.2,1,3.6,1.6,7,2.2c4.6,0.8,11,1.2,18,1.2s13.4-0.4,18-1.2
      	c3.4-0.6,5.8-1.2,7-2.2v42.6C65,63.2,61.8,63.2,61.8,63.2z M56,19.6h6.8H63c0,0,0,0,0-0.2v-2.8v-0.2c0,0,0,0-0.2,0H56h-0.2v0.2v2.8
      	C55.6,19.4,55.8,19.4,56,19.6C55.8,19.4,55.8,19.6,56,19.6z M56.2,16.8h6.4v2.4h-6.4V16.8z"/>
    </svg>
  );
};

export default DishWasher;
