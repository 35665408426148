export const STARTUP = "service-tapp/STARTUP";
export const STARTUP_SUCCESS = "service-tapp/STARTUP_SUCCESS";
export const STARTUP_FAIL = "service-tapp/STARTUP_FAIL";

const LOAD = "service-tapp/auth/LOAD";
const LOAD_SUCCESS = "service-tapp/auth/LOAD_SUCCESS";
const LOAD_FAIL = "service-tapp/auth/LOAD_FAIL";
const ADD_LOCATION = "service-tapp/auth/ADD_LOCATION";
const ADD_LOCATION_SUCCESS = "service-tapp/auth/ADD_LOCATION_SUCCESS";
const ADD_LOCATION_FAIL = "service-tapp/auth/ADD_LOCATION_FAIL";
const CHANGE_PASS = "service-tapp/auth/CHANGE_PASS";
const CHANGE_PASS_SUCCESS = "service-tapp/auth/CHANGE_PASS_SUCCESS";
const CHANGE_PASS_FAIL = "service-tapp/auth/CHANGE_PASS_FAIL";
const CHECK_EMAIL = "service-tapp/auth/CHECK_EMAIL";
const CHECK_EMAIL_START = "service-tapp/auth/CHECK_EMAIL_START";
const CHECK_EMAIL_SUCCESS = "service-tapp/auth/CHECK_EMAIL_SUCCESS";
const CHECK_EMAIL_FAIL = "service-tapp/auth/CHECK_EMAIL_FAIL";
const CLEAR_FORGOT_ERROR = "service-tapp/auth/CLEAR_FORGOT_ERROR";
const CLEAR_LOGIN_ERROR = "service-tapp/auth/CLEAR_LOGIN_ERROR";
const CLEAR_RESET_ERROR = "service-tapp/auth/CLEAR_RESET_ERROR";
const CLEAR_SIGNUP_ERROR = "service-tapp/auth/CLEAR_SIGNUP_ERROR";
const CLEAR_USER = "service-tapp/auth/CLEAR_USER";
const FORGOT = "service-tapp/auth/FORGOT";
const FORGOT_SUCCESS = "service-tapp/auth/FORGOT_SUCCESS";
const FORGOT_FAIL = "service-tapp/auth/FORGOT_FAIL";
const HIDE_CHANGE_PASS = "service-tapp/auth/HIDE_CHANGE_PASS";
const INSERT_USER = "service-tapp/auth/INSERT_USER";
const INSERT_USER_SUCCESS = "service-tapp/auth/INSERT_USER_SUCCESS";
const INSERT_USER_FAIL = "service-tapp/auth/INSERT_USER_FAIL";
const LOGIN = "service-tapp/auth/LOGIN";
const LOGIN_SUCCESS = "service-tapp/auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "service-tapp/auth/LOGIN_FAIL";
const LOGOUT = "service-tapp/auth/LOGOUT";
export const LOGOUT_SUCCESS = "service-tapp/auth/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "service-tapp/auth/LOGOUT_FAIL";
const PROVIDER_SIGNUP = "service-tapp/auth/PROVIDER_SIGNUP";
const PROVIDER_SIGNUP_SUCCESS = "service-tapp/auth/PROVIDER_SIGNUP_SUCCESS";
const PROVIDER_SIGNUP_FAIL = "service-tapp/auth/PROVIDER_SIGNUP_FAIL";
const REMOVE_LOCATION = "service-tapp/location/REMOVE_LOCATION";
const REMOVE_LOCATION_SUCCESS = "service-tapp/location/REMOVE_LOCATION_SUCCESS";
const REMOVE_LOCATION_FAIL = "service-tapp/location/REMOVE_LOCATION_FAIL";
const RESET = "service-tapp/auth/RESET";
const RESET_SUCCESS = "service-tapp/auth/RESET_SUCCESS";
const RESET_FAIL = "service-tapp/auth/RESET_FAIL";
const SAVE_USER = "service-tapp/auth/SAVE_USER";
const SAVE_USER_SUCCESS = "service-tapp/auth/SAVE_USER_SUCCESS";
const SAVE_USER_FAIL = "service-tapp/auth/SAVE_USER_FAIL";
const SET_USER = "service-tapp/auth/SET_USER";
const SHOW_CHANGE_PASS = "service-tapp/auth/SHOW_CHANGE_PASS";
const SIGNUP = "service-tapp/auth/SIGNUP";
const SIGNUP_SUCCESS = "service-tapp/auth/SIGNUP_SUCCESS";
const SIGNUP_FAIL = "service-tapp/auth/SIGNUP_FAIL";
const UPDATE_USER = "service-tapp/auth/UPDATE_USER";

const initialState = {
  changePassVisible: false,
  changing: false,
  loaded: false,
  removing: {},
  saving: false,
  signingUp: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STARTUP:
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result.user,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result,
      };

    case STARTUP_FAIL:
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case ADD_LOCATION:
      return {
        ...state,
        adding: true,
        addError: null,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        adding: false,
        addError: null,
        user: {
          ...state.user,
          locations: [...state.user.locations, action.result],
        },
      };
    case ADD_LOCATION_FAIL:
      return {
        ...state,
        adding: false,
        addError: action.error,
      };
    case CHANGE_PASS:
      return {
        ...state,
        changing: true,
        changeError: null,
      };
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        changing: false,
        changeError: null,
        changePassVisible: false,
      };
    case CHANGE_PASS_FAIL:
      return {
        ...state,
        changing: false,
        changeError: action.error,
      };
    case CHECK_EMAIL:
    case CHECK_EMAIL_START:
      return {
        ...state,
        checking: true,
        checkError: null,
        emailRegistered: null,
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checking: false,
        checkError: null,
        emailRegistered: false,
      };
    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        checking: false,
        checkError: action.error,
        emailRegistered: true,
      };
    case CLEAR_FORGOT_ERROR:
      return {
        ...state,
        forgotError: null,
        forgotMsg: null,
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
      };
    case CLEAR_RESET_ERROR:
      return {
        ...state,
        resetError: null,
        resetMsg: null,
      };
    case CLEAR_SIGNUP_ERROR:
      return {
        ...state,
        signupError: null,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
      };
    case FORGOT:
      return {
        ...state,
        forgetting: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        forgetting: false,
        forgotError: null,
        forgotMsg: action.result,
      };
    case FORGOT_FAIL:
      return {
        ...state,
        forgetting: false,
        forgotError: action.error,
        forgotMsg: null,
      };
    case HIDE_CHANGE_PASS:
      return {
        ...state,
        changePassVisible: false,
      };
    case INSERT_USER:
      return {
        ...state,
        saving: true,
        saveError: null,
      };
    case INSERT_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError: null,
        user: action.result,
      };
    case INSERT_USER_FAIL:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action.result,
        loginError: null,
        signupError: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
        loginError: null,
        signupError: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error,
      };
    case PROVIDER_SIGNUP:
      return {
        ...state,
        signingUp: true,
      };
    case PROVIDER_SIGNUP_SUCCESS:
      let provider = null;
      if (action.result) {
        provider = {
          ...state.user,
          ...action.result,
        };
      }

      return {
        ...state,
        signingUp: false,
        user: provider,
        error: null,
      };
    case PROVIDER_SIGNUP_FAIL:
      return {
        ...state,
        signingUp: false,
        // data: null,
        error: action.error,
      };
    case SET_USER:
      return {
        ...state,
        loaded: true,
        user: action.user,
      };
    case SHOW_CHANGE_PASS:
      return {
        ...state,
        changePassVisible: true,
      };
    case SIGNUP:
      return {
        ...state,
        loggingIn: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action.result,
        signupError: null,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        signupError: action.error,
      };
    case REMOVE_LOCATION:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: true,
        },
        removeError: null,
      };
    case REMOVE_LOCATION_SUCCESS:
      // TODO: The location data needs to be cleaned out of the state.appliances.locations as well - how ???
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        user: {
          ...state.user,
          locations: action.result,
        },
        removeError: null,
      };
    case REMOVE_LOCATION_FAIL:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        removeError: action.error,
      };
    case RESET:
      return {
        ...state,
        resetting: true,
      };
    case RESET_SUCCESS:
      console.log(action.result);
      return {
        ...state,
        resetting: false,
        resetError: null,
        resetMsg: action.result,
        user: action.result.user,
      };
    case RESET_FAIL:
      return {
        ...state,
        resetting: false,
        resetError: action.error,
        resetMsg: null,
      };
    case SAVE_USER:
      return {
        ...state,
        saving: true,
        saveError: null,
      };
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError: null,
        user: action.result,
      };
    case SAVE_USER_FAIL:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };
    case UPDATE_USER:
      const user = {
        ...state.user,
        ...action.updates,
      };

      return {
        ...state,
        user: user,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("/auth/load"),
  };
}

export function loadStartup() {
  return {
    types: [STARTUP, STARTUP_SUCCESS, STARTUP_FAIL],
    promise: (client) => client.get("/startup/load"),
  };
}

export function addLocation(location) {
  return {
    types: [ADD_LOCATION, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAIL],
    promise: (client) =>
      client.post("/location/insert", {
        data: location,
      }),
  };
}

export function changePassword(pass) {
  return {
    types: [CHANGE_PASS, CHANGE_PASS_SUCCESS, CHANGE_PASS_FAIL],
    promise: (client) =>
      client.post("/auth/changePassword", {
        data: { pass },
      }),
  };
}

export function checkEmail(email) {
  return {
    types: [CHECK_EMAIL, CHECK_EMAIL_SUCCESS, CHECK_EMAIL_FAIL],
    promise: (client) =>
      client.post("/auth/checkEmail", {
        data: { email },
      }),
  };
}

export function checkEmailStart() {
  return { type: CHECK_EMAIL_START };
}

export function checkEmailFail(error) {
  return { type: CHECK_EMAIL_FAIL, error };
}

export function checkEmailSuccess() {
  return { type: CHECK_EMAIL_SUCCESS };
}

export function clearForgotError() {
  return { type: CLEAR_FORGOT_ERROR };
}

export function clearLoginError() {
  return { type: CLEAR_LOGIN_ERROR };
}

export function clearResetError() {
  return { type: CLEAR_FORGOT_ERROR };
}

export function clearSignupError() {
  return { type: CLEAR_SIGNUP_ERROR };
}

export function clearUser() {
  return { type: CLEAR_USER };
}

export function forgot(email) {
  return {
    types: [FORGOT, FORGOT_SUCCESS, FORGOT_FAIL],
    promise: (client) =>
      client.post("/auth/forgot", {
        data: { email },
      }),
  };
}

export function hideChangePassForm() {
  return { type: HIDE_CHANGE_PASS };
}

export function insertUser(user) {
  return {
    types: [INSERT_USER, INSERT_USER_SUCCESS, INSERT_USER_FAIL],
    promise: (client) =>
      client.post("/auth/signup", {
        data: user,
      }),
  };
}

export function login(email, pass) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/auth/login", {
        data: { email, pass },
      }),
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (client) => client.get("/auth/logout"),
  };
}

export function providerSignup(id, token, stripeToken, code) {
  return {
    types: [PROVIDER_SIGNUP, PROVIDER_SIGNUP_SUCCESS, PROVIDER_SIGNUP_FAIL],
    promise: (client) =>
      client.post(`/provider/signup/${id}`, {
        data: { token, stripeToken, coupon: code },
      }),
  };
}

export function removeLocation(id) {
  return {
    types: [REMOVE_LOCATION, REMOVE_LOCATION_SUCCESS, REMOVE_LOCATION_FAIL],
    id,
    promise: (client) =>
      client.post("/location/remove", {
        data: { id },
      }),
  };
}

export function reset(id, token, pass) {
  return {
    types: [RESET, RESET_SUCCESS, RESET_FAIL],
    promise: (client) =>
      client.post("/auth/reset", {
        data: { id, token, pass },
      }),
  };
}

export function saveUser(user) {
  return {
    types: [SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_FAIL],
    promise: (client) =>
      client.post("/user/update", {
        data: user,
      }),
  };
}

export function setUser(user) {
  return { type: SET_USER, user };
}

export function showChangePassForm() {
  return { type: SHOW_CHANGE_PASS };
}

export function signup(email, pass, optin) {
  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: (client) =>
      client.post("/auth/signup", {
        data: { email, pass, optin },
      }),
  };
}

export function updateUser(updates) {
  return { type: UPDATE_USER, updates };
}
