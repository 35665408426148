import { Component } from "react";
import { connect } from "react-redux";
import config from "../../config";
import { editUpdate } from "../../redux/modules/appliances";

class LocationButton extends Component {
  selectLocation = (location, evt) => {
    evt.preventDefault();

    this.props.editUpdate({ location });

    // HACK: Need to figure out a better way to trigger an action
    // HACK: after another one is finished... redux-thunk? redux-saga?
    setTimeout(this.props.saveAppliance, 500);
  };

  render() {
    const { location } = this.props;

    const domain = "//maps.googleapis.com/maps/api/staticmap";
    // TODO: is this the correct way to access these?
    const { 1: lat, 0: lng } = location.loc;
    const mapImageSrc = `${domain}?center=${lat},${lng}&zoom=13&size=300x300&sensor=false&key=${config.google.apiKey}`;

    return (
      <button
        className="box location-button"
        onClick={this.selectLocation.bind(this, location)}
      >
        <div className="location-map">
          <img src={mapImageSrc} alt={location.name} />
        </div>
        <div className="location-details">
          <p>
            <strong>{location.name}</strong>
          </p>
          <p>
            {`${location.street}, ${location.city}, ${location.state} ${location.zip}`.replace(
              /^,\s,?\s?/,
              ""
            )}
          </p>
        </div>
        <div className="location-icon">
          <i className="fa fa-angle-right"></i>
        </div>
      </button>
    );
  }
}

export default connect(
  () => ({}),
  // state => ({
  //   propName: state.obj.var,
  // }),
  { editUpdate }
)(LocationButton);
