// import data from "../../data/brands";
import { STARTUP, STARTUP_SUCCESS, STARTUP_FAIL } from "./auth";

const LOAD = "service-tapp/brands/LOAD";
const LOAD_SUCCESS = "service-tapp/brands/LOAD_SUCCESS";
const LOAD_FAIL = "service-tapp/brands/LOAD_FAIL";

const initialState = {
  loaded: false,
  // data,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STARTUP:
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.brands,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };

    case STARTUP_FAIL:
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.brands && globalState.brands.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("/brand/load"),
  };
}
