import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Header from "../../components/Header/Header";
import * as serviceRequestActions from "../../redux/modules/serviceRequest";
import { useQuery } from "../../utils/hooks";

function ServiceRequestNoRepair({ noRepair, noRepairing, error }) {
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    noRepair(params.id, query.token);
  }, [noRepair, params.id, query.token]);

  return (
    <div className="hero is-fullheight is-bold">
      <Helmet title="No Repair - Service Request" />

      <div className="hero-header">
        <Header />
      </div>

      <div className="hero-body">
        <div className="container">
          {noRepairing && (
            <div className={"st-overlay is-overlay"}>
              <span className={"st-loader loader"}></span>
            </div>
          )}

          {!noRepairing && !error && (
            <div>
              <h1 className="title is-danger">Not Repaired...</h1>
              <h2 className="subtitle">Thanks for letting us know.</h2>
            </div>
          )}

          {!noRepairing && error && (
            <div>
              <h1 className="title is-danger">Error!</h1>
              <h2 className="subtitle">{error.message}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    noRepairing: state.serviceRequest.noRepairing,
    error: state.serviceRequest.error,
  }),
  { ...serviceRequestActions }
)(ServiceRequestNoRepair);
