import { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import * as locationsActions from "../../redux/modules/locations";
import config from "../../config";

class EditLocationForm extends Component {
  setLocationName = (evt) => {
    this.props.editUpdate({ name: evt.target.value });
  };

  setLocationStreet = (evt) => {
    this.props.editUpdate({ street: evt.target.value });
  };

  setLocationCity = (evt) => {
    this.props.editUpdate({ city: evt.target.value });
  };

  setLocationState = (evt) => {
    this.props.editUpdate({ state: evt.target.value });
  };

  setLocationZip = (evt) => {
    this.props.editUpdate({ zip: evt.target.value });
  };

  findLocation = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const {
      location: { street, city, state, zip },
    } = this.props;
    if (
      street.length === 0 ||
      city.length === 0 ||
      state.length === 0 ||
      zip.length === 0
    ) {
      return alert("Please enter your entire address.");
    }

    this.props.searchStart();
    const domain = "https://maps.googleapis.com/maps/api/geocode/json";
    const geocodeURL = `${domain}?address=${street || ""}+${city || ""}+${
      state || ""
    }+${zip || ""}&key=${config.google.apiKey}`;
    const req = request.get(geocodeURL);
    req.end((err, res) => {
      if (err) {
        return console.log("error", err);
      }

      if (res.body.status === "OK") {
        const { lat, lng } = res.body.results[0].geometry.location;
        const pos = { lat, lng };
        this.props.searchSuccess(pos);

        let foundStreet = "";
        res.body.results[0].address_components.forEach((comp) => {
          if (comp.types.indexOf("street_number") > -1) {
            foundStreet += comp.long_name;
          }

          if (comp.types.indexOf("route") > -1) {
            foundStreet += " " + comp.short_name;
          }

          if (comp.types.indexOf("locality") > -1) {
            this.props.editUpdate({ city: comp.long_name });
          }

          if (comp.types.indexOf("administrative_area_level_1") > -1) {
            this.props.editUpdate({ state: comp.short_name });
          }

          if (comp.types.indexOf("postal_code") > -1) {
            this.props.editUpdate({ zip: comp.short_name });
          }
        });

        if (foundStreet.length > 0) {
          this.props.editUpdate({ street: foundStreet });
        }

        setTimeout(() => {
          this.props.saveLocation();
        }, 250);
      } else {
        // console.log( res.body );
        this.props.searchFailed();
      }
    });
  };

  render() {
    const { location, searching } = this.props;

    const domain = "//maps.googleapis.com/maps/api/staticmap";
    const [lng, lat] = location.loc;
    const mapImageSrc = `${domain}?center=${lat},${lng}&zoom=13&size=300x300&sensor=false&key=${config.google.apiKey}`;

    return (
      <form className="relative" onSubmit={this.findLocation.bind(this)}>
        {location.loc && (
          <div className="location is-small field">
            <img src={mapImageSrc} alt="Current Location Google Map" />
            <span className="marker">
              <i className="fa fa-map-marker animated zoomInDown"></i>
            </span>
          </div>
        )}

        <div className="field">
          <label className="label is-hidden">Name</label>
          <p className="control">
            <input
              type="text"
              className="input"
              placeholder="Name"
              onChange={this.setLocationName.bind(this)}
              value={location.name}
            />
          </p>
        </div>

        <div className="field">
          <label className="label is-hidden">Address</label>
          <p className={"control" + (searching ? " has-icons-right" : "")}>
            <input
              type="text"
              className="input"
              placeholder="Street"
              onChange={this.setLocationStreet.bind(this)}
              value={location.street}
            />
            {searching && (
              <span className="icon is-right">
                <i className="fa fa-spin fa-spinner"></i>
              </span>
            )}
          </p>
        </div>

        <div className="columns is-mobile">
          <div className="column is-half">
            <p className="field">
              <input
                type="text"
                className="input"
                placeholder="City"
                onChange={this.setLocationCity.bind(this)}
                value={location.city}
              />
            </p>
          </div>
          <div className="column is-one-quarter">
            <p className="field">
              <input
                type="text"
                className="input"
                placeholder="State"
                onChange={this.setLocationState.bind(this)}
                value={location.state}
              />
            </p>
          </div>
          <div className="column is-one-quarter">
            <p className="field">
              <input
                type="text"
                className="input"
                placeholder="Zip"
                onChange={this.setLocationZip.bind(this)}
                value={location.zip}
              />
            </p>
          </div>
        </div>

        <p className="field is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    searching: state.locations.searching,
  }),
  { ...locationsActions }
)(EditLocationForm);
