import objectId from "bson-objectid";
import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config";
import { addLocation as addLocationToAuth } from "../../redux/modules/auth";
import { editUpdate, serviceStart } from "../../redux/modules/appliances";
import * as locationActions from "../../redux/modules/location";
import { add as addLocation } from "../../redux/modules/locations";

class NameLocationForm extends Component {
  setLocationName = (evt) => {
    this.props.changeName(evt.target.value);
  };

  saveLocation = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    if (this.props.location.name.length === 0) {
      return alert("You must set a name first.");
    }

    const {
      location: { name, street, city, state, zip },
      position,
      user,
    } = this.props;

    const locId = String(objectId());
    const location = {
      _id: locId,
      name,
      street,
      city,
      state,
      zip,
      loc: [position.lng, position.lat],
      user: user._id,
    };
    this.props.addLocationToAuth(location);
    this.props.addLocation(location);

    this.props.editUpdate({ location });

    // HACK: Need to figure out a better way to trigger an action
    // HACK: after another one is finished... redux-thunk? redux-saga?
    setTimeout(() => {
      this.props.saveAppliance();
      this.props.reset();
    }, 500);
  };

  render() {
    const { position } = this.props;

    const domain = "//maps.googleapis.com/maps/api/staticmap";
    const { lat, lng } = position;
    const mapImageSrc = `${domain}?center=${lat},${lng}&zoom=13&size=300x300&sensor=false&key=${config.google.apiKey}`;

    return (
      <form onSubmit={this.saveLocation.bind(this)}>
        <p className="control">
          <input
            type="text"
            className="input"
            placeholder="Type in a name... (ex. Home, Work, Mom's House)"
            onChange={this.setLocationName.bind(this)}
            value={this.props.location.name}
          />
        </p>

        {position.lat && (
          <div className={"location control"}>
            <img src={mapImageSrc} alt="Current Location Google Map" />
            <span className="marker">
              <i className="fa fa-map-marker animated zoomInDown"></i>
            </span>
          </div>
        )}

        <p className="control is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    editing: state.appliances.editing,
    location: {
      name: state.location.name,
      street: state.location.street,
      city: state.location.city,
      state: state.location.state,
      zip: state.location.zip,
    },
    position: state.location.position,
    user: state.auth.user,
  }),
  {
    ...locationActions,
    addLocation,
    addLocationToAuth,
    editUpdate,
    serviceStart,
  }
)(NameLocationForm);
