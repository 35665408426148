import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ForgotForm from "../../components/ForgotForm/ForgotForm";

export default function Forgot() {
  return (
    <div className="hero login is-fullheight">
      <Helmet title="Forgot" />

      <div className="hero-body is-justify-content-center">
        <div className="box">
          <h2 className="subtitle has-text-centered">Forgot Your Password?</h2>
          <ForgotForm />
          <p className="has-text-centered">
            <Link to="/login">Nevermind, I Remember!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
