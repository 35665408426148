import { isEmpty } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { changeTab } from "../../redux/modules/appliances";
import { clearResetError, reset } from "../../redux/modules/auth";
import {
  createValidator,
  match,
  minLength,
  maxLength,
  required as isRequired,
} from "../../utils/validation";
import Notification from "../Notification/Notification";

class ProviderResetForm extends Component {
  constructor() {
    super();
    this.state = { errors: {}, password: "", confirm: "" };
  }

  setPassword = (evt) => {
    this.setState({ password: evt.target.value });
  };

  setConfirm = (evt) => {
    this.setState({ confirm: evt.target.value });
  };

  resetProvider = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const rules = {
      password: [isRequired, minLength(4), maxLength(40)],
      confirm: [isRequired, minLength(4), maxLength(40), match("password")],
    };
    const fieldData = {
      password: `${this.state.password}`,
      confirm: `${this.state.confirm}`,
    };
    const validationErrors = createValidator(rules)(fieldData);
    this.setState({ errors: validationErrors });

    if (!isEmpty(validationErrors)) {
      return;
    }

    const { location, routeParams } = this.props;
    this.props.reset(routeParams.id, location.query.token, fieldData.password);
  };

  render() {
    const { resetError } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this.resetProvider.bind(this)}>
        {resetError && (
          <Notification
            type="danger"
            message={resetError.message}
            dismiss={this.props.clearResetError}
          />
        )}

        <label className="label has-text-left">Password</label>
        <div className="control">
          <p
            className={
              "control" + (errors.password ? " has-icon has-icon-right" : "")
            }
          >
            <input
              type="password"
              className={"input" + (errors.password ? " is-danger" : "")}
              onChange={this.setPassword.bind(this)}
            />
            {errors.password && <i className="fa fa-warning"></i>}
            {errors.password && (
              <span className="help is-danger">{errors.password}</span>
            )}
          </p>
        </div>

        <label className="label has-text-left">Confirm</label>
        <div className="control">
          <p
            className={
              "control" + (errors.confirm ? " has-icon has-icon-right" : "")
            }
          >
            <input
              type="password"
              className={"input" + (errors.confirm ? " is-danger" : "")}
              onChange={this.setConfirm.bind(this)}
            />
            {errors.confirm && <i className="fa fa-warning"></i>}
            {errors.confirm && (
              <span className="help is-danger">{errors.confirm}</span>
            )}
          </p>
        </div>

        <p className="control">
          <button type="submit" className="button is-primary is-fullwidth">
            Set Password
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    resetting: state.auth.resetting,
    resetError: state.auth.resetError,
    user: state.auth.user,
  }),
  { changeTab, clearResetError, reset }
)(ProviderResetForm);
