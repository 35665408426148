export default function LogoTextRight({
  textFill = "#154a94",
  innerFill = "#a22c32",
  outerFill = "#154a94",
  width,
  height,
  className,
}) {
  const inlineStyle = {};
  if (width) {
    inlineStyle.width = `${width}px`;
  }
  if (height) {
    inlineStyle.height = `${height}px`;
  }

  return (
    <svg
      version="1.1"
      className={className}
      style={inlineStyle}
      x="0px"
      y="0px"
      viewBox="0 0 440 100"
    >
      <g id="name">
        <path
          className="service"
          fill={textFill}
          d="M119.1,62.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.3-0.1-0.5l0.4-3.6
        		c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7,0c1.2,0.5,2.6,0.9,4,1.1c1.4,0.2,3,0.3,4.6,0.3c1.2,0,2.1-0.1,2.9-0.2
        		c0.8-0.1,1.4-0.3,1.9-0.6c0.5-0.3,0.8-0.6,1-1c0.2-0.4,0.3-0.8,0.3-1.3c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.6-0.8-1.1-1.1
        		s-1.2-0.7-2.1-1c-0.9-0.3-2-0.7-3.4-1c-1.7-0.5-3.1-1-4.3-1.5c-1.2-0.6-2.2-1.2-2.9-1.9c-0.8-0.7-1.3-1.5-1.7-2.5
        		c-0.4-0.9-0.5-1.9-0.5-3c0-2.6,1-4.6,3-6c2-1.4,5.1-2.1,9.2-2.1c1.6,0,3.1,0.1,4.5,0.3c1.5,0.2,2.9,0.5,4.2,0.9
        		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.1,0.5l-0.3,3.5c0,0.3-0.1,0.5-0.3,0.6s-0.4,0.2-0.7,0.1c-1.1-0.3-2.3-0.6-3.6-0.7
        		s-2.6-0.3-3.9-0.3c-1.2,0-2.1,0.1-2.9,0.2c-0.8,0.1-1.4,0.3-1.8,0.5c-0.5,0.2-0.8,0.5-1,0.8c-0.2,0.3-0.3,0.7-0.3,1.1
        		c0,0.5,0.1,0.9,0.3,1.2c0.2,0.4,0.6,0.7,1.1,1c0.5,0.3,1.2,0.7,2,1c0.8,0.3,1.9,0.7,3.1,1c1.8,0.5,3.4,1.1,4.6,1.7s2.3,1.3,3.1,2
        		c0.8,0.7,1.4,1.5,1.7,2.4c0.4,0.9,0.5,1.9,0.5,3c0,1.4-0.2,2.6-0.7,3.7c-0.5,1.1-1.2,1.9-2.3,2.7c-1,0.7-2.4,1.3-4,1.6
        		c-1.6,0.4-3.6,0.5-5.9,0.5c-1.7,0-3.4-0.1-5-0.3C122,63.7,120.5,63.4,119.1,62.9z M146.3,36c0-0.2,0.1-0.4,0.2-0.6
        		c0.2-0.2,0.3-0.2,0.6-0.2h20.8c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2
        		H153v6.3h13.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.4c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H153v7.1h15.2
        		c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6V63c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-21.1c-0.2,0-0.4-0.1-0.6-0.2
        		c-0.2-0.2-0.2-0.4-0.2-0.6V36z M198.8,44.2c0,1.9-0.5,3.5-1.5,4.9c-1,1.4-2.6,2.5-4.7,3.2l7.9,10.3c0.1,0.2,0.2,0.3,0.2,0.5
        		c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.1-0.5,0.1h-4.3c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.1-1l-6.9-9.4h-5.5V63
        		c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V36c0-0.2,0.1-0.4,0.2-0.6
        		c0.2-0.2,0.3-0.2,0.6-0.2H186c1.8,0,3.5,0.2,5.1,0.6c1.6,0.4,2.9,0.9,4,1.7c1.1,0.7,2,1.7,2.7,2.8
        		C198.4,41.3,198.8,42.6,198.8,44.2z M192,44.2c0-1.3-0.5-2.3-1.6-2.9c-1.1-0.6-2.7-0.9-4.8-0.9h-5V48h5c2.1,0,3.7-0.3,4.8-0.9
        		C191.4,46.5,192,45.5,192,44.2z M215.3,63.8c-0.4,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.4-0.6-0.8l-11.2-26.5c-0.1-0.3-0.1-0.6,0.1-0.8
        		c0.2-0.2,0.4-0.3,0.7-0.3h4.9c0.4,0,0.8,0.1,1,0.2c0.2,0.2,0.4,0.4,0.5,0.7l8.7,22.3l8.7-22.3c0.1-0.3,0.3-0.6,0.5-0.7
        		c0.2-0.2,0.6-0.2,1-0.2h4.9c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.2,0.5,0.1,0.8L223,62.8c-0.2,0.3-0.4,0.6-0.6,0.8
        		c-0.3,0.2-0.6,0.3-1,0.3H215.3z M244.5,35.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v27c0,0.2-0.1,0.4-0.2,0.6
        		c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V36c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2H244.5z
        		 M275.9,63.3c-1.2,0.4-2.5,0.7-3.8,0.8c-1.3,0.1-2.7,0.2-4.1,0.2c-2.8,0-5.3-0.3-7.5-1c-2.2-0.7-4-1.7-5.5-2.9s-2.7-2.8-3.4-4.7
        		c-0.8-1.8-1.2-3.9-1.2-6.2c0-2.3,0.4-4.3,1.2-6.1c0.8-1.8,1.9-3.4,3.4-4.7c1.5-1.3,3.3-2.3,5.5-3c2.2-0.7,4.7-1,7.4-1
        		c1.4,0,2.7,0.1,4.1,0.3c1.4,0.2,2.6,0.4,3.8,0.8c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.2,0.7l-0.3,3.3c0,0.3-0.2,0.5-0.4,0.6
        		c-0.2,0.1-0.5,0.1-0.8,0c-1-0.3-2-0.6-3-0.7c-1-0.2-2.2-0.2-3.5-0.2c-1.9,0-3.5,0.2-5,0.7c-1.4,0.4-2.6,1.1-3.6,1.9
        		c-1,0.8-1.7,1.9-2.2,3.1c-0.5,1.2-0.7,2.5-0.7,4c0,1.6,0.3,3,0.8,4.2s1.3,2.2,2.3,3c1,0.8,2.2,1.4,3.7,1.9c1.4,0.4,3.1,0.6,5,0.6
        		c1.2,0,2.3-0.1,3.4-0.3c1.1-0.2,2.1-0.4,3.1-0.8c0.3-0.1,0.5-0.1,0.8,0s0.4,0.3,0.4,0.6l0.3,3.4c0,0.3-0.1,0.5-0.2,0.7
        		C276.4,63.1,276.2,63.2,275.9,63.3z M281.3,36c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2h20.8c0.2,0,0.4,0.1,0.6,0.2
        		c0.2,0.2,0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-14.9v6.3h13.8c0.2,0,0.4,0.1,0.6,0.2
        		s0.2,0.4,0.2,0.6v3.4c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-13.8v7.1h15.2c0.2,0,0.4,0.1,0.6,0.2
        		c0.2,0.2,0.2,0.3,0.2,0.6V63c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-21.1c-0.2,0-0.4-0.1-0.6-0.2
        		c-0.2-0.2-0.2-0.4-0.2-0.6V36z"
        />
        <path
          className="tapp"
          fill={textFill}
          d="M324.1,40.3H315c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V36c0-0.2,0.1-0.4,0.2-0.6
        		c0.2-0.2,0.4-0.2,0.6-0.2h24.9c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-9.1V63
        		c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V40.3z M349,57.5l-2.3,5.8
        		c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.2-0.4,0.2h-5.6c-0.3,0-0.5-0.1-0.7-0.3s-0.1-0.5,0-0.8l11.4-27c0.1-0.3,0.4-0.5,0.8-0.5h7.2
        		c0.4,0,0.6,0.2,0.8,0.5l11.4,27c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.2-0.4,0.3-0.7,0.3h-5.6c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.3-0.4
        		l-2.3-5.8H349z M360.2,52.4l-4.6-11.9l-4.6,11.9H360.2z M386.8,35.2c2,0,3.8,0.2,5.5,0.6c1.6,0.4,3,1,4.1,1.8c1.1,0.8,2,1.8,2.6,3
        		s0.9,2.6,0.9,4.1c0,1.5-0.3,2.9-0.9,4.1s-1.5,2.2-2.6,3c-1.1,0.8-2.5,1.4-4.1,1.8c-1.6,0.4-3.4,0.6-5.5,0.6h-5V63
        		c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V36c0-0.2,0.1-0.4,0.2-0.6
        		s0.4-0.2,0.6-0.2H386.8z M381.8,49h4.3c1.1,0,2.2-0.1,3-0.2c0.9-0.1,1.6-0.4,2.2-0.7c0.6-0.3,1-0.8,1.3-1.3s0.4-1.2,0.4-2.1
        		s-0.1-1.5-0.4-2.1s-0.7-1-1.3-1.3c-0.6-0.3-1.3-0.6-2.2-0.7c-0.9-0.1-1.9-0.2-3-0.2h-4.3V49z M415.9,35.2c2,0,3.8,0.2,5.5,0.6
        		c1.6,0.4,3,1,4.1,1.8c1.1,0.8,2,1.8,2.6,3s0.9,2.6,0.9,4.1c0,1.5-0.3,2.9-0.9,4.1s-1.5,2.2-2.6,3c-1.1,0.8-2.5,1.4-4.1,1.8
        		c-1.6,0.4-3.4,0.6-5.5,0.6h-5V63c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H405c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V36
        		c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2H415.9z M410.9,49h4.3c1.1,0,2.2-0.1,3-0.2c0.9-0.1,1.6-0.4,2.2-0.7c0.6-0.3,1-0.8,1.3-1.3
        		s0.4-1.2,0.4-2.1s-0.1-1.5-0.4-2.1s-0.7-1-1.3-1.3c-0.6-0.3-1.3-0.6-2.2-0.7c-0.9-0.1-1.9-0.2-3-0.2h-4.3V49z"
        />
      </g>
      <g id="icon">
        <path
          className="outer"
          fill={outerFill}
          d="M95.3,33.7C86.2,22.2,74.5,12.1,62,4.6c-12.7-0.3-27.3,1.6-42.1,6.3c-2.4,5.3-4.8,11.5-7,18.6
            c-2.6,8-4.8,16.8-6.6,26.1c13.8,13.7,29.5,27.7,45,39.9c9.1-2.9,17.7-6.2,25.4-9.7c6.8-3.1,12.8-6.2,17.9-9.1
            C97.3,61,97.3,46.2,95.3,33.7z M87.4,65.9c-0.2,0.1-0.4,0.2-0.6,0.3c-9,3.9-19.6,8-31.8,12c-10.7-9.5-21.7-19.3-32.4-28.6
            C25.2,37.1,28,26.2,30.9,17c0.1-0.2,0.1-0.4,0.2-0.5c11.2-3,22-4.5,31.7-5c9.3,6.3,18,13.9,25.5,22.3
            C89.1,43.4,88.9,54.3,87.4,65.9z"
        />
        <path
          className="inner"
          fill={innerFill}
          d="M71,28.1c-0.1,4.7-0.3,10.3-0.6,15c-4,1.3-6.4,1.7-11.2,3.2c-4.3-3.9-6.1-5.7-10.5-9.4
            c0.8-4.4,1.7-9.9,2.4-14.1c-3.5,0.3-6.9,1-9.1,1.5c-1.3,6.2-2.6,13-4,20.5c6.7,5.6,13.4,11.5,19.9,17.5c7.2-2.3,13.9-4.4,20-6.4
            c0.7-7.1,1-13.9,0.9-20.1C77,33.8,73,29.9,71,28.1z"
        />
      </g>
    </svg>
  );
}
