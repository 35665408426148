import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { reset as resetPass, clearResetError } from "../../redux/modules/auth";
import LoginInput from "../LoginInput/LoginInput";
import { StyledForm } from "../LoginForm/LoginForm";
import Notification from "../Notification/Notification";
import {
  createValidator,
  maxLength,
  match,
  minLength,
  required,
} from "../../utils/validation";
import { useQuery } from "../../utils/hooks";

const resetValidation = createValidator({
  password: [required, minLength(4), maxLength(40)],
  confirm: [required, match("password"), minLength(4), maxLength(40)],
});

function ResetForm({
  clearResetError,
  resetting,
  resetError,
  resetMsg,
  resetPass,
}) {
  const params = useParams();
  const query = useQuery();

  return (
    <Form
      initialValues={{ password: "", confirm: "" }}
      onSubmit={({ password }) => {
        resetPass(params.id, query.token, password);
      }}
      validate={resetValidation}
    >
      {({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {resetError && (
            <Notification
              message={resetError.message}
              type="danger"
              dismiss={clearResetError}
            />
          )}
          {resetMsg && (
            <Notification
              message={resetMsg.message}
              type="success"
              dismiss={clearResetError}
            />
          )}

          <Field name="password">
            {(field) => (
              <LoginInput field={field} type="password" label="New Password" />
            )}
          </Field>
          <Field name="confirm">
            {(field) => (
              <LoginInput field={field} type="password" label="Confirm" />
            )}
          </Field>

          <button
            className={
              "button is-primary is-fullwidth" +
              (resetting || submitting ? " is-loading" : "")
            }
          >
            Reset
          </button>
        </StyledForm>
      )}
    </Form>
  );
}

export default connect(
  (state) => ({
    resetError: state.auth.resetError,
    resetMsg: state.auth.resetMsg,
    resetting: state.auth.resetting,
    user: state.auth.user,
  }),
  { clearResetError, resetPass }
)(ResetForm);
