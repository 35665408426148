import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { firstAppliance } from "../../redux/modules/appliances";
import Icon from "../Icon/Icon";

const Appliance = styled.a`
  position: relative;

  &.box {
    cursor: pointer;
    // HACK: computed property was showing 'all' in browser
    // HACK: adding important! seems to force this to be only these two
    transition-property: box-shadow, transform !important;
    transition: 250ms;
    transition-timing-function: cubic-bezier(0.75, 0, 0.5, 1);
    transition-delay: 20ms;

    &:hover {
      box-shadow: 0 6px 8px rgba(17, 17, 17, 0.1),
        0 0 0 1px rgba(17, 17, 17, 0.1);
      transform: translateY(-4px);
    }
  }

  &.loading .media {
    opacity: 0.1;
  }

  .title {
    color: #69707a;
  }

  .media-content figure {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  figure.media-left {
    width: 128px;

    svg {
      max-height: 110px;

      path {
        fill: #e9ebed;
      }
    }
  }

  .level span {
    margin-bottom: 0;
  }

  @media screen and (max-width: 769px) {
    margin: 0 -20px;
    border-radius: 0;

    .title {
      margin-bottom: 0;
    }

    .media-content {
      width: 100%;
    }
  }

  @media screen and (max-width: 340px) {
    margin: 0 -10px;
  }
`;

class AppliancesListNewItem extends Component {
  componentDidMount() {
    if (!this.props.appliances || this.props.appliances.length === 0) {
      setTimeout(() => {
        this.props.addAppliance(null, "welcome");
        this.props.firstAppliance();
      }, 250);
    }
  }

  render() {
    return (
      <Appliance
        tabIndex="0"
        className={"box"}
        onClick={this.props.addAppliance.bind(this, null, undefined, undefined)}
      >
        <article className="media">
          <figure className="media-left is-hidden-mobile">
            <Icon icon="fridge" fill="#63666B" size={null} />
          </figure>
          <div className="media-content">
            <figure className="is-hidden-tablet">
              <Icon icon="fridge" fill="hsl(220, 7%, 72%)" size={null} />
            </figure>
            <div className="content">
              <h2 className="title">Add Appliance</h2>
            </div>
            <progress
              className="progress is-hidden-mobile"
              value="20"
              max="100"
            >
              20%
            </progress>
            <nav className="level is-hidden-mobile">
              <div className="level-left">
                <span className="level-item button is-outlined" disabled>
                  <div className="is-hidden-mobile">Get&nbsp;</div> Service
                </span>
                <span className="level-item button is-outlined" disabled>
                  <div className="is-hidden-mobile">Add&nbsp;</div> Warranty
                </span>
                <span className="level-item button is-outlined" disabled>
                  Edit <span className="is-hidden-mobile">&nbsp;Details</span>
                </span>
                <span className="level-item button is-outlined" disabled>
                  <span className="is-hidden-mobile">Repair&nbsp;</span> History
                </span>
              </div>
            </nav>
          </div>
        </article>
      </Appliance>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
  }),
  { firstAppliance }
)(AppliancesListNewItem);
