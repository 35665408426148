import { parse } from "querystring";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga";
import config from "../config";

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(config.google.trackingId);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
    if (window.location.href.includes("localhost")) {
      console.log(
        "track pageview (",
        initialized,
        "):",
        location.pathname + location.search
      );
    }
  }, [initialized, location]);
};

export const useQuery = () => {
  return parse(useLocation().search.replace(/^\?/, ""));
};
