import moment from "moment";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { TransitionGroup, Transition } from "react-transition-group";
import Modal from "../../components/Modal/Modal";
import Notification from "../../components/Notification/Notification";
import ProviderLoginForm from "../../components/ProviderLoginForm/ProviderLoginForm";
import ProviderHeader from "../../components/ProviderHeader/ProviderHeader";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
import { logout } from "../../redux/modules/auth";
import * as serviceRequestActions from "../../redux/modules/serviceRequest";
import { VideoFile } from "../Appliances/Appliances";
import { useQuery } from "../../utils/hooks";

function ServiceRequestRetailerAccept({
  accepting,
  changeTab,
  error,
  logout,
  retailerAccept,
  stripeToken,
  selectedTab,
  serviceRequest,
  user,
}) {
  const modalRef = useRef();
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    retailerAccept(params.id, query.token);
  }, [retailerAccept, params.id, query.token]);

  const makePayment = () => {
    let stripeData = null;
    if (user.stripeCustomer && user.stripeCustomer.defaultSource) {
      stripeData = { type: "customer", customer: user.stripeCustomer };
    } else if (stripeToken) {
      stripeData = { type: "token", token: stripeToken };
    }

    retailerAccept(params.id, query.token, stripeData);
  };

  const sr = serviceRequest;
  const paid = (sr && sr.request && sr.request.paid) || false;

  let stripeSource = null;
  if (user && user.stripeCustomer && user.stripeCustomer.defaultSource) {
    stripeSource = { ...user.stripeCustomer.defaultSource };
  } else if (stripeToken) {
    stripeSource = { ...stripeToken.card };
  }

  const isProvider = user && user.isProvider;

  return (
    <div className="hero is-fullheight is-bold">
      <Helmet title="Accept NoRepair - Service Request" />

      <div className="hero-header">
        <ProviderHeader />
      </div>

      <div className="hero-body">
        <div className="container">
          {accepting && (
            <div className={"st-overlay is-overlay"}>
              <span className={"st-loader loader"}></span>
            </div>
          )}

          {error && !error.type && (
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <h1 className="title is-danger">Error!</h1>
              <h2 className="subtitle">{error.message}</h2>
            </div>
          )}

          {!accepting && !paid && (
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <h1 className="title is-info">Payment Required</h1>
              <h2 className="subtitle">
                A $3.99 fee is required to accept this request and get access to
                the customer's info.
                {!user && (
                  <span>
                    <br />
                    <br />
                    If you don't have an accout yet:{" "}
                    <a
                      href="http://servicetapp.com/service-provider-signup/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Signup Here
                    </a>
                    .
                  </span>
                )}
              </h2>

              {user && !isProvider && !("provider" in user.tokens) && (
                <div>
                  <p className="control">
                    You must have a provider account in order to accept this
                    request. Please logout and try again. If you think this is
                    an error, please contact support at{" "}
                    <a href="mailto:support@servicetapp.com">
                      support@servicetapp.com
                    </a>
                    .
                  </p>
                  <p className="control">
                    <button
                      onClick={logout}
                      className="button is-danger is-fullwidth"
                    >
                      Logout
                    </button>
                  </p>
                </div>
              )}

              {user && !isProvider && "provider" in user.tokens && (
                <div>
                  <p className="control">
                    It appears you have not completed the provider account sign
                    up process. Before you can start accepting requests, you
                    must pay the signup fee.
                  </p>
                  <p className="control">
                    <a
                      href={`/providerSignup/${user._id}?token=${
                        user.tokens.provider
                      }&redirect=${encodeURIComponent(
                        window.location.pathname + window.location.search
                      )}`}
                      className="button is-danger is-fullwidth"
                    >
                      Complete Sign Up
                    </a>
                  </p>
                </div>
              )}

              {!user && !isProvider && (
                <div>
                  <p className="control">
                    <button
                      onClick={() => changeTab("login")}
                      className="button is-danger is-fullwidth"
                    >
                      Login
                    </button>
                  </p>
                </div>
              )}

              {error && error.type && error.type === "StripeCardError" && (
                <Notification
                  type="danger"
                  message={
                    error.message + " Would you like to try a different card?"
                  }
                />
              )}

              {isProvider &&
                (!stripeSource ||
                  (error &&
                    error.type &&
                    error.type === "StripeCardError")) && <StripePaymentForm />}

              {isProvider && stripeSource && !error && (
                <div>
                  {/* TODO: It would be nice to implement this
                <p className="control">
                  <a onClick={() => {}} className="button is-warning is-fullwidth">Change Payment Method</a>
                </p> */}
                  <p className="control">
                    <button
                      onClick={makePayment}
                      className="button is-primary is-fullwidth"
                    >
                      Pay $3.99 with {stripeSource.brand} ....
                      {stripeSource.last4}
                    </button>
                  </p>
                </div>
              )}
            </div>
          )}

          {!accepting && !error && paid && (
            <div>
              <h1 className="title is-success">NoRepair Accepted!</h1>
              {/* <h2 className="subtitle">Thanks for letting us know!</h2> */}

              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td>Customer:</td>
                    <th>
                      {sr.name.first} {sr.name.last}
                    </th>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <th>
                      {sr.appliance.location.street}
                      <br />
                      {sr.appliance.location.city},{" "}
                      {sr.appliance.location.state} {sr.appliance.location.zip}
                    </th>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <th>
                      <a href={`tel://${sr.phoneNumber.replace(/\D/g, "")}`}>
                        {sr.phoneNumber}
                      </a>
                    </th>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <th>
                      <a href={`mailto://${sr.email}`}>{sr.email}</a>
                    </th>
                  </tr>
                  <tr>
                    <td>Appliance:</td>
                    <th>
                      {sr.appliance.brand.name} {sr.appliance.modelNumber}{" "}
                      {sr.appliance.type.name}
                    </th>
                  </tr>
                  <tr>
                    <td>Serial #:</td>
                    <th>{sr.appliance.serialNumber}</th>
                  </tr>
                  <tr>
                    <td>Purchased:</td>
                    <th>{sr.appliance.purchaseDate}</th>
                  </tr>
                  {(() => {
                    if (sr.appliance.images.length > 0) {
                      return (
                        <tr>
                          <td>Images:</td>
                          <th>
                            {sr.appliance.images.map((img, idx) => (
                              <a
                                key={idx}
                                href={img}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={img}
                                  style={{ maxWidth: 300 }}
                                  alt=""
                                />
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  <tr>
                    <td>Symptoms:</td>
                    <th className="content">
                      <ul className="mt0">
                        {sr.symptoms.map((symp, idx) => (
                          <li key={idx}>{symp}</li>
                        ))}
                      </ul>
                    </th>
                  </tr>
                  {/*
                    HACK: Was getting a warning about no text nodes allowed inside a <tbody>
                    HACK: So this IIFE seems to prevent react from outputting <!-- react-empty: # -->
                  */}
                  {(() => {
                    if (sr.other) {
                      return (
                        <tr>
                          <td>Notes:</td>
                          <th>{sr.other}</th>
                        </tr>
                      );
                    }
                  })()}
                  {(() => {
                    if (sr.images.length > 0) {
                      return (
                        <tr>
                          <td>Images:</td>
                          <th>
                            {sr.images.map((img, idx) => (
                              <a
                                key={idx}
                                href={img}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={img}
                                  style={{ maxWidth: 300 }}
                                  alt=""
                                />
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  {(() => {
                    if (sr.videos.length > 0) {
                      return (
                        <tr>
                          <td>Videos:</td>
                          <th>
                            {sr.videos.map((vid, idx) => (
                              <a
                                key={idx}
                                href={vid}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <VideoFile>
                                  <i className="fa fa-file-video-o"></i>
                                </VideoFile>
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  <tr>
                    <td>Submitted:</td>
                    <th>
                      <span
                        title={moment(sr.createdAt).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      >
                        {moment(sr.createdAt).fromNow()}
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <TransitionGroup>
        {selectedTab === "login" && !user && (
          <Transition key="login" nodeRef={modalRef} timeout={500}>
            {(transitionState) => (
              <Modal
                ref={modalRef}
                transitionState={transitionState}
                title="Provider Login"
                bodyClass="content has-text-centered"
                contentStyles={{ maxWidth: 400 }}
                cancelText="Cancel"
                cancel={() => changeTab("")}
                saveText="Login"
              >
                {(submitBtnRef) => (
                  <ProviderLoginForm submitBtnRef={submitBtnRef} />
                )}
              </Modal>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </div>
  );
}

export default connect(
  (state) => ({
    accepting: state.serviceRequest.accepting,
    error: state.serviceRequest.error,
    selectedTab: state.serviceRequest.selectedTab,
    serviceRequest: state.serviceRequest.data,
    stripeToken: state.stripe.token,
    user: state.auth.user,
  }),
  { ...serviceRequestActions, logout }
)(ServiceRequestRetailerAccept);
