import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { clearLoginError, login } from "../../redux/modules/auth";
import {
  createValidator,
  email as isEmail,
  minLength,
  maxLength,
  required as isRequired,
} from "../../utils/validation";
import Notification from "../Notification/Notification";
import LoginInput from "../LoginInput/LoginInput";

const validator = createValidator({
  email: [isRequired, isEmail, minLength(6), maxLength(40)],
  password: [isRequired, minLength(4), maxLength(40)],
});

function ProviderLoginForm({
  submitBtnRef,
  loginError,
  login,
  clearLoginError,
}) {
  const loginProvider = (values) => {
    login(values.email, values.password);
  };

  const forgotLink = ""; // (<Link to="forgot" onClick={clearLoginError}>Forgot Password?</Link>);

  return (
    <Form
      initialValues={{ email: "", password: "" }}
      onSubmit={loginProvider}
      validate={validator}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {loginError && (
            <Notification
              type="danger"
              message={loginError.message}
              link={forgotLink}
              dismiss={clearLoginError}
            />
          )}

          <Field name="email" type="email">
            {(field) => <LoginInput field={field} label="Email" />}
          </Field>
          <Field name="password" type="password">
            {(field) => <LoginInput field={field} label="Password" />}
          </Field>

          <p className="control is-hidden">
            <button ref={submitBtnRef} type="submit">
              Submit
            </button>
          </p>
        </form>
      )}
    </Form>
  );
}

export default connect(
  (state) => ({
    loggingIn: state.auth.loggingIn,
    loginError: state.auth.loginError,
    user: state.auth.user,
  }),
  { clearLoginError, login }
)(ProviderLoginForm);
