import React from 'react';

const Range = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="15.2 8 49.8 64.2" {...props}>
      <path fill={fill} d="M44.8,30.2h-10c0,0-0.2,0-0.2,0.2l-1.4,5.8v0.2c0,0,0,0,0.2,0h13h0.2v-0.2L44.8,30.2C45,30.2,45,30.2,44.8,30.2z M33.8,36
      	l1.2-5.4h9.8l1.4,5.4H33.8z M57.8,30.2L57.8,30.2H47.2H47v0.2l2.2,5.8c0,0,0,0.2,0.2,0.2h13c0,0,0.2,0,0.2-0.2V36L57.8,30.2z
      	 M49.6,36l-2-5.4h10.2L62,36H49.6z M32.6,30.2H22h-0.2L17.2,36v0.2c0,0,0,0.2,0.2,0.2h13c0,0,0.2,0,0.2-0.2l2.2-5.8
      	C32.8,30.4,32.8,30.2,32.6,30.2C32.8,30.2,32.6,30.2,32.6,30.2z M30.2,36H17.8l4.4-5.4h10.2L30.2,36z M54.4,39.4
      	c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C55.8,40,55.2,39.4,54.4,39.4z M54,41.6
      	c-0.4-0.2-0.6-0.6-0.6-1s0.2-0.8,0.6-1V41.6z M54.8,41.6h-0.2h-0.2v-2h0.2c0.2,0,0.2,0,0.2,0V41.6z M55,41.6V40
      	c0.2,0.2,0.4,0.4,0.4,0.8C55.4,41.2,55.2,41.4,55,41.6z M65,43.6V38l0,0c0-0.2,0-0.4-0.2-0.6c-0.2-0.2-0.4-0.2-0.6-0.4v-0.6v-0.2
      	l-5.6-6.8V9c0-0.2,0-0.2-0.2-0.4L57.2,8H57H22.6h-0.2l-1,0.8c0,0-0.2,0.2-0.2,0.4v20.4l-5.4,6.8v0.2v0.6c-0.2,0.2-0.6,0.6-0.6,1.2
      	v0.2c0,0.6,0,5,0,5c0,0.2,0,0.2,0.2,0.2V44v0.2V45l0,0c0,0.2,0,2.8,0,6.2c0,6.8,0,17,0,17.2l0,0c0,0.2,0,0.4,0.2,0.8
      	s0.6,0.6,1.2,0.6l0,0h2c0,0.2,0.2,0.6,0.4,0.8c0.4,0.4,1,0.8,2.2,0.8l0,0l0,0l0,0L22,72h0.2h2h0.2c0,0,0,0,0-0.2v-0.6
      	c1.8,0,4,0,6.8,0c8.4,0,19.6,0,25,0V72c0,0,0,0,0,0.2c0,0,0,0,0.2,0h2h0.2l0.8-0.8l0,0l0,0c0.4,0,2-0.2,2.2-1.6h2
      	c0,0,1.2-0.2,1.4-1.4V43.6L65,43.6z M22,9.2l0.8-0.6h34l0.8,0.6V29H57V10l0,0l0,0H22.6l0,0l0,0v19.2H22V9.2z M56.8,29.2h-34v-19h34
      	V29.2z M16.4,36.6l5.4-6.8h36l5.4,6.6v0.4H16.4V36.6z M16.2,37.8h47.6c0.6,0,0.4,0.2,0.4,0.2l0,0l0,0H15.8
      	C16,37.8,16.2,37.8,16.2,37.8z M15.8,38.4L15.8,38.4h48.4l0,0H15.8L15.8,38.4L15.8,38.4z M15.8,41c0-0.6,0-1.2,0-1.8
      	c0-0.2,0-0.2,0-0.4h48.4V43H15.8C15.8,42.6,15.8,41.8,15.8,41z M15.8,44.2L16,44h26.8c0,0.2,0,0.4,0,0.6c-20.8,0-25.8,0-27,0
      	C15.8,44.6,15.8,44.2,15.8,44.2z M15.8,45.2c0.4,0,1.2,0,3,0c3.4,0,10.2,0,23.8,0v16.4H15.8c0-3.4,0-7.4,0-10.6
      	C15.8,48.2,15.8,46,15.8,45.2z M16.6,68.8c-0.8,0-0.8-0.4-0.8-0.6l0,0c0-0.2,0-2.8,0-6.2h27v6.8H16.6z M21.2,70.4L21.2,70.4
      	L21.2,70.4C21.4,70.6,21.4,70.6,21.2,70.4C21.2,70.6,21.2,70.4,21.2,70.4z M24,71.8h-1.6L22,71.2c0.4,0,1.2,0,2.2,0v0.6H24z
      	 M58.2,71.8h-1.6v-0.6c1,0,1.6,0,2.2,0L58.2,71.8z M59.4,70.4C59.4,70.4,59.2,70.4,59.4,70.4c-0.2,0-17,0-28.4,0c-2.8,0-5.2,0-7,0
      	c-0.8,0-1.6,0-2,0c-0.2,0-0.4,0-0.6,0h-0.2l0,0l0,0l0,0c-1.4,0-1.8-0.6-1.8-1H61C60.6,70.4,59.6,70.4,59.4,70.4z M64.2,68.2
      	c0,0.6-0.6,0.6-0.6,0.6h-20v-24l0,0V44h20.6V68.2z M21.6,54.6h15.2c1.2,0,2-1,2-2v-3.4c0-1.2-1-2-2-2H21.6c-1.2,0-2,1-2,2v3.4
      	C19.6,53.6,20.6,54.6,21.6,54.6z M24.8,54.2h-2.4v-1h2.4V54.2z M20,49.2c0-0.8,0.8-1.6,1.6-1.6h15.2c0.8,0,1.6,0.8,1.6,1.6v3H20
      	V49.2z M38.6,52.6c0,0.8-0.8,1.6-1.6,1.6H25.2V53c0,0,0,0,0-0.2c0,0,0,0-0.2,0h-2.8c0,0,0,0-0.2,0c0,0,0,0,0,0.2v1.2h-0.4
      	c-0.8,0-1.6-0.8-1.6-1.6H38.6z M44.6,30.8h-9.2V31h9.2V30.8z M44.6,31.4H35v0.2h9.6V31.4z M35,32h10v-0.2H35V32z M34.8,32.6h10.4
      	v-0.2H34.8C34.8,32.4,34.8,32.6,34.8,32.6z M34.6,33.2h10.6V33H34.6V33.2z M34.6,33.6h10.8v-0.2H34.6V33.6z M34.4,34.2h11.2V34H34.4
      	V34.2z M34.2,34.8h11.4v-0.2H34.2C34.2,34.6,34.2,34.8,34.2,34.8z M51.8,33c-0.2,0-1,0.2-1,1v0.2c0,0,0,0,0,0.2s0,0,0.2,0.2
      	c0.4,0.4,1.6,1,4.2,1l0,0c0,0,0.2,0,0.4,0l0,0H56c0,0,0,0,0.2,0c0.8,0,2.2-0.4,2.6-1.2l0,0c0,0,0,0,0-0.2s-0.2-0.8-1-1.2
      	c-0.4-0.6-1.6-1-3-1C53.4,31.8,52,32.2,51.8,33z M55.6,35.2C55.4,35.2,55.4,35.2,55.6,35.2c-0.2,0-0.2,0-0.2,0
      	c-1.6,0-2.6-0.2-3.4-0.6c-0.4-0.2-0.6-0.2-0.6-0.4c0,0-0.2,0-0.2-0.2l0,0l0,0l0,0c0-0.4,0.4-0.4,0.6-0.6c0.2,0.8,1.6,1.2,3,1.2
      	c0.2,0,0.4,0,0.8,0L55.6,35.2z M55.6,33C55.4,33,55.4,33,55.6,33c-0.2,0-0.2,0.2-0.2,0.2v0.2c-0.2,0-0.2,0-0.4,0c-0.6,0-1-0.2-1-0.4
      	s0.4-0.4,1-0.4s1,0.2,1,0.4c0,0,0,0.2-0.2,0.2C55.8,33.2,55.8,33.2,55.6,33z M57.8,33.4c0.6,0.2,0.6,0.6,0.6,0.6l0,0
      	c-0.2,0.6-1.4,0.8-2.2,1v-0.6C57.2,34.4,57.8,34,57.8,33.4z M57.6,33.2c0,0.2-0.4,0.6-1.4,0.8c0-0.2-0.2-0.4-0.2-0.6
      	c0.2-0.2,0.2-0.2,0.2-0.4c0-0.4-0.6-0.6-1.2-0.6c-0.6,0-1.2,0.2-1.2,0.6c0,0.4,0.6,0.6,1.2,0.6c0.2,0,0.2,0,0.4,0l0,0l0.2,0.4
      	c-0.2,0-0.4,0-0.6,0c-0.8,0-1.4-0.2-2-0.4c-0.4-0.2-0.8-0.4-0.8-0.6c0-0.2,0.2-0.4,0.8-0.6c0.4-0.2,1.2-0.4,2-0.4s1.4,0.2,2,0.4
      	C57.4,32.8,57.6,33,57.6,33.2z M21.8,33c-0.8,0.4-1,0.8-1,1.2c0,0,0,0,0,0.2l0,0l0,0c0.4,0.8,1.8,1.2,2.6,1.2c0,0,0,0,0.2,0H24l0,0
      	c0.2,0,0.4,0,0.4,0l0,0c1.8,0,2.8-0.4,3.4-0.6c0.4-0.2,0.6-0.4,0.8-0.4c0.2-0.2,0.2-0.2,0.2-0.2l0,0v-0.2c0-0.6-0.8-0.8-1-0.8
      	c-0.2-0.8-1.6-1.2-3-1.2C23.4,31.8,22,32.2,21.8,33z M23.2,35.2c-0.8-0.2-1.8-0.4-2.2-1l0,0c0-0.2,0-0.4,0.6-0.6
      	c0.2,0.6,0.6,1,1.6,1.2V35.2z M23.8,33.4C23.8,33.2,23.8,33.2,23.8,33.4c0-0.2,0-0.2,0.2-0.4c0.2,0,0.4-0.2,0.8-0.2
      	c0.2,0,0.6,0,0.8,0.2c0.2,0,0.2,0.2,0.2,0.2s0,0.2-0.2,0.2s-0.4,0.2-0.8,0.2c-0.2,0-0.2,0-0.4,0v-0.2v-0.2h-0.2
      	C23.8,33.2,23.8,33.2,23.8,33.4z M27.8,33.4c0.2,0,0.6,0.2,0.6,0.4c0,0,0,0,0,0.2c-0.2,0.2-1,1.2-4,1.2l0,0c0,0,0,0-0.2,0v-0.6
      	c0.2,0,0.4,0,0.8,0C26.2,34.6,27.4,34.2,27.8,33.4z M22.8,32.6c0.4-0.2,1.2-0.4,2-0.4s1.4,0.2,2,0.4c0.4,0.2,0.8,0.4,0.8,0.6
      	c0,0.2-0.2,0.4-0.8,0.6c-0.4,0.2-1.2,0.4-2,0.4c-0.2,0-0.4,0-0.6,0l0.2-0.4l0,0c0.2,0,0.2,0,0.4,0c0.6,0,1.2-0.2,1.4-0.6
      	c0-0.6-0.6-0.6-1.4-0.8c-0.6,0-1.2,0.2-1.4,0.8c0,0.2,0,0.4,0.2,0.4c0,0.2-0.2,0.4-0.2,0.4c-1,0-1.4-0.4-1.4-0.8
      	C22,33,22.2,32.8,22.8,32.6z M51,39.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S51.8,39.4,51,39.4z M50.6,41.6
      	c-0.4-0.2-0.6-0.6-0.6-1s0.2-0.8,0.6-1V41.6z M51.2,41.8H51h-0.2v-2H51c0.2,0,0.2,0,0.2,0V41.8z M51.4,41.6V40
      	c0.2,0.2,0.4,0.4,0.4,0.8C52,41.2,51.8,41.4,51.4,41.6z M29.4,39.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
      	S30.2,39.4,29.4,39.4z M29,41.6c-0.4-0.2-0.6-0.6-0.6-1s0.2-0.8,0.6-1V41.6z M29.8,41.6h-0.2h-0.2v-2h0.2c0.2,0,0.2,0,0.2,0V41.6z
      	 M30,41.6V40c0.2,0.2,0.4,0.4,0.4,0.8C30.4,41.2,30.2,41.4,30,41.6z M26,39.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
      	s1.4-0.6,1.4-1.4S26.8,39.4,26,39.4z M25.6,41.6c-0.4-0.2-0.6-0.6-0.6-1s0.2-0.8,0.6-1V41.6z M26.4,41.6h-0.2H26v-2h0.2
      	c0.2,0,0.2,0,0.2,0V41.6z M26.6,41.6V40c0.2,0.2,0.4,0.4,0.4,0.8C27,41.2,26.8,41.4,26.6,41.6z M42.8,39.4c-0.8,0-1.4,0.6-1.4,1.4
      	c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S43.6,39.4,42.8,39.4z M42.4,41.6c-0.4-0.2-0.6-0.6-0.6-1s0.2-0.8,0.6-1V41.6z
      	 M43,41.6h-0.2h-0.2v-2h0.2c0.2,0,0.2,0,0.2,0V41.6z M43.2,41.6V40c0.2,0.2,0.4,0.4,0.4,0.8C43.8,41.2,43.6,41.4,43.2,41.6z"/>
    </svg>
  );
};

export default Range;
