import styled from "styled-components";
import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../redux/modules/auth";
import Icon from "../Icon/Icon";

const StyledIcon = styled(Icon)`
  margin: -5px 0;

  .outer {
    fill: var(--blue);
  }

  .iconTitle {
    margin-left: 5px;
    font-size: 1.25em;
  }
`;

const LoggedIn = styled.span`
  opacity: 0.5;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 300;
`;

function Header({ appliances, isHero, logout, user }) {
  const history = useHistory();
  const [menuIsActive, setMenuIsActive] = useState(false);

  const handleLogout = (evt) => {
    evt.preventDefault();
    logout();
    // HACK: to get around Redux action... we should just call API here directly?
    // and then redirect in the .then() callback?
    setTimeout(() => history.push("/login"), 500);
  };

  const toggleMenu = () => {
    setMenuIsActive(!menuIsActive);
  };

  return (
    <header>
      <nav
        className={
          "navbar" +
          (isHero ? " hero" : "") +
          (menuIsActive ? " is-active" : "")
        }
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <StyledIcon icon="logo" height="36" width="" />
            </Link>

            <button
              className={"navbar-burger" + (menuIsActive ? " is-active" : "")}
              aria-label="menu"
              aria-expanded="false"
              onClick={toggleMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div className={"navbar-menu" + (menuIsActive ? " is-active" : "")}>
            <div className="navbar-start">
              <NavLink
                to="/"
                exact
                className="navbar-item is-hidden-mobile"
                activeClassName="is-active"
              >
                Appliances
              </NavLink>
              {user && !user.guest && (
                <NavLink
                  to="/locations"
                  className="navbar-item is-hidden-mobile"
                  activeClassName="is-active"
                >
                  Locations
                </NavLink>
              )}
              {user && !user.guest && (
                <NavLink
                  to="/account"
                  className="navbar-item is-hidden-mobile"
                  activeClassName="is-active"
                >
                  Account
                </NavLink>
              )}
              {/* {!user || !!user.guest && <Link to="/login" className="navbar-item is-hidden-mobile" activeClassName="is-active">Login</Link>} */}
            </div>

            <div className="navbar-end">
              <NavLink
                to="/"
                className="navbar-item is-hidden-tablet"
                activeClassName="is-active"
              >
                Appliances
              </NavLink>
              {user && !user.guest && (
                <NavLink
                  to="/locations"
                  className="navbar-item is-hidden-tablet"
                  activeClassName="is-active"
                >
                  Locations
                </NavLink>
              )}

              {!user ||
                (!!user.guest && (
                  <NavLink
                    to="/login"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    Login
                  </NavLink>
                ))}
              {user && !!user.guest && (
                <span className="navbar-item">
                  {appliances.length > 0 ? (
                    <NavLink
                      to="/account"
                      className="button is-primary"
                      activeClassName="is-active"
                    >
                      Create Account
                    </NavLink>
                  ) : (
                    <NavLink
                      to="/sign-up"
                      className="button is-primary"
                      activeClassName="is-active"
                    >
                      Sign Up
                    </NavLink>
                  )}
                </span>
              )}

              {user && !user.guest && (
                <NavLink
                  to="/account"
                  className="navbar-item is-hidden-tablet"
                  activeClassName="is-active"
                >
                  Account
                </NavLink>
              )}
              {user && !user.guest && (
                <span className="navbar-item">
                  <LoggedIn>Logged in as</LoggedIn>
                  &nbsp;
                  <strong>{user.name.first || user.email}</strong>
                </span>
              )}
              {user && !user.guest && (
                <span className="navbar-item">
                  <Link
                    to="/logout"
                    className="button is-primary"
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>
      {menuIsActive && (
        <Backdrop className="animated fadeIn fast" onClick={toggleMenu} />
      )}
    </header>
  );
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    user: state.auth.user,
  }),
  {
    logout,
  }
)(Header);
