const isEmpty = (value) =>
  value === undefined || value === null || value === "";

const join = (rules) => (value, data) =>
  rules
    .map((rule) => rule(value, data))
    .filter((error) => !!error)[0 /* first error */];

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (
    !isEmpty(value) &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    return "Invalid email address";
  }
}

export function phone(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value) && !/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/i.test(value)) {
    return "Invalid phone number";
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return "Required";
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return "Must be an integer";
  }
}

export function number(value) {
  if (Number.isNaN(Number(value))) {
    return "Must be a number";
  }
}

export function month(value) {
  const num = Number(value);
  if (Number.isNaN(num) || num < 1 || num > 12) {
    return "Must be a valid month";
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(", ")}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return `Does not match ${field}.`;
      }
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}
