import React from 'react';

const Microwave = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="7.8 21 64.4 37.6" {...props}>
      <path fill={fill} d="M61.2,56.6h8h0.2c0,0,0,0,0-0.2V54v-0.2c0,0,0,0-0.2,0h-8H61c0,0,0,0,0,0.2v2.4C61,56.4,61,56.4,61.2,56.6
      	C61,56.4,61,56.6,61.2,56.6z M61.4,54H69v2h-7.6V54z M64.6,38.6c1.2,0,2.2-0.4,3.2-1.2c0.8-0.8,1.2-2,1.2-3.2S68.6,32,67.8,31
      	c-0.8-0.8-2-1.2-3.2-1.2c-1.2,0-2.2,0.4-3.2,1.2c-0.8,0.8-1.2,2-1.2,3.2s0.4,2.2,1.2,3.2C62.2,38.2,63.4,38.6,64.6,38.6z M68.2,34.2
      	c0,0.2,0,0.4,0,0.8h-4v-1.4h4C68,33.8,68.2,34,68.2,34.2z M62,31.8c0.8-0.8,1.6-1,2.6-1s1.8,0.4,2.6,1c0.4,0.4,0.6,1,0.8,1.4h-4.2
      	l0,0H62h-0.8C61.4,32.6,61.6,32.2,62,31.8z M61,34.2c0-0.2,0-0.4,0-0.6h2.6V35H61C61,34.8,61,34.4,61,34.2z M63.8,35.4L63.8,35.4H68
      	c-0.2,0.6-0.4,1-0.8,1.4c-0.6,0.6-1.6,1-2.6,1s-1.8-0.4-2.6-1c-0.4-0.4-0.6-0.8-0.8-1.4H63.8z M64.6,40c-2.4,0-4.4,2-4.4,4.4
      	s2,4.4,4.4,4.4s4.4-2,4.4-4.4S67,40,64.6,40z M67.6,42.4l-1.8,1.8l-1-1l0,0l1.8-1.8C66.8,41.6,67.2,42,67.6,42.4z M61.4,46
      	c-0.2-0.4-0.4-1-0.4-1.6c0-2,1.6-3.6,3.6-3.6c0.6,0,1,0.2,1.6,0.4L61.4,46z M61.6,46.4l2.8-2.8l1,1l-2.8,2.8
      	C62.2,47,61.8,46.8,61.6,46.4z M68.2,44.4c0,2-1.6,3.6-3.6,3.6c-0.6,0-1.2-0.2-1.6-0.4l3-3l0,0l1.8-1.8C68,43.2,68.2,43.8,68.2,44.4
      	z M72,21.2L71.8,21H8.2C8,21,8,21,8,21.2l-0.2,0.2v36.8c0,0.2,0,0.2,0.2,0.2l0.2,0.2h63.6c0.2,0,0.2,0,0.2-0.2l0.2-0.2V21.6
      	C72.2,21.4,72.2,21.4,72,21.2z M8.6,58V22H55c1.2,5,2,11.2,2,18s-0.8,13-2,18H8.6z M71.4,58H55.8c0.4-1.8,0.8-4,1.2-6.2h14.2V58
      	H71.4z M71.4,51H57.2c0.4-3.4,0.8-7,0.8-11c0-6.8-0.8-13-2-18h15.6v29H71.4z M49.4,27.8H16c-0.2,0-0.2,0-0.2,0.2l-0.2,0.2V49
      	c0,0.2,0,0.2,0.2,0.2l0.2,0.2h33.4c0.2,0,0.2,0,0.2-0.2l0.2-0.2V28.2c0-0.2,0-0.2-0.2-0.2C49.6,27.8,49.6,27.8,49.4,27.8z M49,48.6
      	H16.4v-20H49V48.6z M19.4,46.2h26.8h0.2c0,0,0,0,0-0.2V31.4v-0.2c0,0,0,0-0.2,0H19.4h-0.2c0,0,0,0,0,0.2V46
      	C19.2,46,19.2,46,19.4,46.2L19.4,46.2z M19.6,31.6h26.2v14.2H19.6V31.6z"/>
    </svg>
  );
};

export default Microwave;
