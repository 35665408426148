import { useEffect, useRef } from "react";

export default function LoginInput(props) {
  const inputRef = useRef();

  useEffect(() => {
    if (props.focusOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.focusOnMount]);

  const { label, field } = props;

  const classNames =
    "input" +
    (!field.meta.pristine ? " is-touched" : "") +
    (field.meta.touched && field.meta.error ? " is-danger has-error" : "");

  return (
    <div className="field">
      <label htmlFor={field.input.name} className="label">
        {label}
      </label>
      <div className="control">
        <input
          ref={inputRef}
          {...field.input}
          {...props}
          className={classNames}
        />
      </div>
      {field.meta.touched && field.meta.error && (
        <p className="help is-danger">{field.meta.error}</p>
      )}
    </div>
  );
}
