import {
  createStore as _createStore,
  applyMiddleware,
  // compose
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createMiddleware from "./middleware/clientMiddleware";
// import { syncHistory } from 'react-router-redux';

export default function createStore(/* history, */ client) {
  // Sync dispatched route actions to the history
  // const reduxRouterMiddleware = syncHistory(history);

  const middleware = [
    createMiddleware(client),
    // reduxRouterMiddleware
  ];

  const reducer = require("./modules/reducer").default;
  const store = _createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  // reduxRouterMiddleware.listenForReplays(store);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./modules/reducer", () => {
      store.replaceReducer(require("./modules/reducer"));
    });
  }

  return store;
}
