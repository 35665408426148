import React, { Component } from "react";

import { connect } from "react-redux";
import HistoryItem from "./HistoryItem";

class RepairHistory extends Component {
  shouldComponentUpdate(nextProps) {
    // NOTE: This prevents a console error when there is no "currentAppliance" to render when closing the modal
    return nextProps.editing !== null;
  }

  render() {
    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];
    const { serviceRequests } = currentAppliance;
    // console.log( serviceRequests );

    return (
      <div>
        {serviceRequests
          // .filter(sr => sr.status >= 4)
          .map((sr) => (
            <HistoryItem
              key={sr._id}
              serviceRequest={sr}
              appliance={currentAppliance}
            />
          ))}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    editing: state.appliances.editing,
  }),
  {}
)(RepairHistory);
