const CLEAR_ERROR = "service-tapp/stripe/CLEAR_ERROR";
const CLEAR_TOKEN = "service-tapp/stripe/CLEAR_TOKEN";
const RESPONSE_ERROR = "service-tapp/stripe/RESPONSE_ERROR";
const RESPONSE_SUCCESS = "service-tapp/stripe/RESPONSE_SUCCESS";

const initialState = {
  token: null,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };
    case RESPONSE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case RESPONSE_SUCCESS:
      return {
        ...state,
        error: null,
        token: action.token,
      };
    default:
      return state;
  }
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function clearToken() {
  return { type: CLEAR_TOKEN };
}

export function handleResponse(status, response) {
  if (status === 200) {
    return { type: RESPONSE_SUCCESS, token: response.token };
  }

  return { type: RESPONSE_ERROR, status, error: response.error };
}
