import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import * as authActions from "../../redux/modules/auth";
import LoginInput from "../LoginInput/LoginInput";
import Notification from "../Notification/Notification";
import {
  createValidator,
  email,
  maxLength,
  minLength,
  required,
} from "../../utils/validation";

export const StyledForm = styled.form`
  margin-bottom: 1rem;
`;

const loginValidation = createValidator({
  email: [required, email, minLength(6), maxLength(40)],
  password: [required, minLength(4), maxLength(40)],
});

function LoginForm({ login, loggingIn, loginError, clearLoginError }) {
  return (
    <Form
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        const { email, password } = values || {};
        login(email, password);
      }}
      validate={loginValidation}
    >
      {({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {loginError && (
            <Notification
              type="danger"
              message={loginError.message}
              link={
                <Link to="forgot" onClick={clearLoginError}>
                  Forgot Password?
                </Link>
              }
              dismiss={clearLoginError}
            />
          )}

          <Field name="email">
            {(field) => (
              <LoginInput
                field={field}
                type="email"
                label="Email Address"
                autoComplete="off"
              />
            )}
          </Field>

          <Field name="password">
            {(field) => (
              <LoginInput field={field} type="password" label="Password" />
            )}
          </Field>

          <button
            className={
              "button is-primary is-fullwidth" +
              (loggingIn || submitting ? " is-loading" : "")
            }
          >
            Login
          </button>
        </StyledForm>
      )}
    </Form>
  );
}

export default connect(
  (state) => ({
    loggingIn: state.auth.loggingIn,
    loginError: state.auth.loginError,
    user: state.auth.user,
  }),
  { ...authActions }
)(LoginForm);
