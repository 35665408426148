import { Component } from "react";

export const types = ["primary", "info", "success", "warning", "danger"];

export default class Notification extends Component {
  render() {
    const { dismiss, message, link, type } = this.props;

    let typeClass = "";
    if (typeof type !== "undefined" && types.indexOf(type) > -1) {
      typeClass = ` is-${type}`;
    }

    return (
      <div className={"notification" + typeClass}>
        {dismiss && (
          <button className="delete" onClick={dismiss}>
            &nbsp;
          </button>
        )}
        {message} {link}
      </div>
    );
  }
}
