const CHANGE_NAME = 'service-tapp/location/CHANGE_NAME';
const CHANGE_QUERY = 'service-tapp/location/CHANGE_QUERY';
const CHANGE_STREET = 'service-tapp/location/CHANGE_STREET';
const CHANGE_CITY = 'service-tapp/location/CHANGE_CITY';
const CHANGE_STATE = 'service-tapp/location/CHANGE_STATE';
const CHANGE_ZIP = 'service-tapp/location/CHANGE_ZIP';
const REQUEST = 'service-tapp/location/REQUEST';
const RESET = 'service-tapp/location/RESET';
const SEARCH_START = 'service-tapp/location/SEARCH_START';
const SEARCH_SUCCESS = 'service-tapp/location/SEARCH_SUCCESS';
const SEARCH_FAIL = 'service-tapp/location/SEARCH_FAIL';

const initialState = {
  found: false,
  name: '',
  position: { lng: -122.674172, lat: 45.523057 },
  query: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  requested: false,
  requestError: null,
  searching: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_NAME:
      return {
        ...state,
        name: action.name,
      };
    case CHANGE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case CHANGE_STREET:
      return {
        ...state,
        street: action.street,
      };
    case CHANGE_CITY:
      return {
        ...state,
        city: action.city,
      };
    case CHANGE_STATE:
      return {
        ...state,
        state: action.state,
      };
    case CHANGE_ZIP:
      return {
        ...state,
        zip: action.zip,
      };
    case REQUEST:
      return {
        ...state,
        requested: true,
      };
    case RESET:
      return {
        ...initialState,
        position: state.position,
      };
    case SEARCH_START:
      return {
        ...state,
        searching: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        found: true,
        searching: false,
        position: action.pos,
        requestError: null,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        searching: false,
        found: false,
        requested: false,
        requestError: action.message,
      };
    default:
      return state;
  }
}

export function changeName(name) {
  return { type: CHANGE_NAME, name };
}

export function changeQuery(query) {
  return { type: CHANGE_QUERY, query };
}

export function changeStreet(street) {
  return { type: CHANGE_STREET, street };
}

export function changeCity(city) {
  return { type: CHANGE_CITY, city };
}

export function changeState(state) {
  return { type: CHANGE_STATE, state };
}

export function changeZip(zip) {
  return { type: CHANGE_ZIP, zip };
}

export function request() {
  return { type: REQUEST };
}

export function reset() {
  return { type: RESET };
}

export function searchStart() {
  return { type: SEARCH_START };
}

export function searchSuccess(pos) {
  return { type: SEARCH_SUCCESS, pos };
}

export function searchFailed(message) {
  return { type: SEARCH_FAIL, message };
}
