import findIndex from "lodash/findIndex";
import { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import * as applianceActions from "../../redux/modules/appliances";

const Filters = styled.div`
  padding: 20px;
  background: #fafbfd;
  border-bottom: 1px solid #e9ebed;

  .container {
    padding: 0 10px;
  }

  .control:not(:last-child) {
    margin-bottom: 0;
  }

  p.control {
    @media screen and (min-width: 769px) and (max-width: 979px) {
      display: none;
    }
  }

  .field.has-addons .control:last-child .Select-control {
    @media screen and (min-width: 769px) and (max-width: 979px) {
      border-radius: 4px;
    }
  }

  div.control {
    width: 200px;

    @media screen and (max-width: 979px) {
      width: 160px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .level-left .level-item:not(:last-child),
  .level-right .level-item:not(:last-child) {
    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }
`;

const Toggle = styled.div`
  margin-bottom: 0 !important;
  cursor: pointer;

  .subtitle {
    @media screen and (max-width: 768px) {
      line-height: 32px;
    }
  }

  .icon {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      float: right;
    }
  }
`;

class ApplianceFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideFilters: true,
    };
  }

  clearAll = (evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.removeAllFilters();
  };

  toggleFilters = (evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.setState({ hideFilters: !this.state.hideFilters });
  };

  updateBrand = (sel) => {
    const brandId = !sel ? "" : sel.value;
    const { brands } = this.props;
    const brandIndex = findIndex(brands, (b) => b._id === brandId); // eslint-disable-line
    const brand = brandIndex > -1 ? brands[brandIndex] : null;

    this.props.removeFilter("brands");
    if (brand) {
      this.props.addFilter("brands", brand);
    }
  };

  updateLocation = (sel) => {
    const locationId = !sel ? "" : sel.value;
    const { locations } = this.props;
    const locationIndex = findIndex(locations, (b) => b._id === locationId); // eslint-disable-line
    const location = locationIndex > -1 ? locations[locationIndex] : null;

    this.props.removeFilter("locations");
    if (location) {
      this.props.addFilter("locations", location);
    }
  };

  updateType = (sel) => {
    const typeId = !sel ? "" : sel.value;
    const { types } = this.props;
    const typeIndex = findIndex(types, (b) => b._id === typeId); // eslint-disable-line
    const type = typeIndex > -1 ? types[typeIndex] : null;

    this.props.removeFilter("types");
    if (type) {
      this.props.addFilter("types", type);
    }
  };

  render() {
    const {
      appliances = [],
      brands = [],
      filters = { brands: [], locations: [], types: [] },
      locations = [],
      types = [],
    } = this.props;

    const applianceBrands = appliances.reduce(
      (prev, curr) => [...prev, curr.brand._id],
      []
    );
    const selectedBrand = filters.brands.length ? filters.brands[0]._id : null;
    const brandOpts = brands
      .filter(
        (brand) => !appliances.length || applianceBrands.includes(brand._id)
      )
      .map((brand) => ({ value: brand._id, label: brand.name }));

    const selectedLocation = filters.locations.length
      ? filters.locations[0]._id
      : null;
    const locationOpts = locations.map((loc) => ({
      value: loc._id,
      label: loc.name,
    }));

    const applianceTypes = appliances.reduce(
      (prev, curr) => [...prev, curr.type._id],
      []
    );
    const selectedType = filters.types.length ? filters.types[0]._id : null;
    const typeOpts = types
      .filter((type) => !appliances.length || applianceTypes.includes(type._id))
      .map((type) => ({ value: type._id, label: type.name }));

    const { hideFilters } = this.state;

    return (
      <Filters>
        <div className="container">
          <div className="level">
            <div className="level-left">
              <Toggle className={"level-item"} onClick={this.toggleFilters}>
                <span className="icon is-medium">
                  <i
                    className={
                      "fa " + (hideFilters ? "fa-angle-down" : "fa-angle-up")
                    }
                  ></i>
                </span>

                <p className="subtitle is-5">Filter:</p>
              </Toggle>

              <div
                className={
                  "level-item" + (hideFilters ? " is-hidden-mobile" : "")
                }
              >
                <div className="field has-addons">
                  <p className="control">
                    <button className="button is-static" disabled>
                      Brand
                    </button>
                  </p>
                  <div className="control">
                    <Select
                      placeholder="Brand..."
                      options={brandOpts}
                      value={selectedBrand}
                      onChange={this.updateBrand}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  "level-item" + (hideFilters ? " is-hidden-mobile" : "")
                }
              >
                <div className="field has-addons">
                  <p className="control">
                    <button className="button is-static" disabled>
                      Location
                    </button>
                  </p>
                  <div className="control">
                    <Select
                      placeholder="Location..."
                      options={locationOpts}
                      value={selectedLocation}
                      onChange={this.updateLocation}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  "level-item" + (hideFilters ? " is-hidden-mobile" : "")
                }
              >
                <div className="field has-addons">
                  <p className="control">
                    <button className="button is-static" disabled>
                      Type
                    </button>
                  </p>
                  <div className="control">
                    <Select
                      placeholder="Type..."
                      options={typeOpts}
                      value={selectedType}
                      onChange={this.updateType}
                    />
                  </div>
                </div>
              </div>
            </div>

            {(selectedBrand || selectedLocation || selectedType) && (
              <div
                className={
                  "level-right" + (hideFilters ? " is-hidden-mobile" : "")
                }
              >
                <div className="level-item">
                  <button onClick={this.clearAll} className="button is-primary">
                    Clear All
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Filters>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    brands: state.brands.data,
    filters: state.appliances.filters,
    locations: state.locations.data,
    types: state.applianceTypes.data,
  }),
  { ...applianceActions }
)(ApplianceFilters);
