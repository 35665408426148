import React from 'react';

const CookTop = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="8 22.2 64.1 35.4" {...props}>
      <path fill={fill} d="M72,23.8c0-0.8-0.6-1.6-1.6-1.6H9.6c-0.8,0-1.4,0.6-1.6,1.6v28.8l0,0l0,0c0,0.2,0.2,0.6,0.2,0.8c0.2,0.2,0.2,0.4,0.4,0.4
      	c3,2.2,15.8,3.6,31.2,3.8C55.2,57.6,68,56,71,53.8c0.4-0.3,1-0.8,1-1.3C72.1,51.4,72,23.8,72,23.8z M70.8,52.8
      	c0,0.2-0.2,0.2-0.2,0.2C68,55,55.2,56.6,40,56.6S12,55,9.4,53c-0.2,0-0.2-0.2-0.4-0.2c-0.2-0.2-0.2-0.2-0.2-0.2l0,0v-0.2V24
      	c0-0.4,0.4-0.8,0.8-0.8H70c0.4,0,0.8,0.4,0.8,0.8v28.4C70.8,52.4,71,52.6,70.8,52.8z M23.2,49.2c4.8,0,8.8-4,8.8-8.8s-4-8.8-8.8-8.8
      	s-8.8,4-8.8,8.8C14.4,45.2,18.4,49.2,23.2,49.2z M17.4,37.8c0.8-2,2.8-3.4,5-3.8v2.2c-1.2,0.2-2.2,1-2.8,2.2L17.4,37.8z M19.8,39.4
      	C20,39.4,20,39.4,19.8,39.4C20,39.4,20,39.4,19.8,39.4c0,0.2,0,0.2,0,0.2l0,0h-0.4v-0.2H19.8z M19.4,39.2v0.2l-2.2-0.6v-0.2
      	L19.4,39.2z M19.2,40v0.2c0,1,0.4,2,1.2,2.8L19,44.8c-1.2-1.2-2.2-2.8-2.2-4.6c0-0.4,0-0.6,0-1L19.2,40z M20.8,43.6
      	c0,0,0.2,0,0.2,0.2l-1.4,1.8c0,0-0.2,0-0.2-0.2L20.8,43.6z M25.2,43.4C25.2,43.2,25.2,43.2,25.2,43.4C25.2,43.2,25.2,43.2,25.2,43.4
      	c0.2-0.2,0.2-0.2,0.2,0v0.2C25.4,43.4,25.4,43.4,25.2,43.4L25.2,43.4L25,43.6L25.2,43.4L25.2,43.4z M26.2,45.8
      	c-0.8,0.4-1.8,0.8-3,0.8c-1,0-2-0.2-2.8-0.8l1.4-1.8c0.4,0.2,1,0.2,1.6,0.2s1-0.2,1.6-0.4L26.2,45.8z M21.2,43.4L21.2,43.4
      	c-0.2,0-0.2,0-0.2,0H21.2L21.2,43.4c-0.2-0.2-0.2-0.2,0-0.2C21.2,43.2,21.2,43.2,21.2,43.4C21.2,43.2,21.2,43.4,21.2,43.4L21.2,43.4
      	z M20.2,46.2c0.8,0.4,2,0.8,3,0.8c1.2,0,2.2-0.2,3-0.8l0.6,0.8c-1,0.6-2.4,1-3.6,1s-2.4-0.4-3.6-0.8L20.2,46.2z M26.8,45.6l-1.4-1.8
      	c0,0,0.2,0,0.2-0.2L26.8,45.6C26.8,45.4,26.8,45.4,26.8,45.6z M26.2,43c0.6-0.8,1-1.6,1-2.8V40l2.2-0.8c0,0.4,0.2,0.6,0.2,1
      	c0,1.8-0.8,3.4-2,4.6L26.2,43z M26.8,38.4c-0.6-1-1.6-1.8-2.6-2V34c2.2,0.4,4,1.8,4.8,3.6L26.8,38.4z M26.8,39.4h-0.2l0,0l0,0h-0.2
      	l0,0v-0.2h0.2C26.8,39.2,26.8,39.4,26.8,39.4z M29.2,38.4v0.2L27,39.4v-0.2L29.2,38.4z M29.4,38.6v-0.2l0.8-0.2v0.2L29.4,38.6z
      	 M29.2,37.6c-1-2-2.8-3.6-5.2-3.8v-1l0,0c2.8,0.4,5,2,6,4.4L29.2,37.6z M24,37v-0.4c1,0.2,2,1,2.4,2h-0.2c-0.4,0.2-0.6,0.4-0.6,0.8
      	v0.2c0.2,0.4,0.4,0.6,0.8,0.6h0.2l-0.2-0.4l0.2,0.4h0.2v0.2c0,1-0.4,2-1,2.6l0,0c-0.2-0.2-0.4-0.4-0.6-0.4s-0.4,0-0.4,0.2
      	c-0.2,0.2-0.4,0.4-0.4,0.6c0,0.2,0,0.4,0.2,0.4l0,0l0,0c-0.4,0.2-1,0.2-1.4,0.2s-1-0.2-1.4-0.2l0,0c0-0.2,0.2-0.4,0.2-0.4
      	c0-0.2-0.2-0.6-0.4-0.6c-0.2-0.2-0.4-0.2-0.4-0.2c-0.2,0-0.6,0.2-0.6,0.4l0.2,0.2L20.6,43l0,0c-0.6-0.6-1-1.6-1-2.6v-0.2h0.2H20
      	c0.4,0,0.6-0.2,0.8-0.6v-0.2c0-0.4-0.2-0.6-0.6-0.8H20c0.6-1,1.4-1.8,2.6-2V37c0,0.4,0.4,0.8,0.8,0.8C23.6,37.8,24,37.4,24,37z
      	 M22.4,33v1c-2.4,0.2-4.2,1.8-5.2,4l-0.8-0.2C17.4,35,19.6,33.2,22.4,33C22.4,32.8,22.4,32.8,22.4,33z M17,38.4v0.2l-1-0.2v-0.2
      	L17,38.4z M16.8,39.4c0,0.4,0,0.6,0,1c0,2,0.8,3.6,2.2,4.8L18.4,46c-1.6-1.4-2.6-3.4-2.6-5.6c0-0.4,0-0.8,0.2-1.2L16.8,39.4z
      	 M19,46.8C19,46.8,18.8,46.8,19,46.8C18.8,46.8,18.8,46.8,19,46.8c-0.2,0-0.2,0-0.2,0l0,0H19C18.8,46.6,18.8,46.6,19,46.8L19,46.8z
      	 M18.8,46.4l0.6-0.8c0,0,0.2,0,0.2,0.2L18.8,46.4C19,46.4,19,46.4,18.8,46.4z M23.2,48.4c-1.4,0-2.8-0.4-4-1c0.2,0,0.2-0.2,0.2-0.2
      	l0,0c1,0.6,2.4,1,3.6,1c1.4,0,2.6-0.4,3.8-1l0,0c0,0.2,0.2,0.2,0.2,0.2C26,48,24.6,48.4,23.2,48.4z M26.8,45.8
      	C27,45.6,27,45.6,26.8,45.8l0.8,0.6l-0.2,0.2L26.8,45.8z M27.8,46.6C27.8,46.6,27.6,46.8,27.8,46.6C27.6,46.8,27.6,46.6,27.8,46.6
      	L27.8,46.6C27.6,46.6,27.6,46.6,27.8,46.6L27.8,46.6L27.8,46.6L27.8,46.6z M27.6,45.2c1.2-1.2,2-3,2-4.8c0-0.4,0-0.8,0-1l0.8-0.2
      	c0,0.4,0.2,0.8,0.2,1.4c0,2.2-1,4.2-2.4,5.6L27.6,45.2z M31.2,40.4c0,2.4-1,4.6-2.8,6.2c0-0.2,0-0.2-0.2-0.4l0,0
      	c1.6-1.4,2.6-3.4,2.6-5.8c0-0.4,0-1-0.2-1.4c0.2,0,0.2-0.2,0.4-0.4C31.2,39.2,31.2,39.6,31.2,40.4z M30.8,37.4c-0.2,0-0.2,0-0.4,0
      	c0,0,0,0-0.2,0c-1-2.6-3.4-4.4-6.2-4.6c0-0.2-0.2-0.4-0.4-0.4C26.8,32.4,29.6,34.4,30.8,37.4z M23,33C23,32.8,23.2,32.8,23,33
      	C23.2,32.8,23.2,32.8,23,33c0.2,0,0.2,0,0.2,0l0,0v0.8H23l0,0V33z M23,34C23.2,34,23.2,34,23,34c0.2,0,0.2,0,0.2,0v2.2H23l0,0V34z
      	 M23,36.4C23.2,36.4,23.2,36.4,23,36.4c0.2,0,0.2,0,0.2,0v0.4c0,0,0,0.2-0.2,0.2c0,0-0.2,0-0.2-0.2v-0.4H23z M22.8,32.2
      	c-0.2,0.2-0.2,0.2-0.4,0.4c-2.8,0.2-5.2,2.2-6.4,4.8l0,0c-0.2,0-0.2,0-0.4,0.2C16.8,34.6,19.4,32.4,22.8,32.2z M15.2,38.6
      	c0,0.2,0.2,0.2,0.4,0.4c0,0.4-0.2,0.8-0.2,1.4c0,2.4,1,4.4,2.6,5.8l0,0c0,0,0,0.2,0,0.4c-1.8-1.4-3-3.8-3-6.2
      	C15.2,39.8,15.2,39.2,15.2,38.6z M23.6,38.2L23.4,39v-1c0-0.2,0-0.2-0.2-0.2S23,37.8,23,38v0.8L22.8,38l-0.2-0.2l0,0L22.4,38l0,0
      	l0.2,0.8L22.2,38H22c0,0,0,0-0.2,0v0.2c0,0,0,0,0,0.2l0.4,0.8l-0.8-0.6c0,0,0,0-0.2,0H21c0,0,0,0,0,0.2V39l0.6,0.6l-0.8-0.4l0,0
      	c0,0-0.2,0-0.2,0.2l0,0c0,0,0,0.2,0.2,0.2L22,40h-1l0,0l0,0c-0.2,0-0.2,0-0.2,0.2l0,0c0,0.2,0,0.2,0.2,0.2h1l-1,0.2
      	c-0.2,0-0.2,0.2-0.2,0.2l0,0c0,0,0,0.2,0.2,0.2l0,0l1-0.2l-0.8,0.4L21,41.4l0,0l0.2,0.2l0,0l0.8-0.4l-0.6,0.6c0,0,0,0,0,0.2
      	c0,0,0,0,0,0.2c0,0,0,0,0.2,0c0,0,0,0,0.2,0l0.6-0.6L22,42.2l0,0c0,0,0,0.2,0.2,0.2l0,0h0.2l0.4-0.8l-0.2,1l0,0c0,0,0,0.2,0.2,0.2
      	l0,0l0,0c0,0,0.2,0,0.2-0.2l0.2-1l0.2,1c0,0,0,0.2,0.2,0.2l0,0c0.2,0,0.2-0.2,0.2-0.2l0,0l-0.2-1l0.4,0.8l0.2,0.2l0,0l0.2-0.2l0,0
      	L24,41.6l0.6,0.6c0,0,0,0,0.2,0c0,0,0,0,0.2,0c0,0,0,0,0-0.2c0,0,0,0,0-0.2l-0.6-0.6l0.8,0.4c0,0,0,0,0.2,0h0.2c0,0,0,0,0-0.2v-0.2
      	l-0.8-0.4l0.8,0.2l0,0l0,0l0,0l0,0c0,0,0.2,0,0.2-0.2l0,0c0,0,0-0.2-0.2-0.2l-0.8-0.2h1c0.2,0,0.2,0,0.2-0.2l0,0
      	c0-0.2-0.2-0.2-0.2-0.2l0,0h-1l0.8-0.4l0.2-0.2l0,0l-0.2-0.2l0,0l-0.8,0.4l0.8-0.6c0,0,0,0,0-0.2c0,0,0,0,0-0.2c0,0,0,0-0.2,0
      	c0,0,0,0-0.2,0l-0.8,0.6l0.6-0.8c0,0,0,0,0-0.2V38c0,0,0,0-0.2,0h-0.2L24,38.8l0.2-0.8l0,0c0,0,0-0.2-0.2-0.2
      	C24,38,23.8,38,23.6,38.2C23.8,38,23.8,38,23.6,38.2z M21,40.8L21,40.8L21,40.8L21,40.8z M24,42.4L24,42.4L24,42.4L24,42.4z
      	 M25.4,40.2L25.4,40.2L25.4,40.2L25.4,40.2z M24.2,40.4L24.2,40.4L24.2,40.4L24.2,40.4L24.2,40.4z M24.2,40L24.2,40L24.2,40L24.2,40
      	z M24.2,40L24.2,40L24.2,40L24.2,40z M24.2,40.8L24.2,40.8L24.2,40.8L24.2,40.8z M23.6,39.2L23.6,39.2L23.6,39.2L23.6,39.2z
      	 M22.8,39.2L22.8,39.2L22.8,39.2L22.8,39.2z M22.4,39.4L22.4,39.4L22.4,39.4L22.4,39.4z M22.2,40L22.2,40L22.2,40L22.2,40z
      	 M22.2,40.8L22.2,40.8L22.2,40.8L22.2,40.8L22.2,40.8z M22.2,40.8L22.2,40.8L22.2,40.8L22.2,40.8z M22.6,41.2L22.6,41.2L22.6,41.2
      	L22.6,41.2z M22.8,42.6L22.8,42.6L22.8,42.6L22.8,42.6z M23,41.4L23,41.4L23,41.4L23,41.4z M23.2,41.4L23.2,41.4L23.2,41.4
      	L23.2,41.4L23.2,41.4L23.2,41.4L23.2,41.4L23.2,41.4L23.2,41.4z M23.4,41.4L23.4,41.4L23.4,41.4L23.4,41.4z M23.6,41.4L23.6,41.4
      	L23.6,41.4L23.6,41.4z M23.8,41.2L23.8,41.2L23.8,41.2L23.8,41.2z M24,41L24,41L24,41L24,41z M24.2,39.8L24.2,39.8L24.2,39.8
      	L24.2,39.8z M24,39.6L24,39.6L24,39.6L24,39.6z M23.8,39.6L23.8,39.6L23.8,39.6C23.8,39.8,23.8,39.8,23.8,39.6
      	c0.2,0.2,0.2,0.2,0.2,0.2s0,0,0,0.2l0,0c0,0,0,0,0.2,0c0,0,0,0,0,0.2l0,0c0,0,0,0,0,0.2c0,0,0,0-0.2,0.2l0,0c0,0,0,0,0,0.2h-0.2
      	c0,0,0,0,0,0.2l0,0l0,0c0,0,0,0-0.2,0c0,0,0,0,0,0.2l0,0l0,0l0,0c0,0,0,0,0,0.2c0,0,0,0-0.2,0l0,0c0,0,0,0-0.2,0c0,0,0,0-0.2-0.2
      	l0,0l0,0c0,0,0,0-0.2,0V41l0,0l0,0l0,0v-0.2c0,0,0,0-0.2,0l0,0l0,0c0,0,0,0,0-0.2c0,0,0,0-0.2,0c0,0,0,0,0-0.2l0,0
      	c0,0,0-0.2-0.2-0.2c0,0,0.2,0,0.2-0.2l0,0l0,0v-0.2h0.2l0,0l0,0l0,0h0.2c0,0,0,0,0-0.2l0,0l0,0c0,0,0,0,0.2,0c0,0,0,0,0-0.2
      	c0,0,0,0,0.2,0l0,0c0,0,0,0,0.2,0c0,0,0,0,0.2,0h0.2c0,0,0,0.2,0.2,0.2l0,0C23.6,39.4,23.6,39.4,23.8,39.6
      	C23.6,39.4,23.6,39.6,23.8,39.6L23.8,39.6z M56.8,49.2c4.8,0,8.8-4,8.8-8.8s-4-8.8-8.8-8.8s-8.8,4-8.8,8.8S52,49.2,56.8,49.2z
      	 M51,37.8c0.8-2,2.8-3.4,5-3.8v2.2c-1.2,0.2-2.2,1-2.8,2.2L51,37.8z M53.6,39.4L53.6,39.4L53.6,39.4c0,0.2,0,0.2,0,0.2l0,0h-0.4
      	v-0.2H53.6z M53,39.2v0.2l-2.2-0.6v-0.2L53,39.2z M52.8,40v0.2c0,1,0.4,2,1.2,2.8l-1.4,1.8c-1.2-1.2-2.2-2.8-2.2-4.6
      	c0-0.4,0-0.6,0-1L52.8,40z M54.4,43.6c0,0,0.2,0,0.2,0.2l-1.4,1.8c0,0-0.2,0-0.2-0.2L54.4,43.6z M58.8,43.4
      	C58.8,43.2,58.8,43.2,58.8,43.4C58.8,43.2,58.8,43.2,58.8,43.4c0.2-0.2,0.2-0.2,0.2,0v0.2C59,43.4,59,43.4,58.8,43.4L58.8,43.4
      	l-0.2,0.2L58.8,43.4L58.8,43.4z M59.8,45.8c-0.8,0.4-1.8,0.8-3,0.8c-1,0-2-0.2-2.8-0.8l1.4-1.8c0.4,0.2,1,0.2,1.6,0.2s1-0.2,1.6-0.4
      	L59.8,45.8z M54.8,43.4L54.8,43.4c-0.2,0-0.2,0-0.2,0H54.8c-0.2-0.2-0.2-0.2,0-0.2C54.8,43.2,54.8,43.2,54.8,43.4
      	C54.8,43.2,55,43.4,54.8,43.4C55,43.4,54.8,43.4,54.8,43.4z M53.8,46.2c0.8,0.4,2,0.8,3,0.8c1.2,0,2.2-0.2,3-0.8l0.6,0.8
      	c-1,0.6-2.4,1-3.6,1c-1.2,0-2.4-0.4-3.6-0.8L53.8,46.2z M60.4,45.6L59,43.8c0,0,0.2,0,0.2-0.2L60.4,45.6
      	C60.4,45.4,60.4,45.4,60.4,45.6z M59.8,43c0.6-0.8,1-1.6,1-2.8V40l2.2-0.8c0,0.4,0.2,0.6,0.2,1c0,1.8-0.8,3.4-2,4.6L59.8,43z
      	 M60.4,38.4c-0.6-1-1.6-1.8-2.6-2V34c2.2,0.4,4,1.8,4.8,3.6L60.4,38.4z M60.4,39.4h-0.2l0,0H60l0,0v-0.2h0.2
      	C60.4,39.2,60.4,39.4,60.4,39.4z M62.8,38.4v0.2l-2.2,0.8v-0.2L62.8,38.4z M63,38.6v-0.2l0.8-0.2v0.2L63,38.6z M62.8,37.6
      	c-1-2-2.8-3.6-5-3.8v-1l0,0c2.6,0.4,5,2,6,4.4L62.8,37.6z M57.6,37v-0.4c1,0.2,2,1,2.4,2h-0.2c-0.4,0.2-0.6,0.4-0.6,0.8v0.2
      	c0.2,0.4,0.4,0.6,0.8,0.6h0.2h0.2v0.2c0,1-0.4,2-1,2.6l0,0c-0.2-0.2-0.4-0.4-0.6-0.4c-0.2,0-0.4,0-0.4,0.2C58.2,43,58,43.2,58,43.4
      	c0,0.2,0,0.4,0.2,0.4l0,0l0,0c-0.4,0.2-1,0.2-1.4,0.2s-1-0.2-1.4-0.2l0,0c0.2-0.2,0.2-0.4,0.2-0.4c0-0.2-0.2-0.6-0.4-0.6
      	c-0.2-0.2-0.4-0.2-0.4-0.2c-0.2,0-0.6,0.2-0.6,0.4l0,0c-0.6-0.6-1-1.6-1-2.6v-0.2h0.2h0.2c0.4,0,0.6-0.2,0.8-0.6v-0.2
      	c0-0.4-0.2-0.6-0.6-0.8h-0.2c0.6-1,1.4-1.8,2.6-2V37c0,0.4,0.4,0.8,0.8,0.8C57.2,37.8,57.6,37.4,57.6,37z M56,33v1
      	c-2.4,0.2-4.2,1.8-5.2,4L50,37.4C51,35,53.2,33.2,56,33C56,32.8,56,32.8,56,33z M50.6,38.4v0.2l-0.8-0.2v-0.2L50.6,38.4z M50.4,39.4
      	c0,0.4,0,0.6,0,1c0,2,0.8,3.6,2.2,4.8L52,46c-1.6-1.4-2.6-3.4-2.6-5.6c0-0.4,0-0.8,0.2-1.2L50.4,39.4z M52.6,46.8L52.6,46.8
      	C52.4,46.8,52.4,46.8,52.6,46.8c-0.2,0-0.2,0-0.2,0l0,0H52.6C52.4,46.6,52.6,46.6,52.6,46.8L52.6,46.8z M52.4,46.4l0.6-0.8
      	c0,0,0.2,0,0.2,0.2L52.4,46.4C52.6,46.4,52.6,46.4,52.4,46.4z M56.8,48.4c-1.4,0-2.8-0.4-4-1c0.2,0,0.2-0.2,0.2-0.2l0,0
      	c1,0.6,2.4,1,3.6,1c1.4,0,2.6-0.4,3.8-1l0,0c0,0.2,0.2,0.2,0.2,0.2C59.8,48,58.4,48.4,56.8,48.4z M60.4,45.8
      	C60.6,45.6,60.6,45.6,60.4,45.8l0.8,0.6c0,0-0.2,0-0.2,0.2L60.4,45.8z M61.4,46.6C61.4,46.6,61.4,46.8,61.4,46.6
      	C61.2,46.8,61.2,46.6,61.4,46.6L61.4,46.6C61.2,46.6,61.2,46.6,61.4,46.6L61.4,46.6L61.4,46.6L61.4,46.6z M61.2,45.2
      	c1.2-1.2,2-3,2-4.8c0-0.4,0-0.8,0-1l0.8-0.2c0,0.4,0.2,0.8,0.2,1.4c0,2.2-1,4.2-2.4,5.6L61.2,45.2z M64.8,40.4c0,2.4-1,4.6-2.8,6.2
      	c0-0.2,0-0.2-0.2-0.4l0,0c1.6-1.4,2.6-3.4,2.6-5.8c0-0.4,0-1-0.2-1.4c0.2,0,0.2-0.2,0.4-0.4C64.8,39.2,64.8,39.6,64.8,40.4z
      	 M64.4,37.4c-0.2,0-0.2,0-0.4,0c0,0,0,0-0.2,0c-1-2.6-3.4-4.4-6.2-4.6c0-0.2-0.2-0.4-0.4-0.4C60.4,32.4,63.2,34.4,64.4,37.4z
      	 M56.8,33C56.8,32.8,56.8,32.8,56.8,33C56.8,32.8,56.8,32.8,56.8,33c0.2,0,0.2,0,0.2,0l0,0v0.8h-0.2l0,0V33z M56.8,34L56.8,34
      	c0.2,0,0.2,0,0.2,0v2.2h-0.2l0,0V34z M56.8,36.4L56.8,36.4c0.2,0,0.2,0,0.2,0v0.4c0,0,0,0.2-0.2,0.2c0,0-0.2,0-0.2-0.2v-0.4H56.8z
      	 M56.4,32.2c-0.2,0.2-0.4,0.2-0.4,0.4c-2.8,0.2-5.4,2.2-6.4,4.8l0,0c-0.2,0-0.2,0-0.4,0.2C50.4,34.6,53.2,32.4,56.4,32.2z M49,38.6
      	c0,0.2,0.2,0.2,0.4,0.4c0,0.4-0.2,0.8-0.2,1.4c0,2.4,1,4.4,2.6,5.8l0,0c0,0.2-0.2,0.2-0.2,0.4c-1.8-1.4-3-3.8-3-6.2
      	C48.8,39.8,48.8,39.2,49,38.6z M57.4,38.2L57.2,39v-1c0-0.2,0-0.2-0.2-0.2s-0.2,0-0.2,0.2v0.8L56.4,38l-0.2-0.2l0,0L56,38l0,0l0.2,1
      	l-0.4-0.8h-0.2c0,0,0,0-0.2,0v0.2c0,0,0,0,0,0.2l0.4,0.8l-0.6-0.6c0,0,0,0-0.2,0h-0.2c0,0,0,0,0,0.2v0.2l0.6,0.6l-0.8-0.4l0,0
      	c0,0-0.2,0-0.2,0.2l0,0c0,0,0,0.2,0.2,0.2l0.8,0.4h-1l0,0l0,0c0,0-0.2,0-0.2,0.2l0,0c0,0.2,0,0.2,0.2,0.2h1l-1,0.2L54.2,41l0,0
      	c0,0,0,0.2,0.2,0.2l0,0l1-0.2l-0.8,0.4l-0.2,0.2l0,0l0.2,0.2l0,0l0.8-0.4L54.8,42c0,0,0,0,0,0.2v0.2c0,0,0,0,0.2,0c0,0,0,0,0.2,0
      	l0.6-0.6l-0.4,0.8l0,0c0,0,0,0.2,0.2,0.2l0,0c0,0,0.2,0,0.2-0.2l0.4-0.8l-0.2,1l0,0c0,0,0,0.2,0.2,0.2l0,0l0,0c0,0,0.2,0,0.2-0.2
      	l0.2-1l0.2,1c0,0.2,0,0.2,0.2,0.2l0,0c0.2,0,0.2,0,0.2-0.2l0,0l-0.2-1l0.4,0.8l0.2,0.2l0,0l0.2-0.2l0,0l-0.4-0.8l0.6,0.6
      	c0,0,0,0,0.2,0c0,0,0,0,0.2,0c0,0,0,0,0-0.2c0,0,0,0,0-0.2l-0.6-0.6l0.8,0.4l0,0h0.2c0,0,0,0,0-0.2v-0.2L58,40.8l0.8,0.2l0,0l0,0
      	l0,0l0,0c0,0,0.2,0,0.2-0.2l0,0c0,0,0-0.2-0.2-0.2L58,40.4h1c0.2,0,0.2,0,0.2-0.2l0,0C59.2,40,59,40,59,40l0,0l-1,0l0.8-0.4l0.2-0.2
      	l0,0l-0.2-0.2l0,0L58,39.6l0.8-0.6v-0.2c0,0,0,0,0-0.2h-0.2c0,0,0,0-0.2,0l-0.8,0.6l0.6-0.8c0,0,0,0,0-0.2V38c0,0,0,0-0.2,0h-0.2
      	l-0.6,0.8l0.2-0.8l0,0c0,0,0-0.2-0.2-0.2C57.6,38,57.6,38,57.4,38.2C57.4,38,57.4,38,57.4,38.2z M59,40.2L59,40.2L59,40.2L59,40.2z
      	 M58,40.4L58,40.4L58,40.4L58,40.4z M57.8,40.2L57.8,40.2L57.8,40.2L57.8,40.2z M57.8,40L57.8,40L57.8,40L57.8,40L57.8,40L57.8,40z
      	 M57.8,40.8L57.8,40.8L57.8,40.8L57.8,40.8z M57.2,39.2L57.2,39.2L57.2,39.2L57.2,39.2z M56.2,39.4L56.2,39.4L56.2,39.4L56.2,39.4
      	L56.2,39.4z M56,39.4L56,39.4L56,39.4L56,39.4z M55.8,39.8L55.8,39.8L55.8,39.8L55.8,39.8L55.8,39.8z M55.8,40.4L55.8,40.4
      	L55.8,40.4L55.8,40.4z M55.8,40L55.8,40L55.8,40L55.8,40z M55.8,40.8L55.8,40.8L55.8,40.8L55.8,40.8z M55.8,40.8L55.8,40.8
      	L55.8,40.8L55.8,40.8z M56.2,41.2L56.2,41.2L56.2,41.2L56.2,41.2z M56.4,42.6L56.4,42.6L56.4,42.6L56.4,42.6z M56.4,41.4L56.4,41.4
      	L56.4,41.4L56.4,41.4z M57,41.4L57,41.4L57,41.4L57,41.4L57,41.4L57,41.4L57,41.4L57,41.4L57,41.4z M57.4,41.2L57.4,41.2L57.4,41.2
      	L57.4,41.2z M57.6,41L57.6,41L57.6,41L57.6,41z M57.8,41L57.8,41L57.8,41L57.8,41z M57.8,39.8L57.8,39.8L57.8,39.8L57.8,39.8z
      	 M57.6,39.6L57.6,39.6L57.6,39.6L57.6,39.6z M57.4,39.4L57.4,39.4L57.4,39.4L57.4,39.4L57.4,39.4L57.4,39.4L57.4,39.4z M57.4,39.6
      	L57.4,39.6C57.6,39.6,57.4,39.6,57.4,39.6C57.4,39.8,57.4,39.8,57.4,39.6c0.2,0.2,0.2,0.2,0.2,0.2s0,0,0,0.2l0,0c0,0,0,0,0.2,0.2
      	c0,0,0,0,0,0.2l0,0c0,0,0,0,0,0.2c0,0,0,0-0.2,0.2l0,0c0,0,0,0,0,0.2h-0.2c0,0,0,0,0,0.2l0,0l0,0c0,0,0,0-0.2,0c0,0,0,0,0,0.2l0,0
      	l0,0c0,0,0,0,0,0.2c0,0,0,0-0.2,0l0,0c0,0,0,0-0.2,0c0,0,0,0-0.2-0.2l0,0l0,0c0,0,0,0-0.2,0v-0.2l0,0l0,0l0,0c0,0,0,0,0-0.2h-0.2
      	l0,0l0,0l0,0c0,0,0,0-0.2,0c0,0,0,0,0-0.2l0,0c0,0,0-0.2-0.2-0.2c0,0,0.2,0,0.2-0.2l0,0l0,0v-0.2h0.2l0,0l0,0l0,0h0.2c0,0,0,0,0-0.2
      	l0,0l0,0c0,0,0,0,0.2,0c0,0,0,0,0-0.2c0,0,0,0,0.2,0l0,0c0,0,0,0,0.2,0c0,0,0,0,0.2,0h0.2c0,0,0,0,0.2,0.2l0,0
      	C57.2,39.4,57.2,39.4,57.4,39.6C57.2,39.4,57.2,39.6,57.4,39.6L57.4,39.6z M40,36.4c2.8,0,5-2.2,5-5.2c0-2.8-2.2-5.2-5-5.2
      	s-5,2.2-5,5.2C35,34,37.2,36.4,40,36.4z M40,27.6L40,27.6l0.2,1.2c0,0,0,0-0.2,0l0,0V27.6L40,27.6z M40,27.4V27l0,0c0,0,0,0,0.2,0
      	v0.4C40,27.4,40,27.4,40,27.4L40,27.4z M40,29L40,29l0.2,0.2c0,0,0,0-0.2,0h-0.2L40,29L40,29z M40,29.6c0.2,0,0.4-0.2,0.4-0.4V29
      	c0.6,0.2,1.2,0.6,1.4,1.2h-0.2c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0.2s0.2,0.4,0.4,0.4c0,0,0,0,0.2,0l0,0c0,0,0,0,0,0.2
      	c0,0.6-0.2,1.2-0.6,1.4c0-0.2-0.2-0.2-0.4-0.2h-0.2c-0.2,0-0.2,0.2-0.2,0.4v0.2c-0.2,0.2-0.6,0.2-0.8,0.2s-0.6,0-0.8-0.2V33
      	c0-0.2,0-0.2-0.2-0.4h-0.2c-0.2,0-0.2,0-0.4,0.2l0,0l0,0c-0.4-0.4-0.6-1-0.6-1.6c0,0,0,0,0-0.2l0,0c0,0,0,0,0.2,0l0,0
      	c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0-0.2S37.8,30,37.6,30l0,0h-0.2c0.2-0.6,0.8-1,1.4-1.2V29C39.6,29.4,39.8,29.6,40,29.6z M43.6,29.6
      	c-0.6-1.2-1.6-2.2-3-2.2V27c1.6,0.2,2.8,1.2,3.4,2.6H43.6z M44,29.8C44,30,44,30,44,29.8l-0.4,0.4V30L44,29.8z M37.8,30.2l-1.2-0.4
      	c0.4-1.2,1.6-2,2.8-2.2v1.2C38.8,29,38.2,29.4,37.8,30.2z M38,30.6L38,30.6c0.2,0,0.2,0,0.2,0.2l0,0l0,0l0,0L38,30.6
      	C37.8,30.6,38,30.6,38,30.6z M37.8,30.4C37.8,30.6,37.6,30.6,37.8,30.4l-1.2-0.2V30L37.8,30.4z M37.6,31C37.6,31,37.6,31.2,37.6,31
      	c0,0.8,0.2,1.4,0.6,1.8L37.6,34c-0.8-0.6-1.2-1.6-1.2-2.8c0-0.2,0-0.4,0-0.6L37.6,31z M37.8,34.4L37.8,34.4l-0.2,0.4
      	c0,0-0.2,0-0.2-0.2L37.8,34.4z M37.8,34.2l0.8-1c0,0,0.2,0,0.2,0.2L37.8,34.2C38,34.2,38,34.2,37.8,34.2z M41.2,33
      	C41.2,33,41.2,32.8,41.2,33C41.2,32.8,41.2,32.8,41.2,33C41.2,32.8,41.2,32.8,41.2,33L41.2,33L41.2,33L41.2,33L41.2,33L41.2,33z
      	 M41.8,34.4c-0.6,0.2-1,0.4-1.8,0.4c-0.6,0-1.2-0.2-1.6-0.4l0.8-1c0.2,0.2,0.6,0.2,1,0.2s0.6,0,1-0.2L41.8,34.4z M38.8,33L38.8,33
      	L38.8,33L38.8,33l-0.2-0.2L38.8,33C38.8,32.8,38.8,32.8,38.8,33C38.8,32.8,38.8,32.8,38.8,33C39,33,39,33,38.8,33
      	C39,33,39,33,38.8,33z M38.2,34.6c0.6,0.2,1.2,0.4,1.8,0.4s1.2-0.2,1.8-0.4L42,35c-0.6,0.4-1.4,0.6-2.2,0.6s-1.4-0.2-2-0.6
      	L38.2,34.6z M42,34.2l-0.8-1c0,0,0.2,0,0.2-0.2L42,34.2C42.2,34.2,42,34.2,42,34.2z M42.2,34.2l0.2,0.4c0,0,0,0-0.2,0.2V34.2
      	C42.2,34.4,42.2,34.2,42.2,34.2z M42.4,33.8l-0.8-1c0.4-0.4,0.6-1,0.6-1.6V31l1.2-0.4c0,0.2,0,0.4,0,0.6
      	C43.6,32.2,43.2,33.2,42.4,33.8z M42.2,30.4l1.2-0.4v0.2L42.2,30.4C42.2,30.6,42.2,30.6,42.2,30.4z M43.4,29.8l-1.2,0.4
      	c-0.4-0.6-1-1.2-1.6-1.2v-1.2C41.8,27.8,42.8,28.6,43.4,29.8z M42.2,30.8L42.2,30.8C42,30.8,42,30.8,42.2,30.8c-0.2,0-0.2,0-0.2,0
      	l0,0C41.8,30.6,41.8,30.6,42.2,30.8L42.2,30.8C42,30.6,42,30.6,42.2,30.8z M39.6,27.4c-1.4,0.2-2.6,1-3.2,2.4L36,29.6
      	c0.6-1.4,2-2.4,3.6-2.6V27.4z M36.4,30C36.4,30.2,36.4,30.2,36.4,30H36v-0.2L36.4,30z M36.2,30.6c0,0.2,0,0.4,0,0.6
      	c0,1.2,0.6,2.2,1.4,2.8l-0.2,0.4c-1-0.8-1.6-2-1.6-3.2c0-0.2,0-0.6,0-0.8L36.2,30.6z M37.4,35L37.4,35L37.4,35
      	C37.4,34.8,37.4,34.8,37.4,35C37.4,34.8,37.4,35,37.4,35C37.6,35,37.4,35,37.4,35z M40,36c-0.8,0-1.6-0.2-2.4-0.6c0,0,0.2,0,0.2-0.2
      	l0,0c0.6,0.4,1.4,0.6,2.2,0.6s1.6-0.2,2.2-0.6l0,0l0.2,0.2C41.8,35.6,40.8,36,40,36z M42.6,35L42.6,35L42.6,35
      	C42.6,34.8,42.6,34.8,42.6,35C42.6,34.8,42.8,34.8,42.6,35C42.8,34.8,42.6,35,42.6,35z M42.6,34c0.8-0.8,1.2-1.8,1.2-2.8
      	c0-0.2,0-0.4,0-0.6l0.4-0.2c0,0.2,0,0.6,0,0.8c0,1.2-0.6,2.4-1.4,3.2L42.6,34z M44.8,31.2c0,1.4-0.6,2.8-1.6,3.6v-0.2l0,0
      	C44.2,33.8,44.8,32.6,44.8,31.2c0-0.4,0-0.6,0-1L45,30C44.6,30.4,44.8,30.8,44.8,31.2z M44.4,29.6h-0.2l0,0c-0.6-1.6-2-2.6-3.8-2.8
      	c0-0.2-0.2-0.2-0.4-0.2C42,26.4,43.8,27.8,44.4,29.6z M40,26.4L40,26.4c-0.2,0-0.4,0.2-0.4,0.2c-1.8,0.2-3.2,1.2-3.8,2.8l0,0h-0.2
      	C36.2,27.8,38,26.4,40,26.4z M35.4,30c0,0.2,0,0.2,0.2,0.2c0,0.2,0,0.6,0,0.8c0,1.4,0.6,2.6,1.6,3.4v0.2c-1-0.8-1.8-2.2-1.8-3.6
      	C35.2,30.8,35.4,30.4,35.4,30z M40.2,30.6L40.2,30.6L40.2,30.6l0.2-0.8l0,0l0,0l0,0l0,0L40.2,30.6L40.2,30.6L40.2,30.6L40.2,30.6z
      	 M40.4,30L40.4,30L40.4,30L40.4,30L40.4,30l-0.2,0.6l0,0l0,0l0,0l0,0L40.4,30z M40.2,30.6C40.2,30.8,40.2,30.8,40.2,30.6
      	C40.4,30.8,40.4,30.8,40.2,30.6C40.4,30.8,40.4,30.8,40.2,30.6L40.2,30.6L40.2,30.6l0.6-0.4l0,0l0,0l0,0l0,0L40.2,30.6L40.2,30.6z
      	 M40.8,30.2L40.8,30.2L40.8,30.2L40.8,30.2L40.8,30.2l-0.4,0.6l0,0l0,0l0,0l0,0L40.8,30.2z M40.4,30.8L40.4,30.8
      	C40.4,30.8,40.4,31,40.4,30.8C40.6,31,40.6,30.8,40.4,30.8L40.4,30.8L40.4,30.8l0.6-0.4l0,0l0,0l0,0l0,0L40.4,30.8L40.4,30.8z
      	 M40.4,30.8l0.6-0.4l0,0l0,0l0,0l0,0L40.4,30.8L40.4,30.8L40.4,30.8L40.4,30.8L40.4,30.8z M40.6,31L40.6,31L40.6,31L40.6,31L40.6,31
      	L40.6,31l0.8-0.2l0,0l0,0l0,0l0,0L40.6,31L40.6,31z M40.6,31l0.6-0.2l0,0l0,0l0,0l0,0L40.6,31L40.6,31L40.6,31L40.6,31L40.6,31
      	L40.6,31z M41.2,31h-0.6l0,0l0,0l0,0l0,0H41.2C41.4,31.2,41.4,31.2,41.2,31C41.4,31,41.4,31,41.2,31L41.2,31z M41.4,31.2h-0.6l0,0
      	l0,0l0,0l0,0l0,0l0,0l0,0H41.4L41.4,31.2L41.4,31.2L41.4,31.2z M40.6,31.2L40.6,31.2C40.6,31.2,40.6,31.4,40.6,31.2
      	C40.6,31.4,40.6,31.4,40.6,31.2C40.6,31.4,40.6,31.4,40.6,31.2L40.6,31.2L40.6,31.2l0.6,0.4l0,0l0,0l0,0l0,0L40.6,31.2z M41.2,31.6
      	L41.2,31.6L41.2,31.6l-0.6-0.2l0,0l0,0l0,0l0,0L41.2,31.6L41.2,31.6L41.2,31.6z M40.6,31.4L40.6,31.4C40.6,31.4,40.4,31.4,40.6,31.4
      	C40.4,31.6,40.4,31.6,40.6,31.4C40.4,31.6,40.4,31.6,40.6,31.4L40.6,31.4L40.6,31.4L41,32l0,0l0,0l0,0l0,0l0,0L40.6,31.4z
      	 M41.2,31.8L41.2,31.8L41.2,31.8l-0.6-0.4l0,0l0,0l0,0l0,0L41.2,31.8L41.2,31.8L41.2,31.8z M40.4,31.6L40.4,31.6L40.4,31.6
      	L40.4,31.6C40.4,31.8,40.4,31.8,40.4,31.6L40.4,31.6L40.4,31.6l0.4,0.6l0,0l0,0l0,0l0,0L40.4,31.6z M40.8,32.2L40.8,32.2L40.8,32.2
      	l-0.4-0.4l0,0l0,0l0,0l0,0L40.8,32.2L40.8,32.2L40.8,32.2z M40.4,31.8C40.4,31.8,40.2,31.6,40.4,31.8C40.2,31.6,40.2,31.8,40.4,31.8
      	C40.2,31.8,40.2,31.8,40.4,31.8C40.2,31.8,40.2,31.8,40.4,31.8l0.2,0.6l0,0l0,0l0,0l0,0L40.4,31.8z M40.6,32.4L40.6,32.4L40.6,32.4
      	l-0.4-0.6l0,0l0,0l0,0l0,0l0,0l0,0L40.6,32.4L40.6,32.4L40.6,32.4z M40.2,31.8L40.2,31.8L40.2,31.8C40,31.8,40,31.8,40.2,31.8
      	L40.2,31.8L40.2,31.8v0.6l0,0l0,0l0,0l0,0V31.8z M40.2,32.6L40.2,32.6L40.2,32.6L40,31.8l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0L40.2,32.6
      	L40.2,32.6z M39.8,31.8L39.8,31.8l-0.2,0.8l0,0l0,0l0,0l0,0l0,0L39.8,31.8L39.8,31.8C40,31.8,40,31.8,39.8,31.8L39.8,31.8z
      	 M39.8,31.8l-0.2,0.6l0,0l0,0l0,0l0,0L39.8,31.8L39.8,31.8L39.8,31.8L39.8,31.8L39.8,31.8L39.8,31.8z M39.8,31.8
      	C39.8,31.8,39.8,31.6,39.8,31.8C39.8,31.6,39.6,31.6,39.8,31.8C39.6,31.6,39.6,31.6,39.8,31.8L39.8,31.8L39.8,31.8l-0.4,0.6l0,0l0,0
      	l0,0l0,0L39.8,31.8L39.8,31.8z M39.8,31.8l-0.2,0.6l0,0l0,0l0,0l0,0L39.8,31.8L39.8,31.8C39.6,31.8,39.6,31.8,39.8,31.8L39.8,31.8
      	C39.6,31.8,39.8,31.8,39.8,31.8L39.8,31.8z M39.6,31.6L39.6,31.6L39.6,31.6C39.6,31.6,39.4,31.6,39.6,31.6L39,32l0,0l0,0l0,0l0,0
      	L39.6,31.6L39.6,31.6z M39.6,31.6L39.2,32l0,0l0,0l0,0l0,0L39.6,31.6L39.6,31.6L39.6,31.6L39.6,31.6L39.6,31.6L39.6,31.6L39.6,31.6z
      	 M39.6,31.4L39.6,31.4C39.4,31.4,39.4,31.4,39.6,31.4C39.4,31.4,39.4,31.4,39.6,31.4L39.6,31.4L39.6,31.4l-0.8,0.2l0,0l0,0l0,0l0,0
      	L39.6,31.4C39.4,31.6,39.6,31.6,39.6,31.4z M39.4,31.6L38.8,32l0,0l0,0l0,0l0,0l0,0L39.4,31.6L39.4,31.6L39.4,31.6
      	C39.4,31.4,39.4,31.4,39.4,31.6L39.4,31.6C39.4,31.4,39.4,31.4,39.4,31.6z M39.4,31.4L39.4,31.4C39.4,31.2,39.4,31.2,39.4,31.4
      	L39.4,31.4h-0.8l0,0l0,0l0,0l0,0H39.4L39.4,31.4z M38.8,31.4C38.8,31.4,38.6,31.4,38.8,31.4C38.6,31.4,38.6,31.4,38.8,31.4l0.6-0.2
      	l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0L38.8,31.4z M39.4,31.2L39.4,31.2L39.4,31.2L39.4,31.2L39.4,31.2C39.4,31,39.4,31,39.4,31.2
      	L39.4,31.2L38.8,31l0,0l0,0l0,0l0,0l0,0L39.4,31.2z M38.6,31L38.6,31L38.6,31L38.6,31h0.6l0,0l0,0l0,0l0,0l0,0l0,0H38.6L38.6,31
      	L38.6,31z M39.4,31L39.4,31L39.4,31C39.6,31,39.6,31,39.4,31C39.6,30.8,39.4,30.8,39.4,31L39.4,31L39.4,31l-0.6-0.4l0,0l0,0l0,0l0,0
      	L39.4,31z M38.8,30.6L38.8,30.6l0.6,0.2l0,0l0,0l0,0l0,0L38.8,30.6L38.8,30.6L38.8,30.6L38.8,30.6z M39.4,30.8L39.4,30.8
      	C39.6,30.8,39.6,30.8,39.4,30.8C39.6,30.8,39.6,30.8,39.4,30.8C39.6,30.8,39.6,30.8,39.4,30.8L39.4,30.8L39,30.2l0,0l0,0l0,0l0,0
      	L39.4,30.8z M39,30.4L39,30.4L39,30.4l0.6,0.4l0,0l0,0l0,0l0,0l0,0l0,0L39,30.4L39,30.4L39,30.4z M39.6,30.6
      	C39.6,30.8,39.6,30.8,39.6,30.6C39.6,30.8,39.8,30.8,39.6,30.6C39.8,30.6,39.8,30.6,39.6,30.6C39.8,30.6,39.8,30.6,39.6,30.6
      	L39.6,30.6L39.6,30.6L39.4,30l0,0l0,0l0,0l0,0L39.6,30.6z M39.4,30L39.4,30L39.4,30l0.4,0.6l0,0l0,0l0,0l0,0L39.4,30L39.4,30
      	L39.4,30z M39.6,29.8L39.6,29.8L39.6,29.8l0.2,0.8l0,0l0,0l0,0l0,0l0,0V30l0,0l0,0v0.6L39.6,29.8C39.8,29.8,39.8,29.8,39.6,29.8
      	L39.6,29.8z M40,29.8L40,29.8L40,29.8v0.6l0,0l0,0V29.8z M40,30.6L40,30.6L40,30.6L40,30.6z M39.8,30.6L39.8,30.6L39.8,30.6
      	L39.8,30.6L39.8,30.6L39.6,30l0,0l0,0l0,0l0,0L39.8,30.6z M36.2,50.4c0-0.2-0.2-0.2-0.4-0.2s-0.4,0-0.4,0.2
      	c-0.6,0.2-1.2,0.8-1.2,1.6s0.6,1.4,1.2,1.6c0,0.2,0.2,0.2,0.4,0.2s0.4,0,0.4-0.2c0.6-0.2,1.2-0.8,1.2-1.6S36.8,50.6,36.2,50.4z
      	 M35.2,53.2c-0.6-0.2-0.8-0.6-0.8-1.2c0-0.6,0.4-1,0.8-1.2l0,0V53.2L35.2,53.2z M36,53.2C36,53.2,36,53.4,36,53.2
      	c-0.2,0-0.2,0.2-0.2,0.2h-0.2l0,0v-2.6l0,0h0.2H36l0,0V53.2z M36.2,53.2L36.2,53.2v-2.6l0,0c0.6,0.2,0.8,0.6,0.8,1.2
      	C37.2,52.6,36.8,53,36.2,53.2z M40.4,50.4c0-0.2-0.2-0.2-0.4-0.2s-0.4,0-0.4,0.2c-0.6,0.2-1.2,0.8-1.2,1.6s0.6,1.4,1.2,1.6
      	c0,0.2,0.2,0.2,0.4,0.2s0.4,0,0.4-0.2c0.6-0.2,1.2-0.8,1.2-1.6C41.6,51.2,41,50.6,40.4,50.4z M39.6,53.2C39,53,38.8,52.6,38.8,52
      	c0-0.6,0.4-1,0.8-1.2l0,0V53.2C39.4,53.2,39.6,53.2,39.6,53.2z M40.2,53.2C40.2,53.2,40.2,53.4,40.2,53.2c-0.2,0-0.2,0.2-0.2,0.2
      	h-0.2l0,0v-2.6l0,0H40h0.2l0,0V53.2z M40.4,53.2L40.4,53.2v-2.6l0,0c0.6,0.2,0.8,0.6,0.8,1.2C41.4,52.6,41,53,40.4,53.2z M44.6,50.4
      	c0-0.2-0.2-0.2-0.4-0.2s-0.4,0-0.4,0.2c-0.6,0.2-1.2,0.8-1.2,1.6s0.6,1.4,1.2,1.6c0,0.2,0.2,0.2,0.4,0.2s0.4,0,0.4-0.2
      	c0.6-0.2,1.2-0.8,1.2-1.6S45.2,50.6,44.6,50.4z M43.8,53.2C43.2,53,43,52.6,43,52s0.4-1,0.8-1.2l0,0V53.2L43.8,53.2z M44.6,53.2
      	C44.6,53.2,44.4,53.4,44.6,53.2c-0.2,0-0.2,0.2-0.2,0.2h-0.2l0,0v-2.6l0,0h0.2h0.2l0,0V53.2z M44.8,53.2L44.8,53.2v-2.6l0,0
      	c0.6,0.2,0.8,0.6,0.8,1.2C45.6,52.6,45.2,53,44.8,53.2z M11,52.2h22.2V52H11V52.2z M46.8,52.2H69V52H46.8V52.2z"/>
    </svg>
  );
};

export default CookTop;
