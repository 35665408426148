import React from 'react';

const Freezer = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="9.6 20.4 60.8 39.4" {...props}>
      <path fill={fill} d="M70.2,20.4H70H10H9.8l-0.2,0.2v38.8c0,0,0,0.2,0.2,0.2l0.2,0.2h60c0,0,0.2,0,0.2-0.2l0.2-0.2V20.6
      	C70.4,20.4,70.4,20.4,70.2,20.4z M51.6,21h18v3.6h-18V21z M28.6,21h22.6v3.6H28.6V21z M10.2,21h18v3.6h-18
      	C10.2,24.6,10.2,21,10.2,21z M10.2,25.2h59.4V26H44l0,0h-8l0,0H10.2V25.2z M43.8,28h-7.6v-1.8h7.6V28z M10.2,59V26.2h25.6v2
      	c0,0,0,0,0,0.2c0,0,0,0,0.2,0h8c0,0,0,0,0.2,0c0,0,0,0,0-0.2v-2h25.6V59H10.2z M67.1,54.1h-5.9v2.7h5.9V54.1z M61.8,56.2v-1.8h4.8
      	v1.8H61.8z"/>
    </svg>
  );
};

export default Freezer;
