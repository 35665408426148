import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import * as authActions from "../../redux/modules/auth";
import {
  createValidator,
  maxLength,
  minLength,
  match,
  required,
} from "../../utils/validation";
import Control from "../Control/Control";

const changePassValidation = createValidator({
  password: [required, minLength(4), maxLength(40)],
  confirm: [required, match("password")],
});

function ChangePasswordForm({ changing, changePassword, submitBtnRef }) {
  return (
    <Form
      initialValues={{ password: "", confirm: "" }}
      onSubmit={(values) => changePassword(values.password)}
      validate={changePassValidation}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {changing && (
            <div className={"st-overlay is-overlay"}>
              <span className={"st-loader loader"}></span>
            </div>
          )}

          <div className="field">
            <label className="label is-hidden">Password</label>
            <Field name="password" type="password">
              {(field) => <Control field={field} placeholder="Password" />}
            </Field>
          </div>

          <div className="field">
            <label className="label is-hidden">Confirm</label>
            <Field name="confirm" type="password">
              {(field) => <Control field={field} placeholder="Confirm" />}
            </Field>
          </div>

          <p className="control is-hidden">
            <button ref={submitBtnRef} type="submit">
              Submit
            </button>
          </p>
        </form>
      )}
    </Form>
  );
}

export default connect(
  (state) => ({
    changing: state.auth.changing,
  }),
  { ...authActions }
)(ChangePasswordForm);
