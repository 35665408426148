// require('babel-polyfill');

const config = {
  isProduction: process.env.NODE_ENV !== "development",

  google: {
    apiKey: "AIzaSyDQ2harjbOqwlRZ9RMlQq2-ELrMqefOrWU",
    trackingId: "UA-91772401-2",
  },

  stripe: {
    publicKey:
      process.env.NODE_ENV === "production"
        ? "pk_live_ySSQxfhF7FEXdxHKJ2MdWaqU"
        : "pk_test_s8AjsYsMvpfQvvIDK1SCz9si",
  },

  requestStatuses: {
    PENDING: 0,
    ACCEPTED: 1,
    DECLINED: 2,
    EXPIRED: 3,
    COMPLETED: 4,
    NO_REPAIR: 5,
    RETAILER_ACCEPTED: 6,
    RETAILER_DECLINED: 7,
  },

  requestStatusData: {
    0: {
      text: "Pending Request",
      status: "Pending",
      className: "is-warning",
      icon: "fa-wrench",
    },
    1: {
      text: "Request Accepted",
      status: "Accepted",
      className: "is-success",
      icon: "fa-warning",
    },
    2: {
      text: "Request Declined",
      status: "Declined",
      className: "is-danger",
      icon: "fa-warning",
    },
    3: {
      text: "Pending Request",
      status: "Pending",
      className: "is-warning",
      icon: "fa-wrench",
    },
    4: {
      text: "Completed",
      status: "Completed",
      className: "is-success",
      icon: "fa-warning",
    },
    5: {
      text: "Unable to Repair",
      status: "Not Repaired",
      className: "is-danger",
      icon: "fa-warning",
    },
    6: {
      text: "Retailer Accepted",
      status: "Retailer Accepted",
      className: "is-danger",
      icon: "fa-tag",
    },
    7: {
      text: "Retailer Declined",
      status: "Retailer Declined",
      className: "is-danger",
      icon: "fa-warning",
    },
  },

  warrantyLength: 1 * 365, // year(s) in days
};

export default config;
