import findIndex from "lodash/findIndex"; // eslint-disable-line
import { LOGOUT_SUCCESS, STARTUP, STARTUP_SUCCESS, STARTUP_FAIL } from "./auth";

const LOAD = "service-tapp/appliances/LOAD";
const LOAD_SUCCESS = "service-tapp/appliances/LOAD_SUCCESS";
const LOAD_FAIL = "service-tapp/appliances/LOAD_FAIL";
const ADD = "service-tapp/appliances/ADD";
const CHANGE_FIRST_NAME = "service-tapp/appliances/CHANGE_FIRST_NAME";
const CHANGE_LAST_NAME = "service-tapp/appliances/CHANGE_LAST_NAME";
const CHANGE_PHONE = "service-tapp/appliances/CHANGE_PHONE";
const CHANGE_SYMPTOM = "service-tapp/appliances/CHANGE_SYMPTOM";
const CHANGE_TAB = "service-tapp/appliances/CHANGE_TAB";
const CLEAR = "service-tapp/appliances/CLEAR";
const CLEAR_SAVE_ERROR = "service-tapp/appliances/CLEAR_SAVE_ERROR";
const EDIT_OTHER_SYMPTOM = "service-tapp/appliances/EDIT_OTHER_SYMPTOM";
const EDIT_START = "service-tapp/appliances/EDIT_START";
const EDIT_STOP = "service-tapp/appliances/EDIT_STOP";
const EDIT_UPDATE = "service-tapp/appliances/EDIT_UPDATE";
const FIRST = "service-tapp/appliances/FIRST";
const FILTER_ADD = "service-tapp/appliances/FILTER_ADD";
const FILTER_REMOVE = "service-tapp/appliances/FILTER_REMOVE";
const FILTER_REMOVE_ALL = "service-tapp/appliances/FILTER_REMOVE_ALL";
const INIT = "service-tapp/appliances/INIT";
const INSERT = "service-tapp/appliances/INSERT";
const INSERT_SUCCESS = "service-tapp/appliances/INSERT_SUCCESS";
const INSERT_FAIL = "service-tapp/appliances/INSERT_FAIL";
const LOOKUP = "service-tapp/appliances/LOOKUP";
const LOOKUP_SUCCESS = "service-tapp/appliances/LOOKUP_SUCCESS";
const LOOKUP_FAIL = "service-tapp/appliances/LOOKUP_FAIL";
const LOOKUP_RESET = "service-tapp/appliances/LOOKUP_RESET";
const REMOVE = "service-tapp/appliances/REMOVE";
const REMOVE_SUCCESS = "service-tapp/appliances/REMOVE_SUCCESS";
const REMOVE_FAIL = "service-tapp/appliances/REMOVE_FAIL";
const SAVE = "service-tapp/appliances/SAVE";
const SAVE_SUCCESS = "service-tapp/appliances/SAVE_SUCCESS";
const SAVE_FAIL = "service-tapp/appliances/SAVE_FAIL";
const SERVICE_START = "service-tapp/appliances/SERVICE_START";
const SERVICE_STOP = "service-tapp/appliances/SERVICE_STOP";
const SUBMIT_SERVICE = "service-tapp/appliances/SUBMIT_SERVICE";
const SUBMIT_SERVICE_SUCCESS = "service-tapp/appliances/SUBMIT_SERVICE_SUCCESS";
const SUBMIT_SERVICE_FAIL = "service-tapp/appliances/SUBMIT_SERVICE_FAIL";
const UPDATE_SERVICE = "service-tapp/appliances/UPDATE_SERVICE";

const initialState = {
  data: [],
  dirty: {},
  editing: null,
  filters: {
    brands: [],
    locations: [],
    types: [],
  },
  first: false,
  firstName: "",
  lastName: "",
  loaded: false,
  looking: false,
  removing: {},
  saveError: {},
  saving: {},
  selectedTab: "model",
  servicing: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
    case INIT:
      return initialState;

    case STARTUP:
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.appliances,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };

    case STARTUP_FAIL:
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case ADD:
      const addData = [...state.data, action.appliance];
      return {
        ...state,
        data: addData,
        dirty: {
          ...state.dirty,
          [action.appliance._id]: true,
        },
      };
    case CHANGE_FIRST_NAME:
      return {
        ...state,
        firstName: action.first,
      };
    case CHANGE_LAST_NAME:
      return {
        ...state,
        lastName: action.last,
      };
    case CHANGE_PHONE:
      return {
        ...state,
        phoneNumber: action.number,
      };
    case CHANGE_SYMPTOM:
      return {
        ...state,
        selectedSymptom: action.symptomName,
      };
    case CHANGE_TAB:
      return {
        ...state,
        selectedTab: action.tabName,
      };
    case CLEAR:
      return {
        ...state,
        // loading: false,
        loaded: false,
        data: null,
        // error: null
      };
    case CLEAR_SAVE_ERROR:
      return {
        ...state,
        saveError: {
          ...state.saveError,
          [action.id]: null,
        },
      };
    case EDIT_OTHER_SYMPTOM:
      return {
        ...state,
        otherSymptom: action.other,
      };
    case EDIT_START:
      return {
        ...state,
        selectedTab: action.tab || "type",
        editing: action.id,
      };
    case EDIT_STOP:
      const editData = [...state.data];
      const editIndex = findIndex(
        editData,
        (item) => item._id === state.editing
      );
      if (state.dirty[state.editing]) {
        editData.splice(editIndex, 1);
      }

      return {
        ...state,
        data: editData,
        editing: null,
      };
    case EDIT_UPDATE:
      const updateData = [...state.data];
      const updateIndex = findIndex(
        updateData,
        (item) => item._id === state.editing
      );
      updateData[updateIndex] = {
        ...updateData[updateIndex],
        ...action.updates,
      };

      return {
        ...state,
        data: updateData,
      };
    case FILTER_ADD:
      const newFilterData = [...state.filters[action.filterType]];
      if (findIndex(newFilterData, (item) => item._id === action.id) === -1) {
        newFilterData.push(action.data);
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterType]: newFilterData,
        },
      };
    case FILTER_REMOVE:
      let removeData = [];

      if (action.id) {
        removeData = [...state.filters[action.filterType]];
        const removeIndex = findIndex(
          removeData,
          (item) => item._id === action.id
        );
        removeData.splice(removeIndex, 1);
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterType]: removeData,
        },
      };
    case FILTER_REMOVE_ALL:
      return {
        ...state,
        filters: initialState.filters,
      };
    case FIRST:
      const first = typeof action.bool === "undefined" ? true : action.bool;

      return {
        ...state,
        first,
      };
    case INSERT:
      return state; // 'saving' flag handled by redux-form
    case INSERT_SUCCESS:
      const insertData = [...state.data];
      const insertIndex = findIndex(
        insertData,
        (item) => item._id === action.result._id
      );
      insertData[insertIndex] = action.result;
      return {
        ...state,
        data: insertData,
        editing: null,
        saveError: {
          ...state.saveError,
          [action.result._id]: null,
        },
      };
    case INSERT_FAIL:
      if (typeof action.error !== "string") {
        return state;
      }
      return {
        ...state,
        saveError: {
          ...state.saveError,
          [action.id]: action.error,
        },
      };
    case LOOKUP:
      return {
        ...state,
        looking: true,
        lookupFound: undefined,
      };
    case LOOKUP_SUCCESS:
      const lookupData = [...state.data];
      const lookupIndex = findIndex(
        lookupData,
        (item) => item._id === state.editing
      );
      lookupData[lookupIndex] = {
        ...lookupData[lookupIndex],
        brand: action.result.brand,
        type: action.result.applianceType,
      };

      return {
        ...state,
        looking: false,
        lookupFound: true,
        data: lookupData,
        error: null,
      };
    case LOOKUP_FAIL:
      return {
        ...state,
        looking: false,
        lookupFound: false,
        // data: null,
        error: action.error,
      };
    case LOOKUP_RESET:
      return {
        ...state,
        lookupFound: undefined,
      };
    case REMOVE:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: true,
        },
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        data: action.result,
        error: null,
      };
    case REMOVE_FAIL:
      return {
        ...state,
        removing: {
          ...state.removing,
          [action.id]: false,
        },
        data: null,
        error: action.error,
      };
    case SAVE:
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.id]: true,
        },
        dirty: {
          ...state.dirty,
          [action.id]: false,
        },
      };
    case SAVE_SUCCESS:
      const saveData = [...state.data];
      const saveIndex = findIndex(
        saveData,
        (item) => item._id === action.result._id
      );
      saveData[saveIndex] = action.result;
      return {
        ...state,
        data: saveData,
        saving: {
          ...state.saving,
          [action.id]: false,
        },
        saveError: {
          ...state.saveError,
          [action.result._id]: null,
        },
      };
    case SAVE_FAIL:
      if (typeof action.error !== "string") {
        return state;
      }
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.id]: false,
        },
        saveError: {
          ...state.saveError,
          [action.id]: action.error,
        },
      };
    case SERVICE_START:
      return {
        ...state,
        selectedTab: action.tab || "symptoms",
        servicing: action.id,
      };
    case SERVICE_STOP:
      return {
        ...state,
        servicing: null,
      };
    case SUBMIT_SERVICE:
      return {
        ...state,
        submittingService: action.id,
      };
    case SUBMIT_SERVICE_SUCCESS:
      const srData = [...state.data];
      const aId = action.result.appliance._id || action.result.appliance;
      const sIdx = findIndex(srData, (item) => item._id === aId);

      const rIdx = findIndex(
        srData[sIdx].serviceRequests,
        (item) => item._id === action.result._id
      );
      if (rIdx === -1) {
        srData[sIdx] = {
          ...srData[sIdx],
          serviceRequests: [...srData[sIdx].serviceRequests, action.result],
        };
      }

      return {
        ...state,
        data: srData,
        submittingService: null,
        error: null,
      };
    case SUBMIT_SERVICE_FAIL:
      return {
        ...state,
        submittingService: null,
        error: action.error,
      };
    case UPDATE_SERVICE:
      const usData = [...state.data];
      const aIdx = findIndex(
        usData,
        (item) => item._id === action.request.appliance._id
      );
      if (aIdx > -1) {
        const usrIdx = findIndex(
          usData[aIdx].serviceRequests,
          (reqItem) => reqItem._id === action.request._id
        );
        if (usrIdx > -1) {
          usData[aIdx] = {
            ...usData[aIdx],
            serviceRequests: [
              ...usData[aIdx].serviceRequests.splice(0, usrIdx),
              action.request,
              ...usData[aIdx].serviceRequests.splice(1),
            ],
          };
        }
      }

      return {
        ...state,
        data: usData,
      };
    default:
      return state;
  }
}

export function init() {
  return { type: INIT };
}

export function isLoaded(globalState) {
  return globalState.appliances && globalState.appliances.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("/appliance/load"),
  };
}

export function add(appliance) {
  return { type: ADD, appliance };
}

export function changeFirstName(first) {
  return { type: CHANGE_FIRST_NAME, first };
}

export function changeLastName(last) {
  return { type: CHANGE_LAST_NAME, last };
}

export function changePhone(number) {
  return { type: CHANGE_PHONE, number };
}

export function changeSymptom(symptomName) {
  return { type: CHANGE_SYMPTOM, symptomName };
}

export function changeTab(tabName) {
  return { type: CHANGE_TAB, tabName };
}

export function clear() {
  return { type: CLEAR };
}

export function clearSaveError(id) {
  return { type: CLEAR_SAVE_ERROR, id };
}

export function editOtherSymptom(other) {
  return { type: EDIT_OTHER_SYMPTOM, other };
}

export function editStart(id, tab) {
  return { type: EDIT_START, id, tab };
}

export function editStop() {
  return { type: EDIT_STOP };
}

export function editUpdate(updates) {
  return { type: EDIT_UPDATE, updates };
}

export function addFilter(filterType, data) {
  return { type: FILTER_ADD, filterType, data };
}

export function removeFilter(filterType, id) {
  return { type: FILTER_REMOVE, filterType, id };
}

export function removeAllFilters() {
  return { type: FILTER_REMOVE_ALL };
}

export function firstAppliance(bool) {
  return { type: FIRST, bool };
}

export function insert(appliance) {
  return {
    types: [INSERT, INSERT_SUCCESS, INSERT_FAIL],
    id: appliance._id,
    promise: (client) =>
      client.post("/appliance/insert", {
        data: appliance,
      }),
  };
}

export function lookup(modelNumber) {
  return {
    types: [LOOKUP, LOOKUP_SUCCESS, LOOKUP_FAIL],
    promise: (client) =>
      client.post("/modelNumber/lookup", {
        data: { modelNumber },
      }),
  };
}

export function lookupReset() {
  return { type: LOOKUP_RESET };
}

export function removeAppliance(id) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    id,
    promise: (client) =>
      client.post("/appliance/remove", {
        data: { id },
      }),
  };
}

export function save(appliance) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    id: appliance._id,
    promise: (client) =>
      client.post("/appliance/update", {
        data: appliance,
      }),
  };
}

export function serviceStart(id, tab) {
  return { type: SERVICE_START, id, tab };
}

export function serviceStop() {
  return { type: SERVICE_STOP };
}

export function submitService(data) {
  return {
    types: [SUBMIT_SERVICE, SUBMIT_SERVICE_SUCCESS, SUBMIT_SERVICE_FAIL],
    promise: (client) =>
      client.post("/serviceRequest/add", {
        data,
      }),
    id: data.applianceId,
  };
}

export function updateService(request) {
  return { type: UPDATE_SERVICE, request };
}
