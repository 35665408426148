import { without } from 'lodash';

const ACCEPT = 'service-tapp/serviceRequest/ACCEPT';
const ACCEPT_SUCCESS = 'service-tapp/serviceRequest/ACCEPT_SUCCESS';
const ACCEPT_FAIL = 'service-tapp/serviceRequest/ACCEPT_FAIL';
const CHANGE_TAB = 'service-tapp/serviceRequest/CHANGE_TAB';
const CLEAR_ERROR = 'service-tapp/serviceRequest/CLEAR_ERROR';
const COMPLETE = 'service-tapp/serviceRequest/COMPLETE';
const COMPLETE_SUCCESS = 'service-tapp/serviceRequest/COMPLETE_SUCCESS';
const COMPLETE_FAIL = 'service-tapp/serviceRequest/COMPLETE_FAIL';
const DECLINE = 'service-tapp/serviceRequest/DECLINE';
const DECLINE_SUCCESS = 'service-tapp/serviceRequest/DECLINE_SUCCESS';
const DECLINE_FAIL = 'service-tapp/serviceRequest/DECLINE_FAIL';
const EDIT = 'service-tapp/serviceRequest/EDIT';
const EXPIRED = 'service-tapp/serviceRequest/EXPIRED';
const EXPIRED_SUCCESS = 'service-tapp/serviceRequest/EXPIRED_SUCCESS';
const EXPIRED_FAIL = 'service-tapp/serviceRequest/EXPIRED_FAIL';
const NO_REPAIR = 'service-tapp/serviceRequest/NO_REPAIR';
const NO_REPAIR_SUCCESS = 'service-tapp/serviceRequest/NO_REPAIR_SUCCESS';
const NO_REPAIR_FAIL = 'service-tapp/serviceRequest/NO_REPAIR_FAIL';
const PAYMENT = 'service-tapp/serviceRequest/PAYMENT';
const PAYMENT_SUCCESS = 'service-tapp/serviceRequest/PAYMENT_SUCCESS';
const PAYMENT_FAIL = 'service-tapp/serviceRequest/PAYMENT_FAIL';
const SAVED = 'service-tapp/serviceRequest/SAVED';
const SET = 'service-tapp/serviceRequest/SET';
const TOGGLE_SYMPTOM = 'service-tapp/serviceRequest/TOGGLE_SYMPTOM';

const initialState = {
  accepting: true,
  completing: true,
  data: {},
  dirty: false,
  noRepairing: true,
  declineing: true,
  selectedTab: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACCEPT:
      return {
        ...state,
        accepting: true,
      };
    case ACCEPT_SUCCESS:
      return {
        ...state,
        accepting: false,
        data: action.result,
        error: null,
      };
    case ACCEPT_FAIL:
      return {
        ...state,
        accepting: false,
        data: null,
        error: action.error,
      };
    case CHANGE_TAB:
      return {
        ...state,
        selectedTab: action.tabName,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case COMPLETE:
      return {
        ...state,
        completing: true,
      };
    case COMPLETE_SUCCESS:
      return {
        ...state,
        completing: false,
        data: action.result,
        error: null,
      };
    case COMPLETE_FAIL:
      return {
        ...state,
        completing: false,
        data: null,
        error: action.error,
      };
    case EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case EXPIRED:
      return {
        ...state,
        accepting: true,
      };
    case EXPIRED_SUCCESS:
      return {
        ...state,
        accepting: false,
        data: action.result,
        error: null,
      };
    case EXPIRED_FAIL:
      return {
        ...state,
        accepting: false,
        data: null,
        error: action.error,
      };
    case NO_REPAIR:
      return {
        ...state,
        noRepairing: true,
      };
    case NO_REPAIR_SUCCESS:
      return {
        ...state,
        noRepairing: false,
        data: action.result,
        error: null,
      };
    case NO_REPAIR_FAIL:
      return {
        ...state,
        noRepairing: false,
        data: null,
        error: action.error,
      };
    case PAYMENT:
      return {
        ...state,
        accepting: true,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        accepting: false,
        data: action.result,
        error: null,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        accepting: false,
        // data: null,
        error: action.error,
      };
    case SAVED:
      return {
        ...state,
        dirty: false,
      };
    case SET:
      return {
        ...state,
        data: action.serviceRequest,
        dirty: true,
      };
    case TOGGLE_SYMPTOM:
      const sReqWithNewSymptoms = { ...state.data };
      const sympIndex = sReqWithNewSymptoms.symptoms.indexOf(action.symptom);
      if (sympIndex === -1) {
        sReqWithNewSymptoms.symptoms = [
          ...sReqWithNewSymptoms.symptoms,
          action.symptom,
        ];
      } else {
        sReqWithNewSymptoms.symptoms = without(
          sReqWithNewSymptoms.symptoms,
          action.symptom
        );
      }

      return {
        ...state,
        data: sReqWithNewSymptoms,
      };
    case DECLINE:
      return {
        ...state,
        declineing: true,
      };
    case DECLINE_SUCCESS:
      return {
        ...state,
        declineing: false,
        data: action.result,
        error: null,
      };
    case DECLINE_FAIL:
      return {
        ...state,
        declineing: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

export function accept(id, token, stripeToken) {
  return {
    types: [ACCEPT, ACCEPT_SUCCESS, ACCEPT_FAIL],
    promise: client =>
      client.post(`/serviceRequest/accept/${id}`, {
        data: { token, stripeToken },
      }),
  };
}

export function changeTab(tabName) {
  return { type: CHANGE_TAB, tabName };
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function complete(id, token) {
  return {
    types: [COMPLETE, COMPLETE_SUCCESS, COMPLETE_FAIL],
    promise: client =>
      client.post(`/serviceRequest/complete/${id}`, {
        data: { token },
      }),
  };
}

export function decline(id, token) {
  return {
    types: [DECLINE, DECLINE_SUCCESS, DECLINE_FAIL],
    promise: client =>
      client.post(`/serviceRequest/decline/${id}`, {
        data: { token },
      }),
  };
}

export function edit(data) {
  return { type: EDIT, data };
}

export function expired(id, token) {
  return {
    types: [EXPIRED, EXPIRED_SUCCESS, EXPIRED_FAIL],
    promise: client =>
      client.post(`/serviceRequest/expired/${id}`, {
        data: { token },
      }),
  };
}

export function makePayment(id, token, stripeData) {
  return {
    types: [PAYMENT, PAYMENT_SUCCESS, PAYMENT_FAIL],
    promise: client =>
      client.post(`/serviceRequest/payment/${id}`, {
        data: { token, stripeData },
      }),
  };
}

export function noRepair(id, token) {
  return {
    types: [NO_REPAIR, NO_REPAIR_SUCCESS, NO_REPAIR_FAIL],
    promise: client =>
      client.post(`/serviceRequest/noRepair/${id}`, {
        data: { token },
      }),
  };
}

export function retailerAccept(id, token, stripeData) {
  return {
    types: [ACCEPT, ACCEPT_SUCCESS, ACCEPT_FAIL],
    promise: client =>
      client.post(`/serviceRequest/retailerAccept/${id}`, {
        data: { token, stripeData },
      }),
  };
}

export function retailerDecline(id, token) {
  return {
    types: [DECLINE, DECLINE_SUCCESS, DECLINE_FAIL],
    promise: client =>
      client.post(`/serviceRequest/retailerDecline/${id}`, {
        data: { token },
      }),
  };
}

export function saved() {
  return { type: SAVED };
}

export function set(serviceRequest) {
  return { type: SET, serviceRequest };
}

export function toggleSymptom(symptom) {
  return { type: TOGGLE_SYMPTOM, symptom };
}
