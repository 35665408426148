import objectId from "bson-objectid";
import moment from "moment";
import { Component, createRef } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
// import { asyncConnect } from "redux-async-connect";
import { TransitionGroup, Transition } from "react-transition-group";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import request from "superagent";

import { setUser, saveUser, updateUser } from "../../redux/modules/auth";
import * as appliancesActions from "../../redux/modules/appliances";
import {
  changeName as changeLocationName,
  reset as resetSearch,
} from "../../redux/modules/location";
import {
  clear as clearProvider,
  load as loadProvider,
} from "../../redux/modules/provider";
import {
  edit as editServiceRequest,
  saved as serviceSaved,
  set as setService,
  toggleSymptom,
} from "../../redux/modules/serviceRequest";
import config from "../../config";
import { getWarrantyDetails } from "../../utils/warranty";
import ApplianceFilters from "../../components/ApplianceFilters/ApplianceFilters";
import AppliancesList from "../../components/AppliancesList/AppliancesList";
import ApplianceTypesList from "../../components/ApplianceTypesList/ApplianceTypesList";
import EditApplianceForm from "../../components/EditApplianceForm/EditApplianceForm";
import ExtendedWarrantyForm from "../../components/ExtendedWarrantyForm/ExtendedWarrantyForm";
import FindLocationForm from "../../components/FindLocationForm/FindLocationForm";
import Header from "../../components/Header/Header";
import LocationButton from "../../components/LocationButton/LocationButton";
import Modal from "../../components/Modal/Modal";
import ModelNumberForm from "../../components/ModelNumberForm/ModelNumberForm";
import NameLocationForm from "../../components/NameLocationForm/NameLocationForm";
import RepairHistory from "../../components/RepairHistory/RepairHistory";
import ServiceDetailsForm from "../../components/ServiceDetailsForm/ServiceDetailsForm";
import WarrantyDetails from "../../components/WarrantyDetails/WarrantyDetails";

const MyAppliances = styled.section`
  background: transparent;
`;

const Locations = styled.div`
  flex-wrap: wrap;
`;

export const VideoFile = styled.span`
  display: block;
  background: #fafafa;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 2em;
  }
`;

export const Uploads = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a:hover ${VideoFile} {
    background-color: #f5f5f5;
  }
`;

export const UploadFile = styled.span`
  position: relative;
  width: 30%;
  height: auto;
  margin-bottom: 6%;

  &.empty {
    margin-top: 0;
  }

  .delete {
    position: absolute;
    top: -12px;
    right: -12px;
  }

  img {
    display: block;
  }
`;

class Appliances extends Component {
  constructor() {
    super();
    this.state = { files: [] };
    this.modalRef = createRef(null);
  }

  onDrop = (files) => {
    // console.log( 'dropped', files );

    const { serviceRequest } = this.props;
    const images = [...serviceRequest.images];
    const videos = [...serviceRequest.videos];

    this.setState({ files });

    const req = request.post("/api/files/add/serviceRequests");
    files.forEach((file) => req.attach(`images`, file));
    req.end((err, res) => {
      if (err) {
        this.setState({ files: [] });
        return console.log("error", err);
      }
      // console.log( res );

      res.body.images.map((imagePath) => images.push(imagePath));
      res.body.videos.map((videoPath) => videos.push(videoPath));
      this.props.editServiceRequest({ images, videos });
      this.setState({ files: [] });
    });
  };

  getSymptoms = (type) => {
    return this.props.applianceTypes
      .filter((applianceType) => applianceType.slug === type)
      .reduce((symptoms, applianceType) => applianceType.symptoms, []);
  };

  setOtherSymptom = (evt) => {
    this.props.editServiceRequest({ other: evt.target.value });
  };

  addGuest = () => {
    if (this.props.user) {
      return;
    }

    const guestId = String(objectId());
    const user = {
      _id: guestId,
      name: { first: "", last: "" },
      email: "",
      locations: [],
      phoneNumber: "",
      token: "",
      guest: true,
      verified: false,
    };
    this.props.setUser(user);
  };

  cancelEditing = () => {
    const { appliances, dirty, editing, selectedTab } = this.props;

    if (dirty[editing]) {
      if (
        selectedTab !== "welcome" &&
        !window.confirm(
          "Are you sure you want to cancel editing this appliance? The data for this appliance will be lost."
        )
      ) {
        return;
      }

      const currentAppliance = appliances.filter(
        (item) => item._id === editing
      )[0];
      currentAppliance.images.forEach((imagePath) => {
        const req = request.post("/api/appliance/deleteUpload");
        // req.set({ 'x-user-id': this.props.user._id });
        req.send({ imagePath });
        req.end((err) => {
          if (err) {
            return console.log("error", err);
          }
        });
      });
    }

    this.props.editStop();
  };

  checkApplianceDetails = () => {
    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];

    if (typeof currentAppliance.brand !== "object") {
      alert("You must select a brand.");
      return false;
    }

    const purchaseDate = moment(
      currentAppliance.purchaseDate,
      "MM-DD-YYYY"
    ).startOf("day");
    const tomorrow = moment().startOf("day").add(1, "days");
    if (purchaseDate >= tomorrow) {
      alert("The date of purchase cannot exceed today's date.");
      return false;
    }

    return true;
  };

  createAccount = () => {
    this.props.changeTab("type");

    // HACK: Timeout below is to prevent an error:
    // HACK: "Cannot read property 'componentDidLeave' of undefined" in ReactTransitionGroup.js:172

    // TODO: Might want to find a better way to handle that
    setTimeout(this.context.router.push.bind(null, "/account"), 500);
  };

  loginInstead = () => {
    this.cancelEditing();
    this.props.history.push("/login");
  };

  lookupAppliance = () => {
    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];

    if (currentAppliance.modelNumber.length > 0) {
      this.props.lookup(currentAppliance.modelNumber);
    } else {
      alert("You must enter a model or serial number.");
    }
  };

  removeImage = (imagePath) => {
    if (
      window.confirm(
        "Are you sure you want to remove this image? This cannot be undone."
      )
    ) {
      const req = request.post("/api/files/remove");
      req.send({ imagePath });
      req.end((err, res) => {
        if (err) {
          return console.log("error", err);
        }
        // console.log( res );

        if (res.statusCode === 200) {
          const { serviceRequest } = this.props;

          let images = [...serviceRequest.images];
          images = images.filter((path) => path !== imagePath);

          let videos = [...serviceRequest.videos];
          videos = videos.filter((path) => path !== imagePath);

          this.props.editServiceRequest({ images, videos });
        }
      });
    }
  };

  resetService = () => {
    const { dirtyRequest, serviceRequest } = this.props;

    if (dirtyRequest) {
      serviceRequest.images.forEach((imagePath) => {
        const req = request.post("/api/files/remove");
        req.send({ imagePath });
        req.end((err) => {
          if (err) {
            return console.log("error", err);
          }
        });
      });
      serviceRequest.videos.forEach((videoPath) => {
        const req = request.post("/api/files/remove");
        req.send({ imagePath: videoPath });
        req.end((err) => {
          if (err) {
            return console.log("error", err);
          }
        });
      });
    }

    if (!dirtyRequest && this.props.user.guest) {
      this.props.changeTab("createAccount");
    }

    this.props.setService({});
    this.props.serviceStop();
    this.props.changeSymptom("");
    this.props.clearProvider();
  };

  saveAppliance = () => {
    const { appliances, editing, first, user } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];
    if (!currentAppliance.user) {
      // TODO: this should be set on initial creation of the appliance? (in AppliancesListNewItem.js - componentDidMount())
      // NOTE: we'd have to refactor a bit to get the above to work
      // NOTE: the first appliance which get's saved the first time around is created before the guest user is
      currentAppliance.user = user._id;
    }

    if (this.checkApplianceDetails() === true) {
      this.props.save(currentAppliance);
      this.props.editStop();
    }

    if (first) {
      // TODO: partial dupe of getService() in components/AppliancesListItem
      const serviceRequestId = String(objectId());
      const serviceRequest = {
        _id: serviceRequestId,
        appliance: editing,
        provider: "",
        user: user._id,
        symptoms: [],
        other: "",
        images: [],
        videos: [],
        name: { first: "", last: "" },
        email: "",
        phoneNumber: "",
        status: config.requestStatuses.PENDING,
        tokens: { accept: "", decline: "" },
        // createdAt: new Date(),
      };
      this.props.setService(serviceRequest);

      this.props.serviceStart(editing);
      this.props.firstAppliance(false);
    }
  };

  showServiceDetails = () => {
    if (!this.props.serviceRequest.symptoms.length) {
      return alert("You must select a symptom first");
    }

    this.props.changeTab("serviceDetails");
  };

  submitService = () => {
    const { provider, servicing, serviceRequest, user } = this.props;

    this.props.submitService({
      ...serviceRequest,
      applianceId: servicing,
      providerId: provider._id,
    });

    this.props.serviceSaved();

    this.resetService();
    // this.props.changeTab('whileYouWait');

    if (
      !user.name.first ||
      (!user.name.first.length && !user.name.last.length)
    ) {
      const name = { ...serviceRequest.name };
      const email = serviceRequest.email;
      const phoneNumber = serviceRequest.phoneNumber;
      this.props.updateUser({
        name,
        email,
        phoneNumber,
      });

      if (!user.guest) {
        setTimeout(() => {
          user.name = name;
          user.email = email;
          user.phoneNumber = phoneNumber;
          this.props.saveUser(user);
        }, 500);
      }
    }
  };

  render() {
    const {
      adding,
      appliances,
      dirty,
      editing,
      emailRegistered,
      found,
      locations,
      provider,
      providerLoaded,
      selectedTab,
      servicing,
      serviceRequest,
      user,
    } = this.props;

    let symptoms = [];
    if (servicing) {
      const [sevicingAppliance] = appliances.filter(
        (item) => item._id === servicing
      );
      if (sevicingAppliance) {
        const servicingType = sevicingAppliance.type.slug;
        symptoms = this.getSymptoms(servicingType);
      }
    }

    const [currentAppliance] = (appliances || []).filter(
      (item) => item._id === editing
    );

    let applianceType = false;
    let warrantyExpired = false;
    let noWarranty = false;
    if (editing) {
      if (!!currentAppliance.type) {
        applianceType = true;
      }

      const warranty = getWarrantyDetails(currentAppliance.purchaseDate);
      warrantyExpired = warranty.status === "expired";
      noWarranty = warranty.status === "none";
    }

    const saveDetailsWithoutLocation = (evt) => {
      if (evt) {
        evt.preventDefault();
      }

      if (this.checkApplianceDetails() === false) {
        return false;
      }

      const tab = locations.length ? "useLocation" : "findLocation";
      this.props.changeTab(tab);
    };

    const saveDetailsWithLocation = (evt) => {
      if (evt) {
        evt.preventDefault();
      }
      this.saveAppliance();
    };

    const detailsSaveMethod =
      dirty[editing] && !currentAppliance.location
        ? saveDetailsWithoutLocation
        : saveDetailsWithLocation;

    let findCancelMethod = () => {
      const tab = locations.length > 0 ? "useLocation" : "details";
      this.props.changeTab(tab);
    };
    let findSaveMethod = undefined;
    if (found) {
      findCancelMethod = this.props.resetSearch;
      findSaveMethod = () => {
        this.props.changeTab("location");
      };
    }

    return (
      <div>
        <Helmet title="My Appliances" />
        <Header isHero />

        <ApplianceFilters />

        <MyAppliances className="section">
          <div className="container">
            <AppliancesList />

            <TransitionGroup>
              {selectedTab === "welcome" && (
                <Transition key="welcome" nodeRef={this.modalRef} timeout={500}>
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Welcome"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel
                      save={() => {
                        this.addGuest();
                        this.props.changeTab("type");
                      }}
                      saveText="Let's Go!"
                    >
                      {user && <p>Thank you for signing up!</p>}
                      <p>
                        In order for us to help get your appliance serviced,
                        we'll first need to collect a few details about it.
                      </p>
                      {!user && (
                        <p>
                          If you already have an account,
                          <br />
                          you should{" "}
                          <button
                            className="text-btn"
                            onClick={this.loginInstead.bind(this)}
                          >
                            Login instead
                          </button>
                          .
                        </p>
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "model" && (
                <Transition key="model" nodeRef={this.modalRef} timeout={500}>
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Let us look up the details for you"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={this.props.changeTab.bind(null, "type")}
                      cancelText="Skip"
                      save={() => {
                        this.lookupAppliance();
                      }}
                      saveText="Lookup"
                    >
                      <ModelNumberForm />
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "type" && (
                <Transition key="type" nodeRef={this.modalRef} timeout={500}>
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="What type of appliance do you have?"
                      contentStyles={{ maxWidth: 450 }}
                      cancel={this.cancelEditing.bind(this)}
                      hideSave={!applianceType}
                      save={this.props.changeTab.bind(null, "details")}
                      saveText="Next"
                    >
                      <ApplianceTypesList />
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "details" && (
                <Transition key="details" nodeRef={this.modalRef} timeout={500}>
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Tell us about the details"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.cancelEditing();
                      }}
                      saveText={dirty[editing] ? "Next" : "Save"}
                    >
                      {(submitBtnRef) => (
                        <EditApplianceForm
                          submitBtnRef={submitBtnRef}
                          saveAppliance={detailsSaveMethod}
                        />
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "useLocation" && (
                <Transition
                  key="useLocation"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Would you like to use an existing location?"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.props.changeTab("details");
                      }}
                      cancelText="Back"
                      save={() => {
                        this.props.changeTab("findLocation");
                      }}
                      saveText="No, Add Location"
                    >
                      <p>Select a location by tapping it below</p>
                      <Locations className="columns is-mobile">
                        {locations.map((location) => (
                          <LocationButton
                            key={location._id}
                            location={location}
                            saveAppliance={this.saveAppliance}
                          />
                        ))}
                      </Locations>
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "findLocation" && (
                <Transition
                  key="findLocation"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title={
                        found
                          ? "Does this look correct?"
                          : "Enter your location"
                      }
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={findCancelMethod}
                      cancelText={found ? "No, Reset" : "Back"}
                      save={findSaveMethod}
                      saveText={found ? "Yes, Continue" : "Search"}
                    >
                      {(submitBtnRef) => (
                        <FindLocationForm submitBtnRef={submitBtnRef} />
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "location" && (
                <Transition
                  key="location"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Name this location"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.props.changeTab("findLocation");
                      }}
                      cancelText="Wait, Go Back!"
                      saveLoading={adding}
                      saveText="Done!"
                    >
                      {(submitBtnRef) => (
                        <NameLocationForm
                          submitBtnRef={submitBtnRef}
                          saveAppliance={this.saveAppliance}
                        />
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {servicing && selectedTab === "symptoms" && (
                <Transition
                  key="symptoms"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Tell us what's happening"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.resetService();
                      }}
                      save={() => {
                        this.showServiceDetails();
                      }}
                      saveText="Continue"
                    >
                      <div className="field">
                        {symptoms.map((symptom, index) => {
                          const classNames =
                            serviceRequest.symptoms.indexOf(symptom) > -1
                              ? " is-primary is-inverted"
                              : " is-white";

                          const iconClass =
                            serviceRequest.symptoms.indexOf(symptom) > -1
                              ? " fa-check-circle"
                              : " fa-circle-o";

                          return (
                            <button
                              key={index}
                              className={"button is-fullwidth" + classNames}
                              onClick={this.props.toggleSymptom.bind(
                                null,
                                symptom
                              )}
                              style={{ justifyContent: "flex-start" }}
                            >
                              <i className={"fa" + iconClass} />
                              &nbsp;{symptom}
                            </button>
                          );
                        })}

                        {(() => {
                          const classNames =
                            serviceRequest.symptoms.indexOf("Other") > -1
                              ? " is-primary is-inverted"
                              : " is-white";

                          const iconClass =
                            serviceRequest.symptoms.indexOf("Other") > -1
                              ? " fa-check-circle"
                              : " fa-circle-o";

                          return (
                            <button
                              className={"button is-fullwidth" + classNames}
                              onClick={this.props.toggleSymptom.bind(
                                null,
                                "Other"
                              )}
                              style={{ justifyContent: "flex-start" }}
                            >
                              <i className={"fa" + iconClass} />
                              &nbsp;Other
                            </button>
                          );
                        })()}
                      </div>

                      {serviceRequest.symptoms.indexOf("Other") > -1 && (
                        <div className="field">
                          <textarea
                            className="textarea"
                            style={{ marginTop: "20px" }}
                            placeholder="Tell us more..."
                            value={serviceRequest.other}
                            onChange={this.setOtherSymptom.bind(this)}
                          />
                        </div>
                      )}

                      {/*
                        TODO: Might be nice to create a component out of this
                        TODO: This would also be a good place to move the dupe .uploads SCSS (or just make it global)
                      */}
                      <div className="field">
                        <label className="label is-hidden">Images</label>
                        <Dropzone
                          onDrop={this.onDrop}
                          accept="image/*, video/*"
                          activeClassName="button has-icon is-fullwidth is-outlined is-success"
                          rejectClassName="button has-icon is-fullwidth is-outlined is-danger"
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="button has-icon is-fullwidth is-outlined is-primary"
                            >
                              <span className="icon is-small">
                                <i className="fa fa-camera" />
                              </span>
                              <span>Attach Images / Videos</span>
                              <input {...getInputProps()} />
                            </div>
                          )}
                        </Dropzone>
                        {(serviceRequest.images.length > 0 ||
                          serviceRequest.videos.length > 0 ||
                          this.state.files.length > 0) && (
                          <div>
                            <Uploads>
                              {this.state.files.length > 0 &&
                                this.state.files.map((file, index) => (
                                  <UploadFile key={index}>
                                    <span className={"st-overlay is-overlay"}>
                                      <span className={"st-loader loader"} />
                                    </span>
                                    {file.type.match(/^image\//) && (
                                      <img src={file.preview} alt="" />
                                    )}
                                    {file.type.match(/^video\//) && (
                                      <VideoFile>
                                        <i className="fa fa-file-video-o" />
                                      </VideoFile>
                                    )}
                                  </UploadFile>
                                ))}
                              {/* This extra span is to prevent a an empty space from showing when only 2 items are in "last row" */}
                              <UploadFile className="empty" />
                            </Uploads>

                            {serviceRequest.images.length > 0 && (
                              <div>
                                <h4>Images</h4>
                                <Uploads>
                                  {serviceRequest.images.map(
                                    (imagePath, index) => (
                                      <UploadFile key={index}>
                                        <button
                                          onClick={() => {
                                            this.removeImage(imagePath);
                                          }}
                                          className="button is-danger delete"
                                        />
                                        <button
                                          href={imagePath}
                                          target="_blank"
                                        >
                                          <img src={imagePath} alt="" />
                                        </button>
                                      </UploadFile>
                                    )
                                  )}
                                  {/* This extra span is to prevent a an empty space from showing when only 2 items are in "last row" */}
                                  <UploadFile className="empty" />
                                </Uploads>
                              </div>
                            )}

                            {serviceRequest.videos.length > 0 && (
                              <div>
                                <h4>Videos</h4>
                                <Uploads>
                                  {serviceRequest.videos.length > 0 &&
                                    serviceRequest.videos.map(
                                      (videoPath, index) => (
                                        <UploadFile key={index}>
                                          <button
                                            onClick={() => {
                                              this.removeImage(videoPath);
                                            }}
                                            className="button is-danger delete"
                                          />
                                          <button
                                            href={videoPath}
                                            target="_blank"
                                          >
                                            <VideoFile>
                                              <i className="fa fa-file-video-o" />
                                            </VideoFile>
                                          </button>
                                        </UploadFile>
                                      )
                                    )}
                                  {/* This extra span is to prevent a an empty space from showing when only 2 items are in "last row" */}
                                  <UploadFile className="empty" />
                                </Uploads>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Modal>
                  )}
                </Transition>
              )}

              {servicing && selectedTab === "serviceDetails" && (
                <Transition
                  key="serviceDetails"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Who should we contact?"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.props.changeTab("symptoms");
                      }}
                      cancelText="Back"
                      saveDisabled={emailRegistered === true}
                      saveText="Continue"
                    >
                      {(submitBtnRef) => (
                        <ServiceDetailsForm
                          submitBtnRef={submitBtnRef}
                          findProvider={this.findProvider}
                        />
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {servicing && selectedTab === "provider" && (
                <Transition
                  key="provider"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title={
                        providerLoaded
                          ? provider.business.name
                          : "Searching for a Provider"
                      }
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={() => {
                        this.props.changeTab("serviceDetails");
                      }}
                      cancelText="Back"
                      save={providerLoaded ? this.submitService : null}
                      saveText="Sounds Good!"
                    >
                      {!providerLoaded && (
                        <div>
                          <p>
                            Please wait a moment while we attempt to match you
                            with a provider in your area.
                          </p>
                          <div
                            className="searching"
                            style={{ fontSize: 48, height: 48 }}
                          >
                            <span className="fa fa-spin fa-spinner" />
                          </div>
                        </div>
                      )}

                      {providerLoaded && (
                        <div>
                          <p>
                            <strong>
                              {serviceRequest.name.first}{" "}
                              {serviceRequest.name.last}
                            </strong>{" "}
                            will be contacted at{" "}
                            <strong>{serviceRequest.phoneNumber}</strong> within
                            1 hour during normal operating hours to schedule an
                            appointment.
                          </p>
                          <p>
                            If for whatever reason you are not called back,
                            <br />
                            please call{" "}
                            <strong>
                              {provider.business.phone || "1-877-749-9587"}
                            </strong>
                          </p>
                        </div>
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {servicing && selectedTab === "whileYouWait" && (
                <Transition
                  key="whileYouWait"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title={provider.business.name}
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel
                      save={this.resetService.bind(this)}
                      saveText="Done"
                    >
                      <p>
                        will contact{" "}
                        <strong>
                          {serviceRequest.name.first} {serviceRequest.name.last}
                        </strong>{" "}
                        at <strong>{serviceRequest.phoneNumber}</strong> within
                        1 hour during normal operating hours to schedule an
                        appointment.
                      </p>
                      <p>
                        If for whatever reason you are not called back, please
                        call ServiceTapp at <strong>1-877-749-9587</strong>
                      </p>

                      <p>
                        <strong
                          className="title is-5"
                          style={{ fontWeight: "bold" }}
                        >
                          While you wait, we suggest you:
                        </strong>
                      </p>
                      <p>
                        1. Call 911 in the event of an emergency!
                        <br />
                        2. Turn off circuit breaker for 5 minutes
                        <br />
                        3. Turn off main water supply if it's leaking
                      </p>
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "repairHistory" && (
                <Transition
                  key="repairHistory"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Repair History"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel
                      save={this.cancelEditing.bind(this)}
                      saveText="Done"
                    >
                      <RepairHistory />
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "warrantyDetails" && (
                <Transition
                  key="warrantyDetails"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Warranty Details"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel={!warrantyExpired && !noWarranty}
                      cancelText={warrantyExpired ? "No" : "Edit Appliance"}
                      cancel={
                        warrantyExpired
                          ? this.props.changeTab.bind(null, "purchaseWarranty")
                          : this.props.changeTab.bind(null, "details")
                      }
                      save={
                        warrantyExpired
                          ? this.props.changeTab.bind(
                              null,
                              "extendedWarrantyDetails"
                            )
                          : this.cancelEditing.bind(this)
                      }
                      saveText={warrantyExpired ? "Yes" : "OK"}
                    >
                      <WarrantyDetails />
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "extendedWarrantyDetails" && (
                <Transition
                  key="extendedWarrantyDetails"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Tell us about your extended warranty"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      cancel={this.cancelEditing.bind(this)}
                      cancelText="Cancel"
                      save={this.cancelEditing.bind(this)}
                      saveText="Save"
                    >
                      {(submitBtnRef) => (
                        <ExtendedWarrantyForm
                          submitBtnRef={submitBtnRef}
                          saveWarranty={() => {}}
                        />
                      )}
                    </Modal>
                  )}
                </Transition>
              )}

              {editing && selectedTab === "purchaseWarranty" && (
                <Transition
                  key="purchaseWarranty"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="We can help extend your warranty"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel
                      save={this.cancelEditing.bind(this)}
                      saveText="OK"
                    >
                      <p>
                        Give us a call at: <strong>(877) 749-9587</strong>
                      </p>
                    </Modal>
                  )}
                </Transition>
              )}

              {selectedTab === "createAccount" && (
                <Transition
                  key="createAccount"
                  nodeRef={this.modalRef}
                  timeout={500}
                >
                  {(state) => (
                    <Modal
                      ref={this.modalRef}
                      transitionState={state}
                      title="Activate Your Account"
                      bodyClass="content has-text-centered"
                      contentStyles={{ maxWidth: 400 }}
                      hideCancel
                      save={this.props.changeTab.bind(null, "type")}
                      saveText="OK"
                    >
                      <p>Thank you for submitting your first request!</p>
                      <p>
                        In order for you to recieve email updates about your
                        request and have access to your appliance(s) in the
                        future, we ask that you activate your account.
                      </p>
                      <p>Please check your email for an activation link.</p>
                    </Modal>
                  )}
                </Transition>
              )}
            </TransitionGroup>
          </div>
        </MyAppliances>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    adding: state.auth.adding,
    appliances: state.appliances.data,
    applianceTypes: state.applianceTypes.data,
    dirty: state.appliances.dirty,
    dirtyRequest: state.serviceRequest.dirty,
    editing: state.appliances.editing,
    emailRegistered: state.auth.checking,
    first: state.appliances.first,
    found: state.location.found,
    locations: state.locations.data,
    locationName: state.location.name,
    locationQuery: state.location.query,
    position: state.location.position,
    provider: state.provider.data,
    providerLoaded: state.provider.loaded,
    searching: state.location.searching,
    servicing: state.appliances.servicing,
    serviceRequest: state.serviceRequest.data,
    selectedTab: state.appliances.selectedTab,
    user: state.auth.user,
  }),
  {
    ...appliancesActions,
    changeLocationName,
    clearProvider,
    editServiceRequest,
    loadProvider,
    resetSearch,
    saveUser,
    serviceSaved,
    setService,
    setUser,
    toggleSymptom,
    updateUser,
  }
)(Appliances);
