import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { logout } from "../../redux/modules/auth";
import { changeTab } from "../../redux/modules/serviceRequest";
import Icon from "../Icon/Icon";

const StyledIcon = styled(Icon)`
  margin: -5px 0;
`;

const LoggedIn = styled.span`
  opacity: 0.5;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 300;
`;

class ProviderHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { menuIsActive: false };
  }

  handleLogout = (evt) => {
    evt.preventDefault();
    this.props.logout();
  };

  toggleMenu = () => {
    this.setState({ menuIsActive: !this.state.menuIsActive });
  };

  render() {
    const { isHero, hideLogin, hideSignUp, user } = this.props;
    const { menuIsActive } = this.state;

    const isProvider = user && user.isProvider;

    return (
      <header>
        <nav
          className={
            "navbar" +
            (isHero ? " hero" : "") +
            (menuIsActive ? " is-active" : "")
          }
        >
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <StyledIcon icon="logo-text-right" height="36" width="" />
              </Link>

              <button
                className={"navbar-burger" + (menuIsActive ? " is-active" : "")}
                aria-label="menu"
                aria-expanded="false"
                onClick={this.toggleMenu}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div className={"navbar-menu" + (menuIsActive ? " is-active" : "")}>
              <div className="navbar-start">
                {isProvider && !user.guest && (
                  <Link
                    to="/account"
                    className="navbar-item is-hidden-mobile"
                    activeClassName="is-active"
                  >
                    Account
                  </Link>
                )}
                {/* {!user || !!user.guest && <Link to="/login" className="navbar-item is-hidden-mobile" activeClassName="is-active">Login</Link>} */}
              </div>

              <div className="navbar-end">
                {!user && !hideLogin && (
                  <button
                    className="navbar-item text-btn"
                    activeClassName="is-active"
                    onClick={() => this.props.changeTab("login")}
                  >
                    Provider Login
                  </button>
                )}
                {!user && !hideSignUp && (
                  <span className="navbar-item">
                    <a
                      href="http://servicetapp.com/service-provider-signup/"
                      target="_blank"
                      rel="noreferrer"
                      className="button is-primary"
                      activeClassName="is-active"
                    >
                      Sign Up
                    </a>
                  </span>
                )}

                {isProvider && (
                  <Link
                    to="/account"
                    className="navbar-item is-hidden-tablet"
                    activeClassName="is-active"
                  >
                    Account
                  </Link>
                )}
                {user && (
                  <span className="navbar-item">
                    <LoggedIn>Logged in as</LoggedIn>
                    &nbsp;
                    <strong>{user.name.first || user.email}</strong>
                  </span>
                )}
                {user && (
                  <span className="navbar-item">
                    <Link
                      to="/logout"
                      className="button is-primary"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </Link>
                  </span>
                )}
              </div>
            </div>
          </div>
        </nav>
        {menuIsActive && (
          <Backdrop
            className="animated fadeIn fast"
            onClick={this.toggleMenu}
          />
        )}
      </header>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { changeTab, logout }
)(ProviderHeader);
