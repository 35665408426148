import { isEmpty } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import MaskedInput from "react-maskedinput";
import request from "superagent";
import { edit as editServiceRequest } from "../../redux/modules/serviceRequest";
import { changeTab } from "../../redux/modules/appliances";
import {
  checkEmail,
  checkEmailFail,
  checkEmailStart,
  checkEmailSuccess,
} from "../../redux/modules/auth";
import { load as loadProvider } from "../../redux/modules/provider";
import {
  createValidator,
  email as isEmail,
  phone as isPhone,
  required as isRequired,
} from "../../utils/validation";

class ServiceDetailsForm extends Component {
  constructor() {
    super();
    this.state = { errors: {} };
  }

  setFirstName = (evt) => {
    const { name } = this.props.serviceRequest;
    name.first = evt.target.value;
    this.props.editServiceRequest({ name });
  };

  setLastName = (evt) => {
    const { name } = this.props.serviceRequest;
    name.last = evt.target.value;
    this.props.editServiceRequest({ name });
  };

  setEmail = (evt) => {
    this.props.editServiceRequest({ email: evt.target.value });
  };

  setPhone = (evt) => {
    this.props.editServiceRequest({ phoneNumber: evt.target.value });
  };

  checkCustomer = () => {
    const {
      serviceRequest: { email },
      user,
    } = this.props;
    return new Promise((resolve, reject) => {
      if (user && user.guest === false) {
        return resolve({ success: true });
      }

      const validationErrors = createValidator({
        email: [isRequired, isEmail],
      })({ email });
      this.setState({ errors: validationErrors });

      if (!isEmpty(validationErrors)) {
        // console.log(validationErrors);
        return resolve({
          message: validationErrors.email,
          param: "email",
          statusCode: 400,
        });
      }

      this.props.checkEmailStart();
      request
        .post("/api/auth/checkEmail")
        .send({ email })
        .end((err, res) => {
          if (err) {
            this.props.checkEmailFail(err);
            return resolve({ success: false });
          }

          this.props.checkEmailSuccess();
          // res.body == { success: true }
          return resolve({ success: true });
        });
    });
  };

  findProvider = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const { appliances, serviceRequest, servicing } = this.props;
    this.checkCustomer().then(() => {
      const rules = {
        first: [isRequired],
        last: [isRequired],
        email: [isRequired, isEmail],
        phone: [isRequired, isPhone],
      };
      const fieldData = {
        first: `${serviceRequest.name.first}`,
        last: `${serviceRequest.name.last}`,
        email: `${serviceRequest.email}`,
        phone: `${serviceRequest.phoneNumber}`,
      };
      const validationErrors = createValidator(rules)(fieldData);
      this.setState({ errors: validationErrors });

      if (!isEmpty(validationErrors)) {
        return;
      }

      this.props.changeTab("provider");
      if (!this.props.providerLoaded) {
        const currentAppliance = appliances.filter(
          (item) => item._id === servicing
        )[0];
        const { zip } = currentAppliance.location;

        // NOTE: This timeout is just to simulate the backend "looking"
        // TODO: Remove this timeout for production?
        setTimeout(this.props.loadProvider.bind(null, zip), 2000);
      }
    });
  };

  render() {
    const { checking, emailRegistered, serviceRequest } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this.findProvider}>
        <p>
          Please enter in a name and phone number that we can pass along to the
          service provider.
        </p>

        <div className="field">
          <label htmlFor="first" className="label has-text-left">
            Name
          </label>
          <div className="control columns is-mobile mb0">
            <div className="column is-half is-half-mobile">
              <p
                className={"control" + (errors.first ? " has-icons-right" : "")}
              >
                <input
                  id="first"
                  type="text"
                  placeholder="First"
                  className={"input" + (errors.first ? " is-danger" : "")}
                  value={serviceRequest.name.first}
                  onChange={this.setFirstName}
                />
                {errors.first && (
                  <span className="icon is-right">
                    <i className="fa fa-warning"></i>
                  </span>
                )}
                {errors.first && (
                  <span className="help is-danger">{errors.first}</span>
                )}
              </p>
            </div>

            <div className="column is-half is-half-mobile">
              <p
                className={"control" + (errors.last ? " has-icons-right" : "")}
              >
                <input
                  type="text"
                  placeholder="Last"
                  className={"input" + (errors.last ? " is-danger" : "")}
                  value={serviceRequest.name.last}
                  onChange={this.setLastName}
                />
                {errors.last && (
                  <span className="icon is-right">
                    <i className="fa fa-warning"></i>
                  </span>
                )}
                {errors.last && (
                  <span className="help is-danger">{errors.last}</span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">Email</label>
          <p
            className={
              "control" +
              (errors.email || emailRegistered !== null || checking
                ? " has-icons-right"
                : "")
            }
          >
            <input
              type="text"
              placeholder="you@domain.com"
              className={
                "input" +
                (errors.email || emailRegistered === true ? " is-danger" : "") +
                (emailRegistered === false ? " is-success" : "")
              }
              value={serviceRequest.email}
              onChange={this.setEmail}
              onBlur={this.checkCustomer}
            />
            {(errors.email || emailRegistered !== null || checking) && (
              <span className="icon is-right">
                {checking && <i className="fa fa-spinner fa-spin"></i>}
                {emailRegistered === false && <i className="fa fa-check"></i>}
                {(errors.email || emailRegistered === true) && (
                  <i className="fa fa-warning"></i>
                )}
              </span>
            )}
            {(errors.email || emailRegistered === true) && (
              <span className="help is-danger">
                {errors.email || "That email is already registered"}
              </span>
            )}
          </p>
        </div>

        <div className="field">
          <label htmlFor="phone" className="label has-text-left">
            Mobile Phone
          </label>
          <p className={"control" + (errors.phone ? " has-icons-right" : "")}>
            <MaskedInput
              id="phone"
              type="tel"
              className={"input" + (errors.phone ? " is-danger" : "")}
              mask="(111) 111-1111"
              placeholder="(555) 555-5555"
              value={serviceRequest.phoneNumber}
              onChange={this.setPhone}
            />
            <span className="icon is-right">
              {errors.phone && <i className="fa fa-warning"></i>}
            </span>
            {errors.phone && (
              <span className="help is-danger">{errors.phone}</span>
            )}
          </p>
        </div>

        <p className="control is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    checking: state.auth.checking,
    emailRegistered: state.auth.emailRegistered,
    providerLoaded: state.provider.loaded,
    servicing: state.appliances.servicing,
    serviceRequest: state.serviceRequest.data,
    user: state.auth.user,
  }),
  {
    changeTab,
    checkEmail,
    checkEmailFail,
    checkEmailStart,
    checkEmailSuccess,
    editServiceRequest,
    loadProvider,
  }
)(ServiceDetailsForm);
