import styled from "styled-components";
import { Component } from "react";
import { connect } from "react-redux";
import objectId from "bson-objectid";

import * as applianceActions from "../../redux/modules/appliances";
import AppliancesListNewItem from "../AppliancesListNewItem/AppliancesListNewItem";
import AppliancesListItem from "../AppliancesListItem/AppliancesListItem";

// .applianceFilters {
//   margin-bottom: 20px;
// }

const LocationList = styled.div`
  margin-bottom: 20px;

  h1 {
    margin-bottom: 5px;
  }
`;

class AppliancesList extends Component {
  addApplianceToLocation(location) {
    this.addAppliance(null, undefined, location);
  }

  addAppliance = (evt, tab, location) => {
    if (evt) {
      evt.preventDefault();
    }

    const { add, editStart } = this.props;
    const applianceId = String(objectId());
    const appliance = {
      _id: applianceId,
      type: "",
      brand: "",
      name: "",
      modelNumber: "",
      serialNumber: "",
      purchaseDate: "",
      warranty: { length: 0, status: 0 },
      location: location || null,
      images: [],
      serviceRequests: [],
    };
    add(appliance);
    editStart(applianceId, tab);
  };

  render() {
    const { appliances, dirty, filters, loading, locations } = this.props;

    const brandFilters = filters.brands.reduce(
      (prev, curr) => [...prev, curr._id],
      []
    );
    const locationFilters = filters.locations.reduce(
      (prev, curr) => [...prev, curr._id],
      []
    );
    const locationKeys = locations.reduce(
      (prev, curr) => [...prev, curr._id],
      []
    );
    const typeFilters = filters.types.reduce(
      (prev, curr) => [...prev, curr._id],
      []
    );

    const filteredAppliances = appliances
      .filter(
        (appliance) =>
          brandFilters.length === 0 ||
          brandFilters.includes(appliance.brand._id)
      )
      .filter(
        (appliance) =>
          typeFilters.length === 0 || typeFilters.includes(appliance.type._id)
      );

    return (
      <div>
        {loading && (
          <p className="has-text-centered">
            <button href="#" className="button is-outlined is-loading">
              Loading
            </button>
          </p>
        )}

        {!loading && (
          <div>
            {/* {(!appliances || appliances.length === 0) && <p> You do not have any appliances yet. </p>} */}

            <div className="appliances-list">
              {/* (brandFilters.length > 0 || locationFilters.length > 0 || typeFilters.length > 0) && <div className={styles.applianceFilters}>
                Active Filter(s):{' '}
                {filters.brands.length > 0 && filters.brands.map(brand => <span key={brand._id} className="tag is-light">
                  Brand: {brand.name}
                  <button className="delete is-small" onClick={() => { this.props.removeFilter('brands', brand._id); }}></button>
                </span>)}
                {' '}
                {filters.locations.length > 0 && filters.locations.map(loc => <span key={loc._id} className="tag is-light">
                  Location: {loc.name}
                  <button className="delete is-small" onClick={() => { this.props.removeFilter('locations', loc._id); }}></button>
                </span>)}
                {' '}
                {filters.types.length > 0 && filters.types.map(type => <span key={type._id} className="tag is-light">
                  Type: {type.name}
                  <button className="delete is-small" onClick={() => { this.props.removeFilter('types', type._id); }}></button>
                </span>)}
              </div> */}

              {brandFilters.length === 0 &&
                locationFilters.length === 0 &&
                typeFilters.length === 0 && (
                  <AppliancesListNewItem addAppliance={this.addAppliance} />
                )}

              {locations.length > 0 &&
                locations
                  .filter(
                    (loc) =>
                      locationFilters.length === 0 ||
                      locationFilters.includes(loc._id)
                  )
                  .map((loc) => (
                    <LocationList key={loc._id}>
                      <button
                        className="text-btn is-pulled-right"
                        onClick={this.addApplianceToLocation.bind(this, loc)}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <h1>
                        <strong>{loc.name}</strong> ({loc.street}, {loc.city},{" "}
                        {loc.state} {loc.zip})
                      </h1>

                      {filteredAppliances.filter(
                        (appliance) =>
                          appliance.location &&
                          appliance.location._id === loc._id
                      ).length > 0 &&
                        filteredAppliances
                          .filter(
                            (appliance) =>
                              appliance.location &&
                              appliance.location._id === loc._id
                          )
                          .map(
                            (appliance) =>
                              !dirty[appliance._id] && (
                                <AppliancesListItem
                                  key={appliance._id}
                                  appliance={appliance}
                                />
                              )
                          )}

                      {filteredAppliances
                        .filter(
                          (appliance) =>
                            appliance.location &&
                            appliance.location._id === loc._id
                        )
                        .filter((appliance) => !dirty[appliance._id]).length ===
                        0 &&
                        (brandFilters.length === 0 &&
                        locationFilters.length === 0 &&
                        typeFilters.length === 0 ? (
                          <div className="box">
                            <div className="content">
                              <p>
                                <strong>
                                  No appliances found at this location.
                                </strong>{" "}
                                <span>
                                  <button
                                    className="text-btn"
                                    onClick={this.addApplianceToLocation.bind(
                                      this,
                                      loc
                                    )}
                                  >
                                    Add an appliance
                                  </button>{" "}
                                  to get started.
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="box">
                            <p>
                              No appliances matching the selected filter(s)
                              found at this location.
                            </p>
                          </div>
                        ))}
                    </LocationList>
                  ))}

              {filteredAppliances
                .filter(
                  (appliance) =>
                    !appliance.location ||
                    !locationKeys.includes(appliance.location._id)
                )
                .filter((appliance) => !dirty[appliance._id]).length > 0 && (
                <LocationList>
                  <h1>
                    <strong>Unknown Location</strong>
                  </h1>

                  {filteredAppliances
                    .filter(
                      (appliance) =>
                        !appliance.location ||
                        !locationKeys.includes(appliance.location._id)
                    )
                    .filter((appliance) => !dirty[appliance._id])
                    .map((appliance) => (
                      <AppliancesListItem
                        key={appliance._id}
                        appliance={appliance}
                      />
                    ))}
                </LocationList>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    dirty: state.appliances.dirty,
    filters: state.appliances.filters,
    loading: state.appliances.loading,
    locations: state.locations.data,
  }),
  { ...applianceActions }
)(AppliancesList);
