import React from 'react';

const Empty = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="13 11 54 58" {...props}>
      <path fill={fill} d="" />
    </svg>
  );
};

export default Empty;
