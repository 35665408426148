import { findIndex } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import * as applianceActions from "../../redux/modules/appliances";

class ModelNumberForm extends Component {
  componentDidUpdate() {
    const { changeTab, lookupFound, lookupReset } = this.props;

    if (lookupFound === true) {
      lookupReset();
      changeTab("details");
    } else if (lookupFound === false) {
      lookupReset();
      // TODO: Show an error / notify user that it wasn't found.
    }
  }

  updateModelNumber = (evt) => {
    // eslint-disable-line
    const modelNumber = evt.target.value;
    this.props.editUpdate({ modelNumber });
  };

  updateSerialNumber = (evt) => {
    // eslint-disable-line
    const serialNumber = evt.target.value;
    this.props.editUpdate({ serialNumber });
  };

  render() {
    const { appliances, editing, looking } = this.props;

    let modelNumber = "";
    let serialNumber = "";
    if (editing) {
      const currentIndex = findIndex(
        appliances,
        (item) => item._id === editing
      );
      const currentAppliance = appliances[currentIndex];

      modelNumber = currentAppliance.modelNumber;
      serialNumber = currentAppliance.serialNumber;
    }

    return (
      <form>
        {looking && (
          <div className={"st-overlay is-overlay"}>
            <span className={"st-loader loader"}></span>
          </div>
        )}

        <p className="control">
          <label className="label is-hidden">Model Number</label>
          <input
            type="text"
            className="input"
            placeholder="Model Number"
            value={modelNumber}
            onChange={this.updateModelNumber}
          />
        </p>

        <p className="control">
          <label className="label is-hidden">Serial Number</label>
          <input
            type="text"
            className="input"
            placeholder="Serial Number"
            value={serialNumber}
            onChange={this.updateSerialNumber}
          />
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    editing: state.appliances.editing,
    looking: state.appliances.looking,
    lookupFound: state.appliances.lookupFound,
  }),
  { ...applianceActions }
)(ModelNumberForm);
