import { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import config from "../../config";
import * as locationActions from "../../redux/modules/location";

class FindLocationForm extends Component {
  // NOTE: This is now controlled by the .locate icon below
  // componentDidMount() {
  //   if ( !this.props.found && !this.props.requested && navigator.geolocation ) {
  //     setTimeout(this.requestLocation, 1e3);
  //   }
  // }

  setLocationStreet = (evt) => {
    this.props.changeStreet(evt.target.value);
  };

  setLocationCity = (evt) => {
    this.props.changeCity(evt.target.value);
  };

  setLocationState = (evt) => {
    this.props.changeState(evt.target.value);
  };

  setLocationZip = (evt) => {
    this.props.changeZip(evt.target.value);
  };

  findLocation = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const { street, city, state, zip } = this.props;
    if (
      street.length === 0 ||
      city.length === 0 ||
      state.length === 0 ||
      zip.length === 0
    ) {
      return alert("Please enter your entire address.");
    }

    this.props.searchStart();
    const domain = "https://maps.googleapis.com/maps/api/geocode/json";
    const geocodeURL = `${domain}?address=${street || ""}+${city || ""}+${
      state || ""
    }+${zip || ""}&key=${config.google.apiKey}`;
    const req = request.get(geocodeURL);
    req.end((err, res) => {
      if (err) {
        return console.log("error", err);
      }
      if (res.body.status === "OK") {
        const { lat, lng } = res.body.results[0].geometry.location;
        const pos = { lat, lng };
        this.props.searchSuccess(pos);

        let foundStreet = "";
        let foundZip = false;
        res.body.results[0].address_components.forEach((comp) => {
          if (comp.types.indexOf("street_number") > -1) {
            foundStreet += comp.long_name;
          }

          if (comp.types.indexOf("route") > -1) {
            foundStreet += " " + comp.short_name;
          }

          if (comp.types.indexOf("locality") > -1) {
            this.props.changeCity(comp.long_name);
          }

          if (comp.types.indexOf("administrative_area_level_1") > -1) {
            this.props.changeState(comp.short_name);
          }

          if (comp.types.indexOf("postal_code") > -1) {
            foundZip = comp.long_name;
          }
        });

        if (foundStreet.length > 0) {
          this.props.changeStreet(foundStreet);
        }

        if (foundZip) {
          this.props.changeZip(foundZip);
        } else {
          this.updateQueryAndZipFromPosition(pos);
        }
      } else {
        // console.log( res.body );
        this.props.searchFailed();
      }
    });
  };

  requestLocation = () => {
    this.props.request();
    this.props.searchStart();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log( 'getCurrentPosition success', position );
        const { latitude, longitude } = position.coords;
        const pos = { lat: latitude, lng: longitude };
        this.props.searchSuccess(pos);

        this.updateQueryAndZipFromPosition(pos);
      },
      (error) => {
        // console.log( 'getCurrentPosition error', error );
        this.props.searchFailed(error.message);
      },
      { timeout: 5000 }
    );
  };

  updateQueryAndZipFromPosition = (pos) => {
    const domain = "https://maps.googleapis.com/maps/api/geocode/json";
    const geocodeURL = `${domain}?latlng=${pos.lat},${pos.lng}&key=${config.google.apiKey}`;
    const req = request.get(geocodeURL);
    req.end((err, res) => {
      if (err) {
        return console.log("error", err);
      }
      if (res.body.status === "OK") {
        res.body.results[0].address_components.forEach((comp) => {
          if (comp.types.indexOf("locality") > -1) {
            this.props.changeCity(comp.long_name);
          }

          if (comp.types.indexOf("administrative_area_level_1") > -1) {
            this.props.changeState(comp.short_name);
          }

          if (comp.types.indexOf("postal_code") > -1) {
            this.props.changeZip(comp.long_name);
          }
        });
      } else {
        // console.log( res.body );
      }
    });
  };

  render() {
    const { /* found, requested, */ requestError, position } = this.props;

    const domain = "//maps.googleapis.com/maps/api/staticmap";
    const { lat, lng } = position;
    const mapImageSrc = `${domain}?center=${lat},${lng}&zoom=13&size=300x300&sensor=false&key=${config.google.apiKey}`;

    return (
      <form className="relative" onSubmit={this.findLocation.bind(this)}>
        <p className="control">
          <input
            type="text"
            className="input"
            placeholder="Street"
            onChange={this.setLocationStreet.bind(this)}
            value={this.props.street}
          />
        </p>

        <div className="columns is-mobile">
          <div className="column is-half">
            <p className="control">
              <input
                type="text"
                className="input"
                placeholder="City"
                onChange={this.setLocationCity.bind(this)}
                value={this.props.city}
              />
            </p>
          </div>
          <div className="column is-one-quarter">
            <p className="control">
              <input
                type="text"
                className="input"
                placeholder="State"
                onChange={this.setLocationState.bind(this)}
                value={this.props.state}
              />
            </p>
          </div>
          <div className="column is-one-quarter">
            <p className="control">
              <input
                type="text"
                className="input"
                placeholder="Zip"
                onChange={this.setLocationZip.bind(this)}
                value={this.props.zip}
              />
            </p>
          </div>
        </div>

        {position.lat && (
          <div className={"location control"}>
            <img src={mapImageSrc} alt="Current Location Google Map" />
            <span className="marker">
              <i className="fa fa-map-marker animated zoomInDown"></i>
            </span>
          </div>
        )}

        {/* navigator.geolocation && !found && !requestError && <span className="locate" title="Locate Me!" onClick={this.requestLocation}>
          <i className={'fa' + (requested ? ' fa-spin fa-spinner' : ' fa-crosshairs')}></i>
        </span> */}

        {requestError && (
          <p className="has-text-centered">
            We were unable to determine your position.
            <br />
            Please use the field above to search for a location.
          </p>
        )}

        <p className="control is-hidden">
          <button ref={this.props.submitBtnRef} type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default connect(
  (state) => ({
    city: state.location.city,
    found: state.location.found,
    position: state.location.position,
    requested: state.location.requested,
    requestError: state.location.requestError,
    searching: state.location.searching,
    state: state.location.state,
    street: state.location.street,
    zip: state.location.zip,
  }),
  { ...locationActions }
)(FindLocationForm);
