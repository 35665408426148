import React from 'react';

const Hood = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="3.2 18.2 73.6 43.6" {...props}>
      <path fill={fill} d="M36,60.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8S35.6,60.2,36,60.2z M36,58.8
      	c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6S35.6,58.8,36,58.8z M40,60.2c0.4,0,0.8-0.4,0.8-0.8
      	c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8S39.6,60.2,40,60.2z M40,58.8c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6
      	c-0.4,0-0.6-0.2-0.6-0.6S39.6,58.8,40,58.8z M42,60.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8
      	S41.6,60.2,42,60.2z M42,58.8c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6S41.6,58.8,42,58.8z M44,60.2
      	c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C43,59.8,43.4,60.2,44,60.2z M44,58.8c0.4,0,0.6,0.2,0.6,0.6
      	c0,0.4-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6S43.6,58.8,44,58.8z M38,60.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
      	c-0.4,0-0.8,0.4-0.8,0.8S37.6,60.2,38,60.2z M38,58.8c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6
      	S37.6,58.8,38,58.8z M75.4,54.6C69.8,53,61.8,52,52.6,51.4V18.6c0-0.2,0-0.2-0.2-0.2l-0.2-0.2H27.8c-0.2,0-0.2,0-0.2,0.2l-0.2,0.2
      	v32.8C18.2,52,10.2,53,4.6,54.6c-0.8,0.2-1.4,1-1.4,1.6c0,0.2,0,0.4,0,0.6l0,0c0.4,0.6,1.2,1.2,2,1.2c0.2,0,0.4,0,0.6,0
      	c3.6-1,8.6-1.8,14.4-2.4L19.8,56l0,0l0,0v0.2l0,0v0.2l2.2,5.2c0,0.2,0.2,0.2,0.4,0.2h35.4c0.2,0,0.4,0,0.4-0.2l2.2-5.4V56l0,0
      	c0,0,0,0,0-0.2l0,0l0,0L60,55.4c5.8,0.6,10.8,1.4,14.4,2.4c0.2,0,0.4,0,0.6,0c0.8,0,1.6-0.4,1.8-1.2c0-0.2,0-0.4,0-0.6
      	C76.8,55.4,76.2,54.8,75.4,54.6z M50.8,19h1v32.4h-0.2c-0.2,0-0.4,0-0.6,0V19H50.8z M29.4,19h21.2v32.2c-1.4,0-2.8-0.2-4.4-0.2
      	c-0.2,0-0.2,0-0.4,0c-2,0-3.8,0-5.8,0c-1,0-2,0-2.8,0c-0.2,0-0.6,0-0.8,0c-0.6,0-1.4,0-2,0c-0.4,0-0.8,0-1,0c-0.6,0-1.2,0-1.8,0
      	c-0.4,0-0.8,0-1.2,0c-0.4,0-0.6,0-1,0C29.4,51,29.4,19,29.4,19z M28.2,19h1v32.2c-0.2,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6,0V19z
      	 M57.6,61h-35l-1.4-3.4H59L57.6,61z M59,57.4H21l-0.4-0.8h38.8L59,57.4z M20.8,55.6l0.2-0.4l0,0c1.4-0.2,2.8-0.2,4.2-0.4
      	c0.2,0,0.2,0,0.4,0c1.4,0,2.8-0.2,4.2-0.2c0.2,0,0.4,0,0.4,0c1.4,0,2.8-0.2,4.4-0.2c0.2,0,0.2,0,0.4,0c1.4,0,3,0,4.4,0h0.2l0,0l0,0
      	h0.2c1.6,0,3,0,4.4,0c0.2,0,0.2,0,0.4,0c1.4,0,3,0,4.4,0.2c0.2,0,0.2,0,0.4,0c1.4,0,3,0.2,4.4,0.2H54c1.4,0.2,2.8,0.2,4.2,0.4l0,0
      	l0.2,0.4H20.8z M76,56.4c-0.2,0.4-0.6,0.8-1,0.8c-0.2,0-0.2,0-0.4,0C69,55.6,60.2,54.4,50.2,54c0,0,0,0-0.2,0
      	c-1.6,0-3.2-0.2-4.8-0.2H45c-1.6,0-3.2,0-5,0l0,0l0,0l0,0l0,0l0,0c-1.6,0-3.4,0-5,0h-0.2c-1.6,0-3,0-4.6,0.2H30
      	c-1.6,0-3,0.2-4.4,0.2c0,0,0,0-0.2,0c-8.2,0.6-15,1.6-19.8,3c0,0-0.2,0-0.4,0c-0.4,0-1-0.2-1-0.8l-0.4,0.2L4,56.4v-0.2
      	c0-0.4,0.2-0.8,0.8-1c5.8-1.6,14-2.6,23.8-3.2c0.6,0,1,0,1.6,0c0.4,0,0.8,0,1.2,0c0.6,0,1.2,0,1.8,0c0.4,0,0.6,0,1,0
      	c0.8,0,1.6,0,2.4,0c0.2,0,0.2,0,0.4,0c1,0,2,0,3,0s2,0,3,0c0.2,0,0.2,0,0.4,0c0.8,0,1.6,0,2.4,0c0.4,0,0.6,0,1,0c0.6,0,1.2,0,1.8,0
      	c0.4,0,0.8,0,1.2,0c0.6,0,1.2,0,1.6,0c9.8,0.6,18,1.6,23.8,3.2c0.4,0.2,0.8,0.6,0.8,1C76,56.2,76,56.4,76,56.4z"/>
    </svg>
  );
};

export default Hood;
