import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { TransitionGroup, Transition } from "react-transition-group";
import styled from "styled-components";
import * as locationsActions from "../../redux/modules/locations";
import EditLocationForm from "../../components/EditLocationForm/EditLocationForm";
import Header from "../../components/Header/Header";
import LocationsList from "../../components/LocationsList/LocationsList";
import Modal from "../../components/Modal/Modal";

const MyLocations = styled.section`
  background: transparent;
`;

function Locations({ editing, editStop, save, saving, selectedTab }) {
  const modalRef = useRef();

  const saveLocation = () => {
    save(editing);
  };

  const isSaving =
    editing && editing._id in saving && saving[editing._id] === true;

  return (
    <div>
      <Helmet title="My Locations" />
      <Header isHero />

      <MyLocations className="section">
        <div className="container">
          <LocationsList />

          <TransitionGroup>
            {editing && selectedTab === "details" && (
              <Transition key="details" nodeRef={modalRef} timeout={500}>
                {(transitionState) => (
                  <Modal
                    ref={modalRef}
                    transitionState={transitionState}
                    title="Edit Location"
                    bodyClass="content has-text-centered"
                    contentStyles={{ maxWidth: 400 }}
                    cancel={editStop}
                    cancelText="Cancel"
                    saveLoading={isSaving}
                    saveText="Save"
                  >
                    {(submitBtnRef) => (
                      <EditLocationForm
                        submitBtnRef={submitBtnRef}
                        location={editing}
                        saveLocation={saveLocation}
                      />
                    )}
                  </Modal>
                )}
              </Transition>
            )}
          </TransitionGroup>
        </div>
      </MyLocations>
    </div>
  );
}

export default connect(
  (state) => ({
    editing: state.locations.editing,
    locations: state.locations.data,
    saving: state.locations.saving,
    selectedTab: state.locations.selectedTab,
    user: state.auth.user,
  }),
  { ...locationsActions }
)(Locations);
