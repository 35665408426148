import { findIndex } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Icon from "../Icon/Icon";
import * as applianceActions from "../../redux/modules/appliances";

const IconLink = styled.a`
  margin-bottom: 10px;

  figcaption {
    text-align: center;
    color: #8a8a8a;
  }

  path {
    fill: #8a8a8a;
    transition: fill 250ms ease-out;
  }

  &.is-active {
    figcaption {
      color: var(--blue);
    }
    path {
      fill: var(--blue);
    }
  }

  &:not(.is-active):hover {
    figcaption {
      color: #43474d;
    }
    path {
      fill: #43474d;
    }
  }
`;

// TODO: Do I need <GlobalStyle /> for this?
// @media screen and (max-width: $mobile) {
//   figure svg {
//     width: 100%;
//     height: auto;
//   }
// }

class ApplianceTypesList extends Component {
  updateType = (type) => {
    const { changeTab, editUpdate } = this.props;
    editUpdate({ type });
    changeTab("details");
  };

  render() {
    const { appliances = [], applianceTypes = [], editing } = this.props;

    let currentAppliance = null;
    let type = null;
    if (editing) {
      const currentIndex = findIndex(
        appliances,
        (item) => item._id === editing
      );
      currentAppliance = appliances[currentIndex];
      type = currentAppliance.type._id || currentAppliance.type;
    }

    return (
      <div className="columns is-multiline is-mobile">
        {applianceTypes.map((applianceType, index) => {
          let typeClass = "column is-one-third";
          if (type === applianceType._id) {
            typeClass += " is-active";
          }

          return (
            <IconLink
              key={index}
              onClick={() => {
                this.updateType(applianceType);
              }}
              className={typeClass}
            >
              <figure className="has-text-centered">
                <Icon icon={applianceType.slug} size="120" />
                <figcaption>{applianceType.name}</figcaption>
              </figure>
            </IconLink>
          );
        })}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    applianceTypes: state.applianceTypes.data,
    editing: state.appliances.editing,
    selectedTab: state.appliances.selectedTab,
  }),
  { ...applianceActions }
)(ApplianceTypesList);
