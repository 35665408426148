import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LocationsListItem from "../LocationsListItem/LocationsListItem";

const StyledLocationsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 980px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
`;

function LocationsList({ loading, locations }) {
  return (
    <div>
      {loading && (
        <p className="has-text-centered">
          <button className="button is-outlined is-loading">Loading</button>
        </p>
      )}

      {
        !loading && (
          <StyledLocationsList>
            {locations &&
              locations.map((loc) => (
                <LocationsListItem key={loc._id} location={loc} />
              ))}
          </StyledLocationsList>
        ) /* - !loading */
      }

      {!loading && locations.length === 0 && (
        <div className="box">
          <div className="content">
            No locations found. <Link to="/">Add an appliance</Link> to get
            started.
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(
  (state) => ({
    loading: state.locations.loading,
    locations: state.locations.data,
  }),
  {}
)(LocationsList);
