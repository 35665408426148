import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Header from "../../components/Header/Header";
import * as serviceRequestActions from "../../redux/modules/serviceRequest";
import { useQuery } from "../../utils/hooks";

function ServiceRequestRetailerDecline({ declineing, error, retailerDecline }) {
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    retailerDecline(params.id, query.token);
  }, [retailerDecline, params.id, query.token]);

  return (
    <div className="hero is-fullheight is-bold">
      <Helmet title="Decline - Service Request" />

      <div className="hero-header">
        <Header />
      </div>

      <div className="hero-body">
        <div className="container">
          {declineing && (
            <div className={"st-overlay is-overlay"}>
              <span className={"st-loader loader"}></span>
            </div>
          )}

          {!declineing && !error && (
            <div>
              <h1 className="title is-danger">NoRepair Declined...</h1>
              <h2 className="subtitle">Thanks for letting us know.</h2>
            </div>
          )}

          {!declineing && error && (
            <div>
              <h1 className="title is-danger">Error!</h1>
              <h2 className="subtitle">{error.message}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    declineing: state.serviceRequest.declineing,
    error: state.serviceRequest.error,
  }),
  { ...serviceRequestActions }
)(ServiceRequestRetailerDecline);
