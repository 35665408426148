import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ResetForm from "../../components/ResetForm/ResetForm";

function Reset({ user }) {
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user, history]);

  return (
    <div className="hero login is-fullheight">
      <Helmet title="Reset" />

      <div className="hero-body is-justify-content-center">
        <div className="box">
          <h2 className="subtitle has-text-centered">Reset Your Password</h2>
          <ResetForm />
          <p className="has-text-centered">
            Already have an account? <Link to="/login">Login!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {}
)(Reset);
