import objectId from "bson-objectid";
import { connect } from "react-redux";
import styled from "styled-components";
import config from "../../config";
import * as locationsActions from "../../redux/modules/locations";
import { addFilter, removeFilter } from "../../redux/modules/appliances";
import { useHistory } from "react-router";

const Location = styled.div`
  position: relative;
  margin-right: 20px;
  margin-bottom: 40px !important;

  @media screen and (max-width: 980px) {
    width: 30%;
    margin-right: 0;
  }

  @media screen and (max-width: 769px) {
    width: 47%;
    margin-right: 0;
  }

  @media screen and (max-width: 480px) {
    width: auto;
    margin: 0 -20px;
    border-radius: 0;
  }

  @media screen and (max-width: 340px) {
    margin: 0 -10px;
  }
`;

const LocationItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .location-map img {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: 769px) {
    .location-map img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: row;

    .location-map {
      max-width: 100px;
    }
  }
`;

const Remove = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  @media screen and (max-width: 340px) {
    top: 15px;
    right: 15px;
  }
`;

const LocationDetails = styled.div`
  text-align: center;
  margin-top: 20px;

  @media screen and (max-width: 480px) {
    flex: 1 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    margin-top: 0;
  }
`;

const LocationActions = styled.div`
  margin-top: 20px;

  a,
  button {
    width: 100%;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

function LocationsListItem({
  addFilter,
  editStart,
  location,
  pushState,
  removeFilter,
  removing,
  remove,
  saving,
}) {
  const history = useHistory();

  const duplicateLocation = (evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    const dupeId = String(objectId());
    const dupe = {
      ...location,
      _id: dupeId,
      name: location.name + " Copy",
    };
    delete dupe.__v;
    editStart(dupe, "details");
  };

  const editLocation = (evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    editStart(location, "details");
  };

  const removeLocation = (id) => (evt) => {
    if (evt) {
      evt.stopPropagation();
    }

    if (
      !removing[id] &&
      window.confirm("Are you sure you want to delete this?")
    ) {
      remove(id);
    }
  };

  const viewAppliances = () => {
    removeFilter("locations");
    addFilter("locations", location);
    history.push("/");
  };

  const domain = "//maps.googleapis.com/maps/api/staticmap";
  const [lng, lat] = location.loc;
  const mapImageSrc = `${domain}?center=${lat},${lng}&zoom=15&size=300x300&sensor=false&key=${config.google.apiKey}`;

  const isLoading = false;

  return (
    <Location className={"box"}>
      {(removing[location._id] || saving[location._id]) && (
        <div className={"st-overlay is-overlay"}>
          <span className={"st-loader loader"}></span>
        </div>
      )}

      <LocationItem onClick={editLocation}>
        <div className="location-map">
          <img src={mapImageSrc} alt={location.name} />
        </div>
        <LocationDetails>
          <p>
            <strong>{location.name}</strong>
          </p>
          <p>
            {location.street}
            <br />
            {`${location.city}, ${location.state} ${location.zip}`.replace(
              /^,\s\s?/,
              ""
            )}
          </p>
        </LocationDetails>
      </LocationItem>

      <Remove>
        <button className="delete" onClick={removeLocation(location._id)} />
      </Remove>

      <LocationActions>
        <div className="columns is-mobile">
          <div className="column">
            <button
              className={
                "button is-outlined is-success" +
                (isLoading ? " is-loading" : "")
              }
              onClick={editLocation}
            >
              <i className="fa fa-pencil"></i>&nbsp;Edit
            </button>
          </div>
          <div className="column is-one-quarter">
            <button
              className={"button is-outlined is-dark"}
              title="Duplicate"
              onClick={duplicateLocation}
            >
              <i className="fa fa-copy"></i>
            </button>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <button
              className={
                "button is-outlined is-primary" +
                (isLoading ? " is-loading" : "")
              }
              onClick={viewAppliances}
            >
              View Appliances
            </button>
          </div>
        </div>
      </LocationActions>
    </Location>
  );
}

export default connect(
  (state) => ({
    removing: state.locations.removing,
    saving: state.locations.saving,
    user: state.auth.user,
  }),
  { ...locationsActions, addFilter, removeFilter }
)(LocationsListItem);
