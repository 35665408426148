import * as Icons from "../../icons";

const Icon = ({ icon, ...props }) => {
  let IconComponent;

  if (!icon) {
    IconComponent = Icons.Empty;
    return <IconComponent {...props} />;
  }

  switch (icon.toLowerCase()) {
    case "cook-top":
      IconComponent = Icons.CookTop;
      break;
    case "dish-washer":
      IconComponent = Icons.DishWasher;
      break;
    case "dryer":
      IconComponent = Icons.Dryer;
      break;
    case "freezer":
      IconComponent = Icons.Freezer;
      break;
    case "fridge":
      IconComponent = Icons.Fridge;
      break;
    case "hood":
      IconComponent = Icons.Hood;
      break;
    case "microwave":
      IconComponent = Icons.Microwave;
      break;
    case "oven":
      IconComponent = Icons.Oven;
      break;
    case "range":
      IconComponent = Icons.Range;
      break;
    case "washer":
      IconComponent = Icons.Washer;
      break;

    case "text-logo":
      IconComponent = Icons.TextLogo;
      break;
    case "logo-text-right":
      IconComponent = Icons.LogoTextRight;
      break;
    case "logo":
      IconComponent = Icons.Logo;
      break;

    default:
      IconComponent = Icons.Empty;
      break;
  }

  return <IconComponent {...props} />;
};

export default Icon;
