import { useRef } from "react";
import { Form, Field } from "react-final-form";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { TransitionGroup, Transition } from "react-transition-group";
import MaskedInput from "react-maskedinput";
import styled from "styled-components";
import Header from "../../components/Header/Header";
import Modal from "../../components/Modal/Modal";
import ChangePasswordForm from "../../components/ChangePasswordForm/ChangePasswordForm";
import * as authActions from "../../redux/modules/auth";
import {
  createValidator,
  email as isEmail,
  match,
  maxLength,
  minLength,
  required as isRequired,
} from "../../utils/validation";
import Control from "../../components/Control/Control";

const MyAccount = styled.section`
  background: transparent;
`;

function Account({
  hideChangePassForm,
  changePassVisible,
  showChangePassForm,
  saveError,
  saving,
  user,
  insertUser,
  saveUser,
}) {
  const modalRef = useRef();
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.saveError && nextProps.saveError.param) {
  //     const stateErrors = {};
  //     stateErrors[nextProps.saveError.param] = nextProps.saveError.message;
  //     this.setState({ errors: stateErrors });
  //   }
  // }

  if (!user) {
    return;
  }

  const rules = { email: [isRequired, isEmail, minLength(6)] };

  if (user.guest) {
    rules.password = [isRequired, minLength(4), maxLength(40)];
    rules.confirm = [
      isRequired,
      match("password"),
      minLength(4),
      maxLength(40),
    ];
  }

  const onSubmit = (values) => {
    const newUser = {
      email: values.email,
      name: {
        first: values.first,
        last: values.last,
      },
      phoneNumber: values.phone,
    };

    if (user.guest) {
      newUser.pass = values.password;
      insertUser(newUser);
    } else {
      saveUser(newUser);
    }
  };

  const { email, guest } = user;

  const guestEmailError =
    guest && email.length === 0 ? "your email address above and" : "";
  const guestMsg = `In order to complete your account, we just need you to put in ${guestEmailError} a password below.`;

  return (
    <div>
      <Helmet title="My Account" />
      <Header isHero />

      <MyAccount className="section">
        <div className="container">
          <h1 className="title">My Account</h1>

          <div className="box">
            <Form
              initialValues={{
                email: user.email,
                first: user.name.first,
                last: user.name.last,
                phone: user.phoneNumber,
              }}
              onSubmit={onSubmit}
              validate={createValidator(rules)}
            >
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label htmlFor="email" className="label">
                      Email Address
                    </label>
                    <Field name="email" type="email">
                      {(field) => <Control field={field} focusOnMount />}
                    </Field>
                    {guest && (
                      <span className="help is-danger">{guestMsg}</span>
                    )}
                  </div>

                  <div className="field">
                    <label htmlFor="first" className="label">
                      Full Name
                    </label>
                    <div className="control">
                      <div className="columns is-mobile">
                        <div className="column">
                          <Field name="first">
                            {(field) => <Control field={field} />}
                          </Field>
                        </div>
                        <div className="column">
                          <Field name="last">
                            {(field) => <Control field={field} />}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="phone" className="label">
                      Phone Number
                    </label>
                    <Field name="phone" type="tel">
                      {(field) => (
                        <Control field={field}>
                          {({ classNames, inputRef }) => (
                            <MaskedInput
                              ref={inputRef}
                              {...field.input}
                              className={classNames}
                              mask="(111) 111-1111"
                              placeholder="(555) 555-5555"
                            />
                          )}
                        </Control>
                      )}
                    </Field>
                  </div>

                  {guest && (
                    <div className="columns is-mobile">
                      <div className="column">
                        <div className="field">
                          <label htmlFor="password" className="label">
                            Password
                          </label>
                          <Field name="password" type="password">
                            {(field) => <Control field={field} />}
                          </Field>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <label htmlFor="confirm" className="label">
                            Confirm
                          </label>
                          <Field name="confirm" type="password">
                            {(field) => <Control field={field} />}
                          </Field>
                        </div>
                      </div>
                    </div>
                  )}

                  {saveError && !saveError.param && (
                    <p className="field">
                      <span className="help is-danger">
                        {saveError.message}
                      </span>
                    </p>
                  )}

                  <p className="field">
                    <button
                      className={
                        "button is-primary" +
                        (saving || submitting ? " is-loading" : "")
                      }
                    >
                      Save
                    </button>{" "}
                    {!guest && (
                      <button
                        className="button"
                        type="button"
                        onClick={showChangePassForm}
                      >
                        Change Password
                      </button>
                    )}
                  </p>
                </form>
              )}
            </Form>
          </div>

          <TransitionGroup>
            {changePassVisible && (
              <Transition
                key="change-password"
                nodeRef={modalRef}
                timeout={500}
              >
                {(transitionState) => (
                  <Modal
                    ref={modalRef}
                    transitionState={transitionState}
                    title="Change Your Password"
                    contentStyles={{ maxWidth: 400 }}
                    cancel={hideChangePassForm}
                    cancelText="Cancel"
                    saveText="Reset"
                  >
                    {(submitBtnRef) => (
                      <ChangePasswordForm submitBtnRef={submitBtnRef} />
                    )}
                  </Modal>
                )}
              </Transition>
            )}
          </TransitionGroup>
        </div>
      </MyAccount>
    </div>
  );
}

export default connect(
  (state) => ({
    changePassVisible: state.auth.changePassVisible,
    removing: state.auth.removing,
    saveError: state.auth.saveError,
    saving: state.auth.saving,
    user: state.auth.user,
  }),
  { ...authActions }
)(Account);
