import moment from "moment";
import styled from "styled-components";
import config from "../../config";

const StyledHistoryItem = styled.div`
  background: #fafafa;
`;

const Time = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  color: #ccc;
`;

const StatusIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Status = styled.span`
  font-size: 10px;
  width: 100%;
  text-align: center;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  margin-left: 60px;

  p:not(:last-child) {
    margin: 0;
  }

  .icon {
    margin-right: 20px;
  }

  .fa {
    vertical-align: middle;
  }
`;

const Coupon = styled.div`
  border: 4px dashed var(--red);
  margin: 20px auto 0;
  padding: 20px;
  background: #fff;
`;

export default function HistoryItem({ appliance, serviceRequest }) {
  return (
    <StyledHistoryItem
      className="box is-shallow"
      style={{ position: "relative" }}
    >
      <Time>{moment(serviceRequest.createdAt).fromNow()}</Time>

      <StatusIcon>
        <span
          className={
            "icon " + config.requestStatusData[serviceRequest.status].className
          }
        >
          <i
            className={
              "fa " + config.requestStatusData[serviceRequest.status].icon
            }
          ></i>
        </span>
        <Status>
          {config.requestStatusData[serviceRequest.status].status}
        </Status>
      </StatusIcon>

      <Details>
        <div>
          <p>
            <strong>{serviceRequest.symptoms.join(", ")}</strong>
          </p>
          {serviceRequest.other.length > 0 && (
            <p>
              <strong>Other:</strong> {serviceRequest.other}
            </p>
          )}
          <p>
            <strong>Provider:</strong> {serviceRequest.provider.business.name}
          </p>
        </div>
      </Details>

      {serviceRequest.status === config.requestStatuses.RETAILER_ACCEPTED && (
        <Coupon>
          <p style={{ margin: 0, textAlign: "center" }}>
            <strong style={{ fontSize: "28px", color: "#162d55" }}>
              $50 OFF!
            </strong>
            <br />
            <span style={{ fontSize: "14px", color: "#999", fontWeight: 300 }}>
              Towards the purchase of a new {appliance.type.name}, $499 or more.
            </span>
          </p>
        </Coupon>
      )}
    </StyledHistoryItem>
  );
}
