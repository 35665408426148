import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
// import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Notification from "../Notification/Notification";
import {
  clearError as clearStripeError,
  handleResponse as handleStripeResponse,
} from "../../redux/modules/stripe";
import config from "../../config";

const stripePromise = loadStripe(config.stripe.publicKey);

function StripePaymentForm({
  clearStripeError,
  handleStripeResponse,
  stripeError,
}) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    // / Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      // console.log("[error]", error);
      handleStripeResponse(500, { error });
    } else {
      // console.log("[Token]", token);
      handleStripeResponse(200, { token });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {stripeError && (
        <Notification
          type="danger"
          message={stripeError.message}
          dismiss={clearStripeError}
        />
      )}

      <div className="box">
        <CardElement />
      </div>

      <p className="control">
        <button
          type="submit"
          className={
            "button is-primary is-fullwidth" + (loading ? " is-loading" : "")
          }
        >
          Add Card
        </button>
      </p>
    </form>
  );
}

const ConnectedStripePaymentForm = connect(
  (state) => ({
    stripeError: state.stripe.error,
    user: state.auth.user,
  }),
  { clearStripeError, handleStripeResponse }
)(StripePaymentForm);

const StripePaymentFormWithElements = () => {
  // const [stripePromise, setStripePromise] = useState(null);
  // console.log(stripePromise);

  // useEffect(() => {
  //   if (stripePromise === null) {
  //     console.log("loading stripe");
  //     setStripePromise(
  //       require("@stripe/stripe-js").loadStripe(config.stripe.publicKey)
  //     );
  //   }
  // }, [stripePromise]);

  // if (stripePromise === null) {
  //   return null;
  // }

  return (
    <Elements stripe={stripePromise}>
      <ConnectedStripePaymentForm />
    </Elements>
  );
};

export default StripePaymentFormWithElements;
