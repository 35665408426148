export default function TextLogo({
  textFill = "#B8BEC1",
  innerFill = "#a22c32",
  outerFill = "#B8BEC1",
  width,
  height,
  className,
}) {
  const inlineStyle = {};
  if (width) {
    inlineStyle.width = `${width}px`;
  }
  if (height) {
    inlineStyle.height = `${height}px`;
  }

  return (
    <svg
      version="1.1"
      className={className}
      style={inlineStyle}
      x="0px"
      y="0px"
      viewBox="0 0 440 100"
    >
      <g className="name">
        <path
          className="service"
          fill={textFill}
          d="M11.7,59.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.3-0.1-0.5l0.4-3.6
            c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7,0c1.2,0.5,2.6,0.9,4,1.1c1.4,0.2,3,0.3,4.6,0.3c1.2,0,2.1-0.1,2.9-0.2
            c0.8-0.1,1.4-0.3,1.9-0.6c0.5-0.3,0.8-0.6,1-1c0.2-0.4,0.3-0.8,0.3-1.3c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.6-0.8-1.1-1.1
            s-1.2-0.7-2.1-1c-0.9-0.3-2-0.7-3.4-1c-1.7-0.5-3.1-1-4.3-1.5C15,46.6,14,46,13.2,45.2c-0.8-0.7-1.3-1.5-1.7-2.5
            c-0.4-0.9-0.5-1.9-0.5-3c0-2.6,1-4.6,3-6c2-1.4,5.1-2.1,9.2-2.1c1.6,0,3.1,0.1,4.5,0.3c1.5,0.2,2.9,0.5,4.2,0.9
            c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.1,0.5l-0.3,3.5c0,0.3-0.1,0.5-0.3,0.6s-0.4,0.2-0.7,0.1c-1.1-0.3-2.3-0.6-3.6-0.7
            s-2.6-0.3-3.9-0.3c-1.2,0-2.1,0.1-2.9,0.2c-0.8,0.1-1.4,0.3-1.8,0.5c-0.5,0.2-0.8,0.5-1,0.8c-0.2,0.3-0.3,0.7-0.3,1.1
            c0,0.5,0.1,0.9,0.3,1.2c0.2,0.4,0.6,0.7,1.1,1c0.5,0.3,1.2,0.7,2,1c0.8,0.3,1.9,0.7,3.1,1c1.8,0.5,3.4,1.1,4.6,1.7s2.3,1.3,3.1,2
            c0.8,0.7,1.4,1.5,1.7,2.4c0.4,0.9,0.5,1.9,0.5,3c0,1.4-0.2,2.6-0.7,3.7c-0.5,1.1-1.2,1.9-2.3,2.7c-1,0.7-2.4,1.3-4,1.6
            c-1.6,0.4-3.6,0.5-5.9,0.5c-1.7,0-3.4-0.1-5-0.3C14.7,60.7,13.1,60.4,11.7,59.9z M39,33c0-0.2,0.1-0.4,0.2-0.6
            c0.2-0.2,0.3-0.2,0.6-0.2h20.8c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2H45.7v6.3h13.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.4c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2H45.7v7.1h15.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6V60c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2H39.7c-0.2,0-0.4-0.1-0.6-0.2C39,60.4,39,60.2,39,60V33z M91.4,41.2c0,1.9-0.5,3.5-1.5,4.9
            c-1,1.4-2.6,2.5-4.7,3.2l7.9,10.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.1-0.5,0.1h-4.3
            c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.1-1l-6.9-9.4h-5.5V60c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2h-5.1
            c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V33c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2h11.5c1.8,0,3.5,0.2,5.1,0.6
            c1.6,0.4,2.9,0.9,4,1.7c1.1,0.7,2,1.7,2.7,2.8C91.1,38.3,91.4,39.6,91.4,41.2z M84.6,41.2c0-1.3-0.5-2.3-1.6-2.9
            c-1.1-0.6-2.7-0.9-4.8-0.9h-5V45h5c2.1,0,3.7-0.3,4.8-0.9C84.1,43.5,84.6,42.5,84.6,41.2z M108,60.8c-0.4,0-0.7-0.1-1-0.3
            c-0.3-0.2-0.5-0.4-0.6-0.8L95.1,33.3C95,33,95,32.7,95.2,32.5c0.2-0.2,0.4-0.3,0.7-0.3h4.9c0.4,0,0.8,0.1,1,0.2
            c0.2,0.2,0.4,0.4,0.5,0.7l8.7,22.3l8.7-22.3c0.1-0.3,0.3-0.6,0.5-0.7c0.2-0.2,0.6-0.2,1-0.2h4.9c0.3,0,0.5,0.1,0.7,0.3
            c0.2,0.2,0.2,0.5,0.1,0.8l-11.2,26.5c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.3-1,0.3H108z M137.2,32.2c0.2,0,0.4,0.1,0.6,0.2
            c0.2,0.2,0.2,0.4,0.2,0.6v27c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V33
            c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2H137.2z M168.6,60.3c-1.2,0.4-2.5,0.7-3.8,0.8c-1.3,0.1-2.7,0.2-4.1,0.2
            c-2.8,0-5.3-0.3-7.5-1c-2.2-0.7-4-1.7-5.5-2.9s-2.7-2.8-3.4-4.7c-0.8-1.8-1.2-3.9-1.2-6.2c0-2.3,0.4-4.3,1.2-6.1
            c0.8-1.8,1.9-3.4,3.4-4.7c1.5-1.3,3.3-2.3,5.5-3c2.2-0.7,4.7-1,7.4-1c1.4,0,2.7,0.1,4.1,0.3c1.4,0.2,2.6,0.4,3.8,0.8
            c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.2,0.7l-0.3,3.3c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.1-0.8,0c-1-0.3-2-0.6-3-0.7
            c-1-0.2-2.2-0.2-3.5-0.2c-1.9,0-3.5,0.2-5,0.7c-1.4,0.4-2.6,1.1-3.6,1.9c-1,0.8-1.7,1.9-2.2,3.1c-0.5,1.2-0.7,2.5-0.7,4
            c0,1.6,0.3,3,0.8,4.2s1.3,2.2,2.3,3c1,0.8,2.2,1.4,3.7,1.9c1.4,0.4,3.1,0.6,5,0.6c1.2,0,2.3-0.1,3.4-0.3c1.1-0.2,2.1-0.4,3.1-0.8
            c0.3-0.1,0.5-0.1,0.8,0s0.4,0.3,0.4,0.6l0.3,3.4c0,0.3-0.1,0.5-0.2,0.7C169,60.1,168.8,60.2,168.6,60.3z M174,33
            c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2h20.8c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2h-14.9v6.3h13.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.4c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2h-13.8v7.1h15.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6V60c0,0.2-0.1,0.4-0.2,0.6
            c-0.2,0.2-0.4,0.2-0.6,0.2h-21.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V33z"
        />
        <path
          className="tapp"
          fill={textFill}
          d="M324.1,37.3H315c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V33c0-0.2,0.1-0.4,0.2-0.6
            c0.2-0.2,0.4-0.2,0.6-0.2h24.9c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-9.1
            V60c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V37.3z M349,54.5
            l-2.3,5.8c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.2-0.4,0.2h-5.6c-0.3,0-0.5-0.1-0.7-0.3s-0.1-0.5,0-0.8l11.4-27
            c0.1-0.3,0.4-0.5,0.8-0.5h7.2c0.4,0,0.6,0.2,0.8,0.5l11.4,27c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.2-0.4,0.3-0.7,0.3h-5.6
            c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.3-0.4l-2.3-5.8H349z M360.2,49.4l-4.6-11.9l-4.6,11.9H360.2z M386.8,32.2c2,0,3.8,0.2,5.5,0.6
            c1.6,0.4,3,1,4.1,1.8c1.1,0.8,2,1.8,2.6,3s0.9,2.6,0.9,4.1c0,1.5-0.3,2.9-0.9,4.1s-1.5,2.2-2.6,3c-1.1,0.8-2.5,1.4-4.1,1.8
            c-1.6,0.4-3.4,0.6-5.5,0.6h-5V60c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-5.1c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6
            V33c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2H386.8z M381.8,46h4.3c1.1,0,2.2-0.1,3-0.2c0.9-0.1,1.6-0.4,2.2-0.7
            c0.6-0.3,1-0.8,1.3-1.3s0.4-1.2,0.4-2.1s-0.1-1.5-0.4-2.1s-0.7-1-1.3-1.3c-0.6-0.3-1.3-0.6-2.2-0.7c-0.9-0.1-1.9-0.2-3-0.2h-4.3
            V46z M415.9,32.2c2,0,3.8,0.2,5.5,0.6c1.6,0.4,3,1,4.1,1.8c1.1,0.8,2,1.8,2.6,3s0.9,2.6,0.9,4.1c0,1.5-0.3,2.9-0.9,4.1
            s-1.5,2.2-2.6,3c-1.1,0.8-2.5,1.4-4.1,1.8c-1.6,0.4-3.4,0.6-5.5,0.6h-5V60c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H405
            c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V33c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2H415.9z M410.9,46h4.3c1.1,0,2.2-0.1,3-0.2
            c0.9-0.1,1.6-0.4,2.2-0.7c0.6-0.3,1-0.8,1.3-1.3s0.4-1.2,0.4-2.1s-0.1-1.5-0.4-2.1s-0.7-1-1.3-1.3c-0.6-0.3-1.3-0.6-2.2-0.7
            c-0.9-0.1-1.9-0.2-3-0.2h-4.3V46z"
        />
      </g>
      <g className="icon">
        <path
          className="outer"
          fill={outerFill}
          d="M302.3,33.7c-9.2-11.5-20.8-21.6-33.4-29.1c-12.7-0.3-27.3,1.6-42.1,6.3
            c-2.4,5.3-4.8,11.5-7,18.6c-2.6,8-4.8,16.8-6.6,26.1c13.8,13.7,29.5,27.7,45,39.9c9.1-2.9,17.7-6.2,25.4-9.7
            c6.8-3.1,12.8-6.2,17.9-9.1C304.3,61,304.3,46.2,302.3,33.7z M294.4,65.9c-0.2,0.1-0.4,0.2-0.6,0.3c-9,3.9-19.6,8-31.8,12
            c-10.7-9.5-21.7-19.3-32.4-28.6c2.6-12.5,5.4-23.4,8.2-32.6c0.1-0.2,0.1-0.4,0.2-0.5c11.2-3,22-4.5,31.7-5
            c9.3,6.3,18,13.9,25.5,22.3C296.1,43.4,295.9,54.3,294.4,65.9z"
        />
        <path
          className="inner"
          fill={innerFill}
          d="M278,28.1c-0.1,4.7-0.3,10.3-0.6,15c-4,1.3-6.4,1.7-11.2,3.2c-4.3-3.9-6.1-5.7-10.5-9.4
            c0.8-4.4,1.7-9.9,2.4-14.1c-3.5,0.3-6.9,1-9.1,1.5c-1.3,6.2-2.6,13-4,20.5c6.7,5.6,13.4,11.5,19.9,17.5c7.2-2.3,13.9-4.4,20-6.4
            c0.7-7.1,1-13.9,0.9-20.1C284,33.8,280,29.9,278,28.1z"
        />
      </g>
    </svg>
  );
}
