export default function Logo({
  innerFill = "#a22c32",
  outerFill = "#B8BEC1",
  width,
  height,
  className,
}) {
  // const width = this.props.width || 100;
  // const inlineStyle = { width: `${width}px` };

  const inlineStyle = {};
  if (width) {
    inlineStyle.width = `${width}px`;
  }
  if (height) {
    inlineStyle.height = `${height}px`;
  }

  return (
    <svg
      version="1.1"
      className={className}
      style={inlineStyle}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g className="icon">
        <path
          className="outer"
          fill={outerFill}
          d="M93.9,33.7C84.7,22.2,73.1,12.1,60.5,4.6c-12.7-0.3-27.3,1.6-42.1,6.3c-2.4,5.3-4.8,11.5-7,18.6
            c-2.6,8-4.8,16.8-6.6,26.1c13.8,13.7,29.5,27.7,45,39.9c9.1-2.9,17.7-6.2,25.4-9.7C82,82.6,88,79.5,93,76.5
            C95.8,61,95.8,46.2,93.9,33.7z M85.9,65.9c-0.2,0.1-0.4,0.2-0.6,0.3c-9,3.9-19.6,8-31.8,12c-10.7-9.5-21.7-19.3-32.4-28.6
            c2.6-12.5,5.4-23.4,8.2-32.6c0.1-0.2,0.1-0.4,0.2-0.5c11.2-3,22-4.5,31.7-5c9.3,6.3,18,13.9,25.5,22.3
            C87.6,43.4,87.4,54.3,85.9,65.9z"
        />
        <path
          className="inner"
          fill={innerFill}
          d="M69.5,28.1c-0.1,4.7-0.3,10.3-0.6,15c-4,1.3-6.4,1.7-11.2,3.2c-4.3-3.9-6.1-5.7-10.5-9.4
            c0.8-4.4,1.7-9.9,2.4-14.1c-3.5,0.3-6.9,1-9.1,1.5c-1.3,6.2-2.6,13-4,20.5c6.7,5.6,13.4,11.5,19.9,17.5c7.2-2.3,13.9-4.4,20-6.4
            c0.7-7.1,1-13.9,0.9-20.1C75.6,33.8,71.5,29.9,69.5,28.1z"
        />
      </g>
    </svg>
  );
}
