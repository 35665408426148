import moment from "moment";
import config from "../config";

// TODO: Pass the entire appliance? return based on purchaseDate OR extendedWarranty
export function getWarrantyDetails(purchaseDate) {
  const warranty = {
    date: purchaseDate,
    length: config.warrantyLength,
    current: 0,
    perc: 0,
    status: "none",
    message: "Please provide a purchase date.",
    class: "success",
  };

  if (!purchaseDate) {
    return warranty;
  }

  const ms = moment().diff(moment(purchaseDate, "MM-DD-YYYY"));
  warranty.current = parseInt(moment.duration(ms).asDays().toFixed(0), 10);
  warranty.perc = parseFloat(
    ((warranty.current / warranty.length) * 100).toFixed(2)
  );

  if (warranty.length - warranty.current <= 30) {
    warranty.class = "danger";
  } else if (warranty.length - warranty.current <= 90) {
    warranty.class = "warning";
  }

  if (warranty.current > 0) {
    warranty.status = "active";
  }

  if (warranty.perc >= 100) {
    warranty.status = "expired";
  }

  if (warranty.length <= warranty.current) {
    warranty.message = "Warranty Expired";
  } else if (warranty.perc < 25) {
    warranty.message = warranty.length - warranty.current + " days left";
  } else {
    warranty.message = warranty.length - warranty.current + " days left";
  }

  return warranty;
}
