import { forwardRef, useRef } from "react";

const Modal = forwardRef(
  (
    {
      transitionState,
      bodyClass,
      cancel,
      cancelClass,
      cancelDisabled,
      cancelText,
      children,
      contentStyles,
      hideCancel,
      hideSave,
      save,
      saveClass,
      saveDisabled,
      saveLoading,
      saveText,
      title,
      titleClass,
    },
    ref
  ) => {
    const submitBtnRef = useRef(null);
    // const backgroundRef = useRef(null);
    // const contentRef = useRef(null);

    if (!titleClass) {
      titleClass = "title is-4 is-danger";
    }

    const cancelProps = { onClick: cancel || null };
    if (!cancel || cancelDisabled) {
      cancelProps.disabled = true;
    }

    const saveProps = { onClick: save || null };
    if ((!save && submitBtnRef.current === null) || saveDisabled) {
      saveProps.disabled = true;
    } else if (!save && submitBtnRef.current !== null) {
      saveProps.onClick = () => {
        submitBtnRef.current.click();
        // submitBtnRef.current.submit();
      };
    }

    let bgClass = "";
    let contentClass = "";
    if (transitionState === "entering" || transitionState === "entered") {
      bgClass = " quick fadeIn";
      contentClass = " zoomIn";
    } else if (transitionState === "exiting" || transitionState === "exited") {
      bgClass = " fast fadeOut";
      contentClass = " zoomOut";
    }

    return (
      <div ref={ref} className="modal is-native is-active">
        <div
          // ref={backgroundRef}
          className={"modal-background animated" + bgClass}
        ></div>
        <div
          // ref={contentRef}
          className={"modal-content animated fast" + contentClass}
          style={contentStyles}
        >
          <div className="box">
            {title.length > 0 && (
              <header className="modal-head has-text-centered">
                <h1 className={titleClass}>{title}</h1>
              </header>
            )}
            <section className={`modal-body ${bodyClass}`}>
              {typeof children === "function"
                ? children(submitBtnRef)
                : children}
            </section>
            <footer className="modal-foot">
              {!hideCancel && (
                <button
                  className={`button ${cancelClass || "is-danger is-outlined"}`}
                  {...cancelProps}
                >
                  {cancelText || "Cancel"}
                </button>
              )}
              {!hideSave && (
                <button
                  className={`button ${saveClass || "is-primary is-outlined"} ${
                    saveLoading ? "is-loading" : ""
                  }`}
                  {...saveProps}
                >
                  {saveText || "Save"}
                </button>
              )}
            </footer>
          </div>
        </div>
      </div>
    );
  }
);

export default Modal;
