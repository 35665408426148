import React, { Component } from "react";
import { connect } from "react-redux";
import { getWarrantyDetails } from "../../utils/warranty";

class WarrantyDetails extends Component {
  shouldComponentUpdate(nextProps) {
    // NOTE: This prevents a console error when there is no "currentAppliance" to render when closing the modal
    return nextProps.editing !== null;
  }

  render() {
    const { appliances, editing } = this.props;
    const currentAppliance = appliances.filter(
      (item) => item._id === editing
    )[0];

    // TODO: Pass the entire appliance? return based on purchaseDate OR extendedWarranty
    const warranty = getWarrantyDetails(currentAppliance.purchaseDate);

    // TODO: If they didn't enter in a purchaseDate then
    // TODO: we should probably have another case below to push them to the edit form

    return (
      <div>
        {warranty.status === "none" && (
          <div>
            <p>
              We are unable to determine anything about your warranty status
              until you add a purchase date to your appliance.
            </p>
          </div>
        )}

        {warranty.status === "active" && (
          <div>
            <p>
              According to your purchase date of:{" "}
              <strong>{currentAppliance.purchaseDate}</strong>
              <br />
              it would seem that your manufacturer warranty is still in affect!
            </p>
          </div>
        )}

        {warranty.status === "expired" && (
          <div>
            <p>It appears your manufacturer warranty is expired.</p>
            <p>Do you have an extended warranty?</p>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    appliances: state.appliances.data,
    editing: state.appliances.editing,
  }),
  {}
)(WarrantyDetails);
