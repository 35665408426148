import moment from "moment";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { TransitionGroup, Transition } from "react-transition-group";
import * as serviceRequestActions from "../../redux/modules/serviceRequest";
import { logout } from "../../redux/modules/auth";
import Modal from "../../components/Modal/Modal";
import Notification from "../../components/Notification/Notification";
import ProviderLoginForm from "../../components/ProviderLoginForm/ProviderLoginForm";
import ProviderHeader from "../../components/ProviderHeader/ProviderHeader";
import { VideoFile } from "../Appliances/Appliances";
import { useParams } from "react-router";
import { useQuery } from "../../utils/hooks";

export const PaymentForm = ({
  serviceRequest,
  user,
  isProvider,
  error,
  // stripeSource,
  logOut,
  changeTab,
  makePayment,
}) => (
  <div style={{ maxWidth: 400, margin: "0 auto" }}>
    {/* <h1 className="title is-info">Payment Required</h1>
    <h2 className="subtitle">
      A $3.99 fee is required to accept this request and get access to the
      customer's info.
      {!user && (
        <span>
          <br />
          <br />
          If you don't have an accout yet:{' '}
          <a
            href="http://servicetapp.com/service-provider-signup/"
            target="_blank"
          >
            Signup Here
          </a>
          .
        </span>
      )}
    </h2> */}

    <div className="block">
      <h1 className="title is-info">Accept Request</h1>
      {!user && (
        <h2 className="subtitle">
          <p className="block">
            You must be logged in to accept this request.
            <br />
            If you don't have an accout yet:{" "}
            <a
              href="http://servicetapp.com/service-provider-signup/"
              target="_blank"
              rel="noreferrer"
            >
              Signup Here
            </a>
            .
          </p>
        </h2>
      )}
    </div>

    {user && !isProvider && !("provider" in user.tokens) && (
      <>
        <div className="block">
          <p>
            You must have a provider account in order to accept this request.
            Please logout and try again. If you think this is an error, please
            contact support at{" "}
            <a href="mailto:support@servicetapp.com">support@servicetapp.com</a>
            .
          </p>
        </div>
        <div className="block">
          <p>
            <button onClick={logOut} className="button is-danger is-fullwidth">
              Logout
            </button>
          </p>
        </div>
      </>
    )}

    {user && !isProvider && "provider" in user.tokens && (
      <div>
        <p className="block">
          It appears you have not completed the provider account sign up
          process. Before you can start accepting requests, you must pay the
          signup fee.
        </p>
        <p className="block">
          <a
            href={`/providerSignup/${user._id}?token=${
              user.tokens.provider
            }&redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`}
            className="button is-danger is-fullwidth"
          >
            Complete Sign Up
          </a>
        </p>
      </div>
    )}

    {!user && !isProvider && (
      <div>
        <p className="control">
          <button
            onClick={() => changeTab("login")}
            className="button is-danger is-fullwidth"
          >
            Login
          </button>
        </p>
      </div>
    )}

    {error && error.type && error.type === "StripeCardError" && (
      <Notification
        type="danger"
        message={error.message + " Would you like to try a different card?"}
      />
    )}

    {/* {isProvider &&
      (!stripeSource ||
        (error && error.type && error.type === 'StripeCardError')) && (
        <StripePaymentForm />
      )} */}

    {isProvider && /* stripeSource && */ !error && (
      <div className="block">
        {/* TODO: It would be nice to implement this
        <p className="control">
          <a onClick={() => {}} className="button is-warning is-fullwidth">Change Payment Method</a>
        </p> */}

        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <td>Appliance:</td>
              <th>
                {serviceRequest.appliance.brand.name}{" "}
                {serviceRequest.appliance.modelNumber}{" "}
                {serviceRequest.appliance.type.name}
              </th>
            </tr>
            <tr>
              <td>Symptoms:</td>
              <th className="content">
                <ul className="mt0">
                  {serviceRequest.symptoms.map((symp, idx) => (
                    <li key={idx}>{symp}</li>
                  ))}
                </ul>
              </th>
            </tr>
          </tbody>
        </table>

        <p className="control">
          <button
            onClick={makePayment}
            className="button is-primary is-fullwidth"
          >
            {/* Pay $3.99 with {stripeSource.brand} ....{stripeSource.last4} */}
            Accept Request
          </button>
        </p>
      </div>
    )}
  </div>
);

function ServiceRequestAccept({
  accept,
  accepting,
  changeTab,
  logout,
  makePayment,
  error,
  // stripeToken,
  selectedTab,
  serviceRequest,
  user,
}) {
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    accept(params.id, query.token);
  }, [accept, params.id, query.token]);

  const handlePayment = () => {
    // let stripeData = null;
    // if (user.stripeCustomer && user.stripeCustomer.defaultSource) {
    //   stripeData = { type: 'customer', customer: user.stripeCustomer };
    // } else if (stripeToken) {
    //   stripeData = { type: 'token', token: stripeToken };
    // }

    makePayment(params.id, query.token /* , stripeData */);
  };

  const sr = serviceRequest;
  const paid = (sr && sr.request && sr.request.paid) || false;

  let completeLink = "";
  let noRepairLink = "";
  if (!error) {
    if (sr._id && sr.tokens) {
      completeLink = `/complete/${sr._id}?token=${sr.tokens.complete}`;
      noRepairLink = `/noRepair/${sr._id}?token=${sr.tokens.noRepair}`;
    }
  }

  // let stripeSource = null;
  // if (user && user.stripeCustomer && user.stripeCustomer.defaultSource) {
  //   stripeSource = { ...user.stripeCustomer.defaultSource };
  // } else if (stripeToken) {
  //   stripeSource = { ...stripeToken.card };
  // }

  const isProvider = user && user.isProvider;

  return (
    <div className="hero is-fullheight is-bold">
      <Helmet title="Accept - Service Request" />

      <div className="hero-header">
        <ProviderHeader />
      </div>

      <div className="hero-body">
        <div className="container">
          {accepting && (
            <div className="st-overlay is-overlay">
              <span className="st-loader loader" />
            </div>
          )}

          {error && !error.type && (
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <h1 className="title is-danger">Error!</h1>
              <h2 className="subtitle">{error.message}</h2>
            </div>
          )}

          {!error && !accepting && !paid && (
            <PaymentForm
              user={user}
              isProvider={isProvider}
              error={error}
              serviceRequest={sr}
              // stripeSource={stripeSource}
              logOut={logout}
              changeTab={changeTab}
              makePayment={handlePayment}
            />
          )}

          {!accepting && !error && paid && (
            <div>
              <h1 className="title is-success">Accepted!</h1>
              {/* <h2 className="subtitle">Thanks for letting us know!</h2> */}

              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td>Customer:</td>
                    <th>
                      {sr.name.first} {sr.name.last}
                    </th>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <th>
                      {sr.appliance.location.street}
                      <br />
                      {sr.appliance.location.city},{" "}
                      {sr.appliance.location.state} {sr.appliance.location.zip}
                    </th>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <th>
                      <a href={`tel://${sr.phoneNumber.replace(/\D/g, "")}`}>
                        {sr.phoneNumber}
                      </a>
                    </th>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <th>
                      <a href={`mailto://${sr.email}`}>{sr.email}</a>
                    </th>
                  </tr>
                  <tr>
                    <td>Appliance:</td>
                    <th>
                      {sr.appliance.brand.name} {sr.appliance.modelNumber}{" "}
                      {sr.appliance.type.name}
                    </th>
                  </tr>
                  <tr>
                    <td>Serial #:</td>
                    <th>{sr.appliance.serialNumber}</th>
                  </tr>
                  <tr>
                    <td>Purchased:</td>
                    <th>{sr.appliance.purchaseDate}</th>
                  </tr>
                  {(() => {
                    if (sr.appliance.images.length > 0) {
                      return (
                        <tr>
                          <td>Images:</td>
                          <th>
                            {sr.appliance.images.map((img, idx) => (
                              <a
                                key={idx}
                                href={img}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={img}
                                  style={{ maxWidth: 300 }}
                                  alt=""
                                />
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  <tr>
                    <td>Symptoms:</td>
                    <th className="content">
                      <ul className="mt0">
                        {sr.symptoms.map((symp, idx) => (
                          <li key={idx}>{symp}</li>
                        ))}
                      </ul>
                    </th>
                  </tr>
                  {/*
                    HACK: Was getting a warning about no text nodes allowed inside a <tbody>
                    HACK: So this IIFE seems to prevent react from outputting <!-- react-empty: # -->
                  */}
                  {(() => {
                    if (sr.other) {
                      return (
                        <tr>
                          <td>Notes:</td>
                          <th>{sr.other}</th>
                        </tr>
                      );
                    }
                  })()}
                  {(() => {
                    if (sr.images.length > 0) {
                      return (
                        <tr>
                          <td>Images:</td>
                          <th>
                            {sr.images.map((img, idx) => (
                              <a
                                key={idx}
                                href={img}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={img}
                                  style={{ maxWidth: 300 }}
                                  alt=""
                                />
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  {(() => {
                    if (sr.videos.length > 0) {
                      return (
                        <tr>
                          <td>Videos:</td>
                          <th>
                            {sr.videos.map((vid, idx) => (
                              <a
                                key={idx}
                                href={vid}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <VideoFile>
                                  <i className="fa fa-file-video-o" />
                                </VideoFile>
                              </a>
                            ))}
                          </th>
                        </tr>
                      );
                    }
                  })()}
                  <tr>
                    <td>Submitted:</td>
                    <th>
                      <span
                        title={moment(sr.createdAt).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      >
                        {moment(sr.createdAt).fromNow()}
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>

              <h1 className="title is-success">
                Were you able to repair the appliance?
              </h1>

              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>
                      <a
                        href={noRepairLink}
                        className="button is-danger is-fullwidth"
                      >
                        No
                      </a>
                    </td>
                    <td style={{ width: "50%" }}>
                      <a
                        href={completeLink}
                        className="button is-primary is-fullwidth"
                      >
                        Yes
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <TransitionGroup>
        {selectedTab === "login" && !user && (
          <Transition key="login" timeout={500}>
            {(transitionState) => (
              <Modal
                transitionState={transitionState}
                title="Provider Login"
                bodyClass="content"
                contentStyles={{ maxWidth: 400 }}
                cancelText="Cancel"
                cancel={() => changeTab("")}
                saveText="Login"
              >
                {(submitBtnRef) => (
                  <ProviderLoginForm submitBtnRef={submitBtnRef} />
                )}
              </Modal>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </div>
  );
}

export default connect(
  (state) => ({
    accepting: state.serviceRequest.accepting,
    error: state.serviceRequest.error,
    selectedTab: state.serviceRequest.selectedTab,
    serviceRequest: state.serviceRequest.data,
    stripeToken: state.stripe.token,
    user: state.auth.user,
  }),
  { ...serviceRequestActions, logout }
)(ServiceRequestAccept);
