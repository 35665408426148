import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import * as authActions from "../../redux/modules/auth";
import LoginInput from "../LoginInput/LoginInput";
import { StyledForm } from "../LoginForm/LoginForm";
import Notification from "../Notification/Notification";
import {
  createValidator,
  email,
  maxLength,
  match,
  minLength,
  required,
} from "../../utils/validation";

const signupValidation = createValidator({
  email: [required, email, minLength(6), maxLength(40)],
  password: [required, minLength(4), maxLength(40)],
  confirm: [required, match("password"), minLength(4), maxLength(40)],
});

function SignUpForm({ clearSignupError, loggingIn, signupError, signup }) {
  return (
    <Form
      initialValues={{ email: "", password: "", confirm: "", optin: false }}
      onSubmit={(values) => {
        const { email, password, optin } = values;
        signup(email, password, optin);
      }}
      validate={signupValidation}
    >
      {({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {signupError && (
            <Notification
              type="danger"
              message={signupError.message}
              dismiss={clearSignupError}
            />
          )}

          <Field name="email">
            {(field) => (
              <LoginInput
                field={field}
                type="email"
                label="Email Address"
                autoComplete="off"
              />
            )}
          </Field>

          <Field name="password">
            {(field) => (
              <LoginInput field={field} type="password" label="Password" />
            )}
          </Field>

          <Field name="confirm">
            {(field) => (
              <LoginInput field={field} type="password" label="Confirm" />
            )}
          </Field>

          <Field name="optin" type="checkbox">
            {(field) => (
              <div className="field">
                <label className="checkbox">
                  <input {...field.input} /> Would you like to receive email
                  notifications about special offers and new features?
                </label>
              </div>
            )}
          </Field>

          <button
            className={
              "button is-primary is-fullwidth" +
              (loggingIn || submitting ? " is-loading" : "")
            }
          >
            Signup
          </button>
        </StyledForm>
      )}
    </Form>
  );
}

export default connect(
  (state) => ({
    signupError: state.auth.signupError,
    loggingIn: state.auth.loggingIn,
    initialValues: { optin: true },
  }),
  { ...authActions }
)(SignUpForm);
