import React from 'react';

const Washer = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="13 11 54 58" {...props}>
      <path fill={fill} d="M66.8,11.2c0,0-0.2-0.2-0.4-0.2H13.6c-0.2,0-0.2,0-0.4,0.2c0,0-0.2,0.2-0.2,0.4v56.8c0,0.2,0,0.2,0.2,0.4
        c0,0,0.2,0.2,0.4,0.2h52.8c0.2,0,0.2,0,0.4-0.2c0,0,0.2-0.2,0.2-0.4V11.6C67,11.4,66.8,11.4,66.8,11.2z M66,68H14v-7h52V68z
        M66,60.8H14V21.4l12.6,3.2c-3.6,3.6-5.8,8.4-5.8,14c0,10.8,8.6,19.4,19.4,19.4s19.4-8.6,19.4-19.4c0-5.6-2.4-10.8-6.4-14.2L66,21.6
        V60.8z M40.2,20c10.2,0,18.6,8.4,18.6,18.6s-8.4,18.6-18.6,18.6s-18.6-8.4-18.6-18.6C21.6,28.4,29.8,20,40.2,20z M66,21l-13.2,3
        c-3.4-3-7.8-4.8-12.8-4.8s-9.6,2-13.2,5.2L14,21v-9h52V21z M40.2,51c6,0,10.8-4.2,12-9.6c0.2,0.6,0.8,1.2,1.6,1.2
        c1,0,1.8-0.6,1.8-1.4V36c0-0.8-0.8-1.4-1.8-1.4c-0.8,0-1.6,0.4-1.6,1.2c-1.2-5.6-6.2-9.6-12-9.6c-6.8,0-12.4,5.6-12.4,12.4
        C27.8,45.4,33.2,51,40.2,51z M52.6,36c0-0.4,0.6-0.8,1.2-0.8c0.8,0,1.2,0.4,1.2,0.8v5c0,0.4-0.6,0.8-1.2,0.8c-0.8,0-1.2-0.4-1.2-0.8
        V36z M40.2,26.4c6.2,0,11.4,4.6,12,10.8V40c-0.6,6-5.8,10.6-12,10.8c-6.6,0-12-5.4-12-12C28,31.8,33.4,26.4,40.2,26.4z M16.2,19.2
        h7.6l0,0l2-1.6l0,0v-3.4l0,0l0,0h-9.6l0,0l0,0V19.2C16,19.2,16,19.2,16.2,19.2L16.2,19.2z M16.6,14.8v-0.4h9v2.8l-1.8,1.6h-7.2V14.8
        z M36.2,17.2H44l0,0l0,0v-3l0,0l0,0h-7.8l0,0l0,0V17.2C36,17,36,17.2,36.2,17.2C36,17.2,36,17.2,36.2,17.2z M36.4,14.4h7.4v2.4h-7.4
        V14.4z M57.6,66h6.8l0,0l0,0v-3.6l0,0l0,0h-6.8l0,0l0,0L57.6,66C57.4,65.8,57.6,66,57.6,66L57.6,66z M58,62.6h6v3h-6V62.6z M57,16
        c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S56.4,16,57,16z M57,14.4c0.4,0,0.6,0.4,0.6,0.6c0,0.4-0.4,0.6-0.6,0.6
        c-0.4,0-0.6-0.4-0.6-0.6C56.4,14.6,56.6,14.4,57,14.4z M60,16c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S59.6,16,60,16z M60,14.4
        c0.4,0,0.6,0.4,0.6,0.6c0,0.4-0.4,0.6-0.6,0.6c-0.4,0-0.6-0.4-0.6-0.6C59.4,14.6,59.8,14.4,60,14.4z M63,16c0.6,0,1-0.4,1-1
        s-0.4-1-1-1s-1,0.4-1,1S62.4,16,63,16z M63,14.4c0.4,0,0.6,0.4,0.6,0.6c0,0.4-0.4,0.6-0.6,0.6c-0.4,0-0.6-0.4-0.6-0.6
        C62.4,14.6,62.6,14.4,63,14.4z" />
    </svg>
  );
};

export default Washer;
