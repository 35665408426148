import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoginForm from "../../components/LoginForm/LoginForm";

function Login({ user }) {
  const history = useHistory();

  useEffect(() => {
    if (user && !user.guest) {
      history.push("/");
    }
  }, [user, history]);

  return (
    <div className="hero login is-fullheight">
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className="hero-body is-justify-content-center">
        <div className="box">
          <h2 className="subtitle has-text-centered">Welcome Back!</h2>
          <LoginForm />
          <p className="has-text-centered">
            New to the site? <Link to="/sign-up">Sign Up!</Link>
          </p>
          <p className="has-text-centered">
            or <Link to="/">Continue as Guest</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {}
)(Login);
