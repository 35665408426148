const CHANGE = 'service-tapp/provider/CHANGE';
const CHANGE_TAB = 'service-tapp/serviceRequest/CHANGE_TAB';
const CLEAR = 'service-tapp/provider/CLEAR';
const LOAD = 'service-tapp/provider/LOAD';
const LOAD_SUCCESS = 'service-tapp/provider/LOAD_SUCCESS';
const LOAD_FAIL = 'service-tapp/provider/LOAD_FAIL';
const RATE = 'service-tapp/provider/RATE';
const RATE_SUCCESS = 'service-tapp/provider/RATE_SUCCESS';
const RATE_FAIL = 'service-tapp/provider/RATE_FAIL';

const initialState = {
  loaded: false,
  rating: true,
  selectedTab: '',
  signingUp: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE:
      return {
        ...state,
        data: action.provider,
      };
    case CHANGE_TAB:
      return {
        ...state,
        selectedTab: action.tabName,
      };
    case CLEAR:
      return {
        ...state,
        loaded: false,
        data: null,
      };
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case RATE:
      return {
        ...state,
        rating: true,
      };
    case RATE_SUCCESS:
      return {
        ...state,
        rating: false,
        // data: action.result,
        error: null,
      };
    case RATE_FAIL:
      return {
        ...state,
        rating: false,
        // data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.provider && globalState.provider.loaded;
}

export function change(provider) {
  return { type: CHANGE, provider };
}

export function changeTab(tabName) {
  return { type: CHANGE_TAB, tabName };
}

export function clear() {
  return { type: CLEAR };
}

export function load(zip) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client =>
      client.post('/provider/load', {
        data: { zip },
      }),
  };
}

export function loadById(pid) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/provider/load/${pid}`),
  };
}

export function rate(id, rating, token) {
  return {
    types: [RATE, RATE_SUCCESS, RATE_FAIL],
    promise: client =>
      client.post(`/provider/rate/${id}`, {
        data: { rating, token },
      }),
  };
}
