import React from 'react';

const Oven = ({fill, size = 40, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="14.2 14.6 51.8 51" {...props}>
      <path fill={fill} d="M65.6,14.8c0,0-0.2-0.2-0.4-0.2H14.8c-0.2,0-0.2,0-0.4,0.2c0,0-0.2,0.2-0.2,0.4V65c0,0.2,0,0.2,0.2,0.4c0,0,0.2,0.2,0.4,0.2
      	h50.6c0.2,0,0.2,0,0.4-0.2c0,0,0.2-0.2,0.2-0.4V15.2C65.8,15,65.6,14.8,65.6,14.8z M64.8,64.4H15.2V26.2H21V62c0,0.2,0,0.2,0.2,0.4
      	c0,0,0.2,0.2,0.4,0.2h37c0.2,0,0.2,0,0.4-0.2c0,0,0.2-0.2,0.2-0.4V26.2H65v38.2H64.8z M22,61.4V26.2h36v35.2H22z M64.8,25.4H15.2
      	v-9.8h49.6C64.8,15.6,64.8,25.4,64.8,25.4z M56.6,27.8H23.4c-0.4,0-0.8,0.4-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8h33.4
      	c0.4,0,0.8-0.4,0.8-0.8v-1.6C57.6,28.2,57.2,27.8,56.6,27.8z M56.6,30.2H23.4v-1.4h33.4v1.4H56.6z M23,55h34.2v-0.8H23V55z M44,20.8
      	h-1.2V21H44V20.8z M44,19.8h-1.2V20H44V19.8z M44,21.8h-1.2V22H44V21.8z M47,21.8h-1.2V22H47V21.8z M53,20.8h-1.2V21H53V20.8z
      	 M48.8,23.4h0.8c0.8,0,1.4-0.6,1.4-1.4v-2.4c0-0.8-0.6-1.4-1.4-1.4h-0.8c-0.8,0-1.4,0.6-1.4,1.4V22C47.6,22.8,48.2,23.4,48.8,23.4z
      	 M48.4,19.6c0-0.2,0.2-0.4,0.4-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4V22c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4V19.6z M47,20.8
      	h-1.2V21H47V20.8z M53,21.8h-1.2V22H53V21.8z M47,19.8h-1.2V20H47V19.8z M53,19.8h-1.2V20H53V19.8z M28.4,19.8h-1.2V20h1.2V19.8z
      	 M30.4,23.4h0.8c0.8,0,1.4-0.6,1.4-1.4v-2.4c0-0.8-0.6-1.4-1.4-1.4h-0.8c-0.8,0-1.4,0.6-1.4,1.4V22C29,22.8,29.6,23.4,30.4,23.4z
      	 M29.8,19.6c0-0.2,0.2-0.4,0.4-0.4H31c0.2,0,0.4,0.2,0.4,0.4V22c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4V19.6z
      	 M34.4,21.8h-1.2V22h1.2V21.8z M28.4,21.8h-1.2V22h1.2V21.8z M28.4,20.8h-1.2V21h1.2V20.8z M34.4,20.8h-1.2V21h1.2V20.8z M38,19.8
      	h-1.2V20H38V19.8z M38,20.8h-1.2V21H38V20.8z M40,23.4h0.8c0.8,0,1.4-0.6,1.4-1.4v-2.4c0-0.8-0.6-1.4-1.4-1.4H40
      	c-0.8,0-1.4,0.6-1.4,1.4V22C38.6,22.8,39.2,23.4,40,23.4z M39.6,19.6c0-0.2,0.2-0.4,0.4-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4V22
      	c0,0.2-0.2,0.4-0.4,0.4H40c-0.2,0-0.4-0.2-0.4-0.4V19.6z M38,21.8h-1.2V22H38V21.8z M34.4,19.8h-1.2V20h1.2V19.8z M49.6,51.4
      	c1.4,0,2.4-1,2.4-2.4V36.8c0-1.4-1-2.4-2.4-2.4H30.4c-1.4,0-2.4,1-2.4,2.4V49c0,1.4,1,2.4,2.4,2.4H49.6z M28.4,48.8V37
      	c0-1.2,1-2.2,2.2-2.2h18.8c1.2,0,2.2,1,2.2,2.2v11.8c0,1.2-1,2.2-2.2,2.2H30.6C29.2,51,28.4,50,28.4,48.8z M54.6,18.8
      	c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8S54.2,18.8,54.6,18.8z M54.6,17.6c0.2,0,0.4,0.2,0.4,0.4
      	c0,0.2-0.2,0.4-0.4,0.4c-0.4,0-0.6-0.2-0.6-0.4S54.2,17.6,54.6,17.6z"/>
    </svg>
  );
};

export default Oven;
