import { combineReducers } from "redux";
// import { routeReducer } from 'react-router-redux';
// import { reducer as reduxAsyncConnect } from 'redux-async-connect';
import { reducer as form } from "redux-form";

import auth from "./auth";
import appliances from "./appliances";
import applianceTypes from "./appliance-types";
import brands from "./brands";
import location from "./location";
import locations from "./locations";
import provider from "./provider";
import serviceRequest from "./serviceRequest";
import stripe from "./stripe";

export default combineReducers({
  // routing: routeReducer,
  // reduxAsyncConnect,
  form,
  auth,
  appliances,
  applianceTypes,
  brands,
  location,
  locations,
  provider,
  serviceRequest,
  stripe,
});
